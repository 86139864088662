<template>
  <div class="store_new_product">
    <div class="carousel" v-if="carousel.imgs && carousel.imgs.length > 0">
      <el-carousel :height="carousel.height + 'px'">
        <el-carousel-item v-for="(item, index) in carousel.imgs" :key="index">
          <a href="javascript:void(0)" @click="diyNavTo(item)">
            <div :style="`background-image:url(${item.imgUrl})`"></div>
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="products flex-row">
      <div class="l_con">
        <div class="search flex_row_start_center">
          <input type="text" placeholder="搜索新产品" v-model="keyword" />
          <span class="el-icon-search" @click="search"></span>
        </div>
        <div class="cate1">
          <div class="t1">产品分类</div>
          <div class="t1_box" v-if="categories.list.length">
            <el-scrollbar>
              <div :class="{ item1: true, seled: categories.sel == item.categoryId }"
                v-for="(item, index1) in categories.list" :key="index1" @click="querySelect('cate', item)">
                <text>{{ item.categoryName }}</text>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <!-- <div class="cate1">
          <div class="t1">应用领域</div>
          <div class="t1_box" v-if="fields.list.length">
            <el-scrollbar>
              <div :class="{ item1: true, seled: fields.sel == item.fieldId }" v-for="(item, index2) in fields.list"
                :key="index2" @click="querySelect('fields', item)">
                <text>{{ item.templateName }}</text>
              </div>
            </el-scrollbar>
          </div>
        </div> -->
      </div>
      <div class="r_con">
        <div class="flex_row_start_center" v-if="goodsList.length">
          <div class="item2" v-for="(good, idx) in goodsList" :key="idx">
            <div class="img1 flex_row_center_center" @click="goodsDetail(good.productId)">
              <!-- <img :src="good.goodsImage" alt="" /> -->
              <div :style="{ backgroundImage: `url(${good.goodsImage})` }" class="img"></div>

            </div>
            <div class="content1 flex-col" @click="goodsDetail(good.productId)">
              <span class="txt1">{{ good.goodsName }}</span>
              <!-- <span class="txt2">新品特性：{{ good.newProductFeature }}</span> -->
            </div>
          </div>
        </div>
        <div v-else class="empty_con flex_row_center_center">
          <div class="empty flex_column_center_center">
            <img src="@/assets/new_empty.png" alt="" srcset="" />
            <p>暂无符合条件的产品</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, getCurrentInstance, ref, onMounted, toRef } from "vue";
  import { useRoute, useRouter } from 'vue-router';
  import { ElMessage } from 'element-plus'
  export default {
    setup() {
      const { proxy } = getCurrentInstance();
      const router = useRouter();
      const route = useRoute();
      const categories = reactive({
        list: [],
        sel: null
      });
      const fields = reactive({
        list: [],
        sel: null
      });
      const goodsList = ref([]);
      const pageState = reactive({ page: {} });
      const keyword = ref("");
      const pageData = reactive({
        current: 1,
        storeId: route.query.vid
      });
      const carousel = reactive({ imgs: {} }); //轮播图

      const getCarousel = () => { //新品专区轮播
        let param = {
          storeId: route.query.vid,
          type: 1
        }
        proxy
          .$get("v3/seller/front/newProductDeco/banner", param)
          .then((res) => {
            if (res.state == 200) {
              let info = JSON.parse(proxy.$quillEscapeToHtml(res.data))
              if (info && info.length > 0) {
                info = info.filter(item => {
                  return item.imgUrl != '';
                })
                carousel.imgs = info;
              }
            }
          });
      };

      const diyNavTo = (val) => {
        proxy.$diyNavTo(val)
      };

      const getCategories = () => { //产品分类列表
        proxy
          .$get("v3/goods/front/goods/category/categoryList", {
            storeId: route.query.vid
          })
          .then((res) => {
            if (res.state == 200) {
              categories.list = res.data;
            }
          });
      };

      const getFields = () => { //应用领域列表
        proxy
          .$get("v3/seller/front/apply/field/list", {
            storeId: route.query.vid
          })
          .then((res) => {
            if (res.state == 200) {
              fields.list = res.data;
            }
          });
      };

      const getNews = () => { //获取商品列表
        let param = {
          current: pageData.current,
          storeId: route.query.vid
        };
        if (keyword.value) {
          param.keyword = keyword.value;
        }
        if (categories.sel) {
          param.categoryId1 = categories.sel;
        }
        if (fields.sel) {
          param.fieldId = fields.sel;
        }
        proxy
          .$get("v3/goods/front/goods/newGoodsList", param)
          .then((res) => {
            if (res.state == 200) {
              goodsList.value = res.data.list;
              pageState.page = res.data.pagination;
            }
          });
      };

      const search = () => {
        pageData.current = 1;
        getNews();
      };

      const querySelect = (type, item) => {
        if (type == "cate") {
          if (categories.sel != item.categoryId) {
            categories.sel = item.categoryId;
          } else {
            categories.sel = null;
          }
        } else {
          if (fields.sel != item.fieldId) {
            fields.sel = item.fieldId;
          } else {
            fields.sel = null;
          }
        }
        getNews();
      };

      const goodsDetail = (id) => { //商品详情

        // if (!id) {
        //   ElMessage('该新品的系列Id不存在')
        //   return
        // }


        router.push({
          path: '/goods/detail',
          query: {
            productId: id
          }
        })
      }

      onMounted(() => {
        getCarousel();
        getFields();
        getCategories();
        getNews();
      });

      return {
        diyNavTo,
        categories,
        fields,
        goodsList,
        ...toRef(pageState),
        keyword,
        querySelect,
        search,
        getCarousel,
        carousel,
        pageData,
        goodsDetail,
      };
    },
  };
</script>

<style lang="scss">
  .store_new_product {
    background: #f9f9f9;

    .carousel {
      .el-carousel {
        width: 100%;
        margin: 0 auto;
      }

      background: #f8f8f8;

      .el-carousel__item {
        /*图片宽度*/
        /* width: 1920px;
      position: absolute;
      left: 50%;
      margin-left: -960px; */
        /* 图片宽度的一半 */
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          background-position: center center;
          width: 1920px;
          height: 300px;
          position: absolute;
          left: 50%;
          top: 0;
          margin-left: -960px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    .products {
      width: 1200px;
      background: #fff;
      padding: 20px;
      margin: 0 auto;

      .l_con {
        .search {
          width: 212px;
          height: 36px;
          background: #ffffff;
          border: 1px solid #e2e2e2;
          padding-left: 10px;
          padding-right: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          input {
            height: 30px;
            width: 160px;
            font-size: 13px;
            border: none;
          }

          .el-icon-search {
            font-size: 22px;
            color: #e2e2e2;
            margin-left: 5px;

            &:hover {
              color: $colorMain;
            }
          }
        }

        .cate1 {
          width: 212px;
          margin-top: 20px;
          border: 1px solid #e2e2e2;

          .el-scrollbar__view {
            max-height: 390px;
          }

          .t1 {
            padding-left: 20px;
            height: 36px;
            background: #f5f5f5;
            font-size: 14px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #666666;
            line-height: 36px;
          }

          .t1_box {
            max-height: 400px;
            background-color: #f1f4fd;
          }

          .item1 {
            position: relative;
            line-height: 20px;
            color: #666666;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            padding: 10px 15px;
            background: #fff;
            cursor: pointer;

            text {
              display: -webkit-box;
              word-break: break-all;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            &.seled {
              background-color: #F1F4FD;
              color: $colorMain;
            }

            &:hover {
              color: $colorMain;
            }
          }
        }
      }

      .r_con {
        flex-wrap: wrap;

        .flex_row_start_center {
          flex-wrap: wrap;
        }

        .item2 {
          width: 217px;
          height: 280px;
          margin-left: 20px;
          margin-bottom: 20px;

          .img1 {
            border: 1px solid #eeeeee;
            cursor: pointer;

            width: 217px;
            height: 165px;

            .img {
              width: 125px;
              height: 125px;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }

          .content1 {
            margin-top: 10px;
            cursor: pointer;

            .txt1 {
              font-size: 16px;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold;
              color: #333333;
              margin-bottom: 10px;
              word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }

            .txt2 {
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #858585;
              line-height: 24px;
              word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-indent: 15px;
            }
          }
        }

        .empty_con {
          width: 840px;
          height: 600px;
          margin-left: 20px;

          .empty {
            img {
              height: 90px;
              width: 100px;
            }

            P {
              font-size: 16px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #666666;
            }
          }
        }
      }
    }
  }
</style>