<template>
   <div class="store_article_main">
      <div class="main_con">


         <!-- 一级分类start -->
         <div class="filters">
            <div class="line1 flex_row_start_start">
               <div class="txt1">分类：</div>
               <div :class="{ group1: true, flex_row_start_center: true, show_more: categoryList.cateMore }" id="cate">
                  <div :class="{ txt2: true, txtSel: itemC.categoryId == firstCategoryId }"
                     v-for="(itemC, indexC) in categoryList.list" :key="indexC" @click="firstToSec(itemC)">
                     {{ itemC.categoryName }}</div>
               </div>
               <div class="txt2 more_box" @click="showMore('cateMore', 2)" v-if="categoryList.cateMoreFlag">
                  <span>{{ categoryList.cateMore ? '收起' : '展开' }}</span>
                  <span
                     :class="{ 'el-icon-arrow-up': categoryList.cateMore, 'el-icon-arrow-down': !categoryList.cateMore }"
                     style="margin-left: 3px;"></span>
               </div>
            </div>
         </div>
         <!-- 一级分类end -->


         <template v-if="grade == 1">
            <div class="list2 flex_row_between_start" v-if="artList.list.length">
               <div class="m_left">
                  <template v-for="(item3, index3) in artList.list" :key="index3">
                     <informationItem :storeId="vid" :item3="item3"></informationItem>
                  </template>
               </div>
               <!-- <div class="m_right">
                  <div class="h_name">热门推荐</div>
                  <div class="h_list">
                     <div class="h1 flex_row_start_center" v-for="(re, reIdx) in artList.recomList" :key="reIdx">
                        <a :href="`/information/detail?storeId=${vid}&infoId=${re.informationId}`"
                           class="flex_row_start_center" target="_blank">
                           <span :class="`hBox box${reIdx + 1}`">{{ reIdx + 1 }}</span>
                           <span class="hT">{{ re.title }}</span>
                        </a>
                     </div>
                  </div>
               </div> -->
            </div>
            <div class="empty flex_column_center_center go3" v-else>
               <img src="@/assets/new_empty.png" alt="">
               <p>暂无数据</p>
            </div>
         </template>

         <!-- 分页 -->
         <div class="flex_row_center_center sld_pagination">
            <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
               :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
               :hide-on-single-page="true">
            </el-pagination>
         </div>
      </div>
   </div>
</template>

<script>
   import informationItem from '@/components/informationItem.vue'
   import { reactive, getCurrentInstance, ref, onMounted, toRefs, watch, watchEffect, nextTick } from "vue";
   import { useRoute, useRouter } from 'vue-router'
   export default {
      components: {
         informationItem
      },
      setup() {
         const route = useRoute()
         const vid = ref(route.query.vid);
         const router = useRouter()
         const { proxy } = getCurrentInstance();
         const artCateList = ref([])
         const labelList = reactive({
            kno: [],
            knoMore: false,
            knoMoreFirst: true,
            knoMoreFlag: false,
            other: [],
            otherMore: false,
            otherMoreFirst: true,
            otherMoreFlag: false
         })
         const param = reactive({
            current: 1,
            pageSize: 10,

         })
         const state = reactive({
            page: {}
         })
         const artList = reactive({
            list: [],
            recomList: [],
         })
         const grade = ref(route.query.g ? route.query.g : 1)
         const query = reactive({
            categoryId: route.query.categoryId,
            g: route.query.g
         })

         const keyword = route.query.keyword
         const categoryPath = ref([])
         const paramList = ref([])

         const categoryList = reactive({
            list: [],
            cateMore: false,
            cateMoreFlag: false
         })
         const firstCategoryId = ref(0)
         const pidC = ref(0)


         const computedWidth = (type) => {
            let dom = document.getElementById(type)
            let width = Array.from(dom.childNodes).map(item => item.clientWidth).reduce((prev, cur) => prev + (cur ? cur + 30 : 0), 0)
            if (width > 1020) {
               if (type == 'cate') {
                  categoryList[`${type}MoreFlag`] = true
               } else {
                  labelList[`${type}MoreFlag`] = true
               }
            }
         }

         const firstToSec = (item) => {
            firstCategoryId.value = item.categoryId
            query.categoryId = item.categoryId
            query.g = item.grade
            query.vid = vid.value
            router.push({
               path: '/store/article/list',
               query
            })
         }

         const cateTree = () => {
            let param = {
               categoryId: route.query.categoryId ? route.query.categoryId : 0,
               storeId: vid.value
            }
            proxy.$get("v3/cms/front/information/categoryName", param).then((res) => {
               if (res.state == 200) {
                  let { pathName, pid, categoryName, categoryId } = res.data
                  pidC.value = pid
                  if (res.data.pathName) {
                     categoryPath.value = [{ name: pathName, id: pid, grade: 1 }, { name: categoryName, id: categoryId, grade: 2 }]
                  } else {
                     categoryPath.value = [{ name: categoryName, id: categoryId, grade: 1 }]
                  }
               }
            })
         }

         const handleCurrentChange = (e) => {
            param.current = Math.floor(e);
            if (grade.value == 1) {
               getArtCate()
            } else {
               getArtList()
            }
         }

         const recomList = () => { //获取热门推荐
            let params = {
               current: param.current,
               pageSize: param.pageSize,
               isRecommend: 1,
               storeId: vid.value
            };
            // if (vid.value) {
            //    params.storeId = vid.value;
            // }
            // if (route.query.categoryId) {
            //    params.categoryId = route.query.categoryId;
            // }
            proxy.$get('v3/cms/front/information/informationList', params).then(res => {
               if (res.state == 200) {
                  artList.recomList = res.data.list
               }
            })
         }

         const getArtList = () => {
            let params = param;
            proxy.$get('v3/cms/front/information/informationList', params).then(res => {
               if (res.state == 200) {
                  artList.list = res.data.list
                  state.page = res.data.pagination
                  recomList()
               }
            })
         }

         const getArtCate = () => {
            let params = {};
            param.categoryId = route.query.categoryId ? route.query.categoryId : firstCategoryId.value
            params = {
               current: 1,
               pageSize: 10,
               storeId: vid.value,
               isGrade2: true
            };
            if (param.categoryId) {
               params.categoryId = param.categoryId;
            }
            proxy.$get('v3/cms/front/information/list', params).then(res => {
               if (res.state == 200) {
                  artCateList.value = res.data.list
                  state.page = res.data.pagination
                  artCateList.value.map(item => {
                     item.comList = item.informationList.filter(l => l.isRecommend == 0)
                     item.recomList = item.informationList.filter(l => l.isRecommend == 1).sort((a, b) => b.pageView - a.pageView)
                  })

               }
            })
         };

         const seeMore = (item) => {
            query.categoryId = item.categoryId
            query.g = item.grade

            query.vid = vid.value
            router.push({
               path: '/store/article/list',
               query
            })
         }

         const showMore = (type, index) => {
            labelList[type] = !labelList[type]
            if (labelList[type] && labelList[`${type}First`]) {
               labelList[`${type}First`] = false
            }
         }





         const toDetail = (item) => {
            router.push({
               path: '/information/detail',
               query: {
                  infoId: item.informationId,
                  storeId: vid.value
               }
            })
         }



         const getFirstCate = () => {
            let param = {
               categoryId: 0,
               pageSize: 100,
               storeId: vid.value
            }
            proxy.$get('v3/cms/front/information/categoryList', param).then(res => {
               if (res.state == 200) {
                  categoryList.list = res.data.list
                  firstCategoryId.value = route.query.categoryId ? route.query.categoryId : categoryList.list[0].categoryId
                  getArtCate()
               }
            }).then(() => {
               nextTick(() => {
                  computedWidth('cate')
               })
            })
         }


         watch(() => route.query.categoryId, (nv, ov) => {

            if (route.path == '/store/article/list') {
               grade.value = route.query.g ? route.query.g : 1
               param.categoryId = route.query.categoryId
               if (route.query.categoryId) {
                  firstCategoryId.value = route.query.categoryId
               } else {
                  firstCategoryId.value = categoryList.list[0].categoryId
               }

               if (route.query.g == 1) {
                  param.isGrade2 = false
                  getArtCate()
               }

               if (!route.query.categoryId) {
                  categoryPath.value = [];
                  grade.value = 1;
                  getArtCate()
               }

            }



         })

         watchEffect(() => {
            if (route.query.vid) {
               getArtList()
            }
         })

         onMounted(() => {
            param.storeId = vid.value

            if (route.query.categoryId) {
               param.categoryId = route.query.categoryId
               cateTree()
            }

            if (!grade.value) {
               getFirstCate()
            }
            if (grade.value == 1) {
               getFirstCate()
            }
         })

         return {
            labelList,
            artCateList,
            grade,
            seeMore,
            artList,
            toDetail,
            showMore,
            paramList,
            ...toRefs(state),
            categoryPath,
            keyword,
            handleCurrentChange,
            vid,
            firstCategoryId,
            categoryList,
            firstToSec
         }
      }
   }
</script>

<style lang="scss" scoped>
   .store_article_main {
      background: #F9F9F9;

      a,
      a:link {
         color: unset;

         &.link {
            width: 100%;
            height: 100%;
         }
      }

      .main_con {
         width: 1200px;
         margin: 0 auto;
         padding-top: 1px;
         padding-bottom: 40px;

         .nav {
            margin-top: 15px;

            .navI {
               margin-right: 8px;

               .box1 {
                  padding: 0 15px;
                  height: 24px;
                  background: #FFFFFF;
                  border: 1px solid #CCCCCC;
                  line-height: 24px;
                  font-size: 12px;
                  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                  font-weight: 400;
                  color: #333333;
                  margin-right: 8px;
               }
            }
         }

         .sel_param {
            margin: 20px 0;
            padding-left: 15px;

            .sel_border {
               height: 15px;
               width: 15px;

               img {
                  height: 15px;
                  width: 15px;
               }
            }

            .param_item {
               margin-right: 15px;
            }

            .sel_offset {
               font-size: 13px;
               margin-left: 5px;
            }

            .clear_param {
               color: $colorMain;
               font-size: $fontE;
               border-bottom: 1px solid $colorMain;
               margin-left: 10px;
               cursor: pointer;
            }
         }

         .param_list {
            margin-top: 30px;
            width: 100%;

            .p_title {
               color: $colorG;
               font-size: $fontE;
            }

            .param_class {
               width: 140px;
               margin-right: 10px;
               position: relative;
               margin-bottom: 20px;

               .list_under {
                  margin-top: 10px;

                  span {
                     font-size: 10px;
                     transform: scale(0.9);
                     margin-left: 3px;
                  }

                  i {
                     width: 8px;
                     height: 8px;
                     background-color: $colorMain;
                  }
               }
            }

            .p_sub_list {
               margin-top: 10px;
               border: 1px solid #E2E2E2;
               width: 100%;
               position: relative;

               .list_top {
                  padding: 6px 4px;
                  display: flex;

                  .top_input {
                     width: 110px;
                     outline: none;
                     border: none;
                  }

                  .el-icon-search {
                     font-size: 14px;
                     color: #999999;
                  }
               }

               .list_bottom {
                  background: #FAFAFA;
                  height: 129px;
                  border-top: 1px solid #E2E2E2;

                  .param_list_item {
                     padding: 7px 5px;
                     color: #666666;
                     cursor: pointer;

                     &.sel_now {
                        background: $colorMain;
                        color: #fff;
                     }
                  }
               }

               .list_abso {
                  position: absolute;
                  bottom: 0;
                  background-color: #fff;
                  height: 26px;
                  border-top: 1px solid #E2E2E2;

                  .la_item {
                     height: 100%;
                     border-right: 1px solid #E2E2E2;
                     position: relative;

                     &:last-child {
                        border-left: 1px solid #E2E2E2;
                        border-right: none;
                     }

                     .filter_i {
                        width: 26px;
                        height: 20px;
                        border: none;
                        outline: none;
                     }

                     .filter_i_con {
                        border-right: 1px solid #E2E2E2;
                        height: 100%;
                        display: flex;
                        align-items: center;
                     }

                     .filter_degree {
                        width: 25px;
                        text-align: center;
                        height: 26px;
                        line-height: 26px;
                        cursor: pointer;

                        &:hover {
                           .degree_list {
                              display: flex;
                           }
                        }
                     }

                     .degree_list {
                        display: none;
                        position: absolute;
                        bottom: 26px;
                        right: -1px;
                        background: #fff;
                        width: 27px;
                        border: 1px solid #E2E2E2;
                        border-bottom: none;

                        .dgr_item {
                           background: #fff;
                           line-height: 20px;
                           cursor: pointer;
                        }
                     }
                  }

                  .line {
                     width: 25px;
                     text-align: center;
                  }
               }
            }
         }

         .filters {
            background: #fff;
            margin-top: 15px;

            .line1 {
               width: 100%;
               padding: 15px;
               padding-bottom: 5px;
               border-bottom: 1px dashed #EEEEEE;
               position: relative;

               .more {
                  position: absolute;
                  top: 47px;
                  left: 0;
                  width: 1200px;
                  padding: 15px;
                  flex-wrap: wrap;
                  background: #fff;
                  z-index: 100;
                  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
                  line-height: 25px;
               }
            }

            .txt1 {
               font-size: 14px;
               font-family: SourceHanSansCN-Medium, SourceHanSansCN;
               font-weight: 600;
               color: #333333;
               margin-right: 10px;
               white-space: nowrap;
            }

            .group1 {
               height: 25px;
               overflow: hidden;
               flex-wrap: wrap;
               max-width: 1020px;
               transition: all .3s;

               &.show_more {
                  height: auto;
                  overflow: visible;
                  flex-wrap: wrap
               }
            }

            .txt2 {
               font-size: 14px;
               font-family: SourceHanSansCN-Regular, SourceHanSansCN;
               font-weight: 400;
               color: #666666;
               margin: 0 15px;
               cursor: pointer;
               white-space: nowrap;
               margin-bottom: 10px;

               &.txtSel {
                  color: $colorMain;
               }

               &:hover {
                  color: $colorMain;
               }

               &.more_box {
                  min-width: 57px;
                  width: 57px;
                  height: 28px;
                  background: #FFFFFF;
                  border: 1px solid #CECECE;
                  opacity: 1;
                  border-radius: 0px;
                  text-align: center;
                  line-height: 28px;
                  margin-top: -5px;
               }
            }
         }

         .list1 {
            margin-top: 20px;
            background: #fff;
         }

         .list2 {
            margin-top: 20PX;
            margin-bottom: 40px;
            width: 1200px;

            .m_left {
               flex: 1;
               background: #fff;
            }
         }

         .list3 {
            margin-top: 20px;
            flex-wrap: wrap;

            .section1 {
               background: #fff;
               width: 590px;
               margin-bottom: 20px;

               .header1 {
                  height: 56px;
                  border-bottom: 1px solid #eee;

                  .t_name {
                     font-size: 22px;
                     font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                     font-weight: bold;
                     color: #333333;
                     padding-left: 20px;
                     box-shadow: 2px 0px $colorMain inset;
                  }

                  .t_more {
                     font-size: 14px;
                     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                     font-weight: 400;
                     color: #666666;
                     cursor: pointer;
                     padding-right: 20px;

                     &:hover {
                        color: $colorMain;
                     }
                  }
               }

               .main1 {
                  padding: 20px;
                  height: 407px;

                  .layer1 {
                     height: 134px;
                     border-bottom: 1px dashed #D8D8D8;
                     cursor: pointer;

                     .txt1 {
                        font-size: 16px;
                        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                        font-weight: bold;
                        color: #333333;

                        &:hover {
                           color: $colorMain;
                        }
                     }

                     .txt2 {
                        margin-top: 15px;
                        line-height: 18px;
                        word-break: break-all;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #858585;
                     }

                     .el-icon-time {
                        font-size: 13px;
                        color: #AEAEAE;
                     }

                     .txt3 {
                        font-size: 13px;
                        color: #AEAEAE;
                     }
                  }

                  .layer2 {
                     .txt4 {
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #333333;
                        margin: 15px 0;
                        cursor: pointer;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        &:hover {
                           color: $colorMain;
                        }
                     }
                  }
               }
            }
         }
      }

      .m_right {
         padding: 20px;
         width: 363px;
         background: #FFF;
         margin-left: 20px;
         position: relative;

         .h_name {
            font-size: 22px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            margin-bottom: 10px;
         }

         .h_list {
            .h1 {
               margin-bottom: 10px;

               .hBox {
                  width: 24px;
                  height: 24px;
                  border-radius: 6px;
                  font-size: 16px;
                  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                  font-weight: bold;
                  color: #F9F9F9;
                  text-align: center;
                  line-height: 24px;

                  background: #9D9D9D;

                  &.box1 {
                     background: #FF6660 !important;
                  }

                  &.box2 {
                     background: #FFA560 !important;
                  }

                  &.box3 {
                     background: #FFB99E !important;
                  }
               }

               .hT {
                  margin-left: 8px;
                  width: 290px;
                  font-size: 14px;
                  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                  font-weight: 400;
                  color: #333333;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
               }
            }
         }
      }

      .empty {
         padding-top: 140px;
         padding-bottom: 100px;

         img {
            width: 100px;
            height: 92px;
            margin-bottom: 20px
         }

         p {
            font-size: 16px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #666666;
            line-height: 24px;
         }
      }
   }
</style>