<template>
  <NavTopBar />
  <div class="sld_cart_top">
    <div class="ld sld_home_top_search_left">
      <router-link tag="a" class="sld_logo_wrap flex_row_start_center" :to="`/index`">
        <img :src="configInfo.main_site_logo" :onerror='defaultImg' alt />
      </router-link>
    </div>
    <div class="sld_seach_wrap">
      <div class="sld_seach_box ld">
        <form class="form" @submit.prevent>
          <el-select class="form_select" v-if="categories.data.length>0" v-model="categoriesVal"
            :placeholder="L['全部分类']" clearable>
            <el-option v-for="item in categories.data" :key="item.categoryId" :label="item.categoryName"
              :value="item.categoryId">
            </el-option>
          </el-select>
          <el-select class="form_select" v-else :placeholder="L['全部分类']" clearable v-model="categoriesVal"></el-select>
          <div class="search_line"></div>
          <input v-model="keyword" type="text" class="text" autocomplete="off" style="color:rgb(153,153,153);"
            placeholder="请输入产品名称/型号/物料编码" @keyup.enter="search" ref="searchInputCart" @focus="inputFocus" />
          <input type="submit" :value="L['搜索']" class="button" @click="search" />
        </form>
      </div>
    </div>
  </div>
  <router-view></router-view>
  <FooterService />
  <FooterLink />
</template>

<script>
  import NavTopBar from "../../components/NavTopBar";
  import FooterService from "../../components/FooterService";
  import FooterLink from "../../components/FooterLink";
  import { useRouter, useRoute } from "vue-router";
  import { ref, getCurrentInstance, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  export default {
    name: 'Cart',
    components: {
      NavTopBar,
      FooterService,
      FooterLink,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const isRouterAlive = true
      const store = useStore();
      const configInfo = ref(store.state.configInfo)
      const router = useRouter();
      const route = useRoute()
      const keyword = ref(
        router.query != undefined &&
          router.query.keyword != undefined &&
          router.query.keyword
          ? router.query.keyword
          : ""
      );
      const categories = reactive({ data: [] });
      const categoriesVal = ref('');
      const search = () => {
        proxy.$refs.searchInputCart.style.color = 'rgb(153,153,153)'
        if (keyword.value) {
          router.push({
            path: `/goods/list`,
            query: { keyword: keyword.value }
          });
        }
      };

      const inputFocus = () => {
        proxy.$refs.searchInputCart.style.color = '#333'
      }

      // 获取分类
      const getCategories = () => {
        proxy
          .$get('v3/goods/front/goods/category/topCategory')
          .then(res => {
            categories.data = res.data;
          })
      }

      onMounted(() => {
        getCategories();
      })

      return {
        configInfo,
        keyword,
        search,
        isRouterAlive,
        L,
        inputFocus,
        categories,
        categoriesVal,
        getCategories,
        route
      }
    }
  }
</script>

<style lang="scss">
  .sld_cart_top {
    width: 1200px;
    height: 98px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sld_home_top_search_left {
      position: relative;
      float: left;
      width: 331px;
      // margin-top: 24px;

      .sld_logo_wrap {
        display: block;
        width: 160px;
        // height: 80px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .sld_seach_wrap {
      width: 590px;
      padding-top: 4px;
      // margin-top: 30px;

      .sld_seach_box {
        width: 580px;
        z-index: 11;
        height: 38px;
        margin-bottom: 3px;

        .form {
          position: relative;
          border: 2px solid $colorMain;

          .search_line {
            width: 2px;
            height: 18px;
            background-color: $colorI;
          }

          .text {
            height: 36px;
            background-color: #fff;
            width: 471px;
            -webkit-appearance: none;
            -webkit-border-radius: 0;
            height: 34px;
            padding: 5px 5px 5px 10px;
            background-position: 0 -360px;
            background-color: #fff;
            background-repeat: repeat-x;
            line-height: 20px;
            font-family: arial, "\5b8b\4f53";
            font-size: 12px;
            outline: none;
            border: none;
          }

          input {
            margin: 0;
            padding: 0;
            height: 34px;
            border: 0;
          }

          input::placeholder {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
          }

          ::-webkit-input-placeholder {

            margin-left: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
          }

          /* 使用webkit内核的浏览器 */
          :-moz-placeholder {

            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
          }

          /* Firefox版本19+ */
          :-ms-input-placeholder {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
          }

          .button {
            position: absolute;
            top: -1px;
            right: 0;
            width: 103px;
            height: 36px;
            background: $colorMain;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            float: right;
            cursor: pointer;
          }
        }

        .hot_search_wrap {
          height: 30px;
          line-height: 30px;
          color: #999;
          overflow: hidden;
          white-space: nowrap;

          strong {
            float: left;
            font-weight: 400;
          }

          a {
            color: #666;

            &:link,
            &:visited {
              float: left;
              margin-right: 10px;
            }

            &:hover {
              color: $colorMain;
            }
          }
        }
      }
    }

    .form {
      display: flex;
      align-items: center;
    }

    .el-select {
      height: 34px;
      line-height: 34px;
    }

    .el-select .el-input {
      width: 100px;
      height: 34px;
    }

    .el-input--suffix .el-input__inner {
      height: 34px;
      padding-right: 25px;
      border-radius: 0;
      padding-bottom: 1px;
      text-indent: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .el-input__suffix-inner {
      position: relative;
      bottom: 3px;
    }
  }
</style>