<template>
    <div :class="{sld_store_item:true,skeleton_sld_store_item:skeleton?skeleton:false}" class="flex_row_between_center">
        <div class="sld_vendor flex_row_start_start">
            <div class="sld_vendor_logo sld-img-center">
                <router-link :to="`/store/index?vid=${item.storeId}`" target="_blank">
                    <img :src="item.storeLogo" alt="">
                </router-link>
            </div>
            <div class="vendor_info">
                <div class="sld_vendor_name">{{item.storeName}}</div>
                <div class="vendor_certification flex_row_start_center" v-if="item.relatedQualificationList.length">
                    <div v-for="(item,index) in item.relatedQualificationList" class="cr_img">
                        <img :src="item" class="img">
                        <div class="bd122">
                            <img :src="item" class="bd122_img" />
                        </div>
                    </div>
                </div>
                <div class="all_cer" v-if="item.relatedQualificationList.length">
                    <router-link :to="`/store/introduce?vid=${item.storeId}`" target="_blank">
                        <span>全部资质></span>
                    </router-link>
                </div>
                <div class="sale_num">
                    <span>已售</span>
                    <span>{{item.storeSales}}</span>
                </div>
            </div>


        </div>
        <div class="main_lbbox">

            <div class="sld_vendor_goods clearfix">
                <div class="hd">
                    <a href="javascript:;" class="next" @click="slideWin(1)" ref="next">
                        <i class="iconfont iconziyuan111"></i>
                    </a>
                    <a href="javascript:;" class="prev" @click="slideWin(0)" ref="prev">
                        <i class="iconfont iconziyuan111"></i>
                    </a>
                </div>
                <div class="slide_wrap">
                    <ul class="bd clearfix">
                        <li class="new_goods" v-for="(newGoods,index) in item.goodsList" :key="index">
                            <router-link :to="`/goods/detail?productId=${newGoods.defaultProductId}`" target="_blank">
                                <div class="sld_img sld-img-center">
                                    <div class="img" :style="{backgroundImage:'url('+newGoods.goodsImage+')'}"></div>
                                </div>
                                <p class="goods_name">{{newGoods.goodsName}}</p>
                                <p class="goods_price">￥{{newGoods.goodsPrice}}</p>
                            </router-link>
                        </li>
                        <div class="empty" v-show="!item.goodsList.length">
                            <img src="../../assets/goods/empty_data.png" alt="">
                            <p>本店暂无产品～</p>
                        </div>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { ref, onMounted, getCurrentInstance } from 'vue'
    import { ElRate } from 'element-plus'
    export default {
        name: 'storeListItem',
        props: ["item", "ItemIndex", 'skeleton'],
        components: {
            ElRate,
        },
        data() {
            return {
                value: 3
            }
        },
        setup(props) {
            const indexNum = ref(2)
            //新物品和热销物品按钮切换
            const isOnLogic = (index) => {
                indexNum.value = index
            }
            const { proxy } = getCurrentInstance()
            const score = ref(Number(props.item.serviceScore))
            const colors = ref(['#E2231A', '#E2231A', '#E2231A'])


            //商品滑动
            const notClickQuick = ref(true)//防止快速点击造成dom混乱
            const slideWin = (ltr) => {
                let ul = document.getElementsByClassName('bd')[props.ItemIndex]
                let liLength = ul.getElementsByClassName('new_goods').length
                let isanimate = false
                let max = liLength % 4 == 0 ? liLength * 180 : (Math.floor(liLength / 4) + 1) * 4 * 180
                let nowMg = parseInt(getComputedStyle(ul, null).marginLeft)
                let slide_wrap_width = max - document.getElementsByClassName('slide_wrap')[props.ItemIndex].offsetWidth
                ul.style.width = max + 'px'
                if (notClickQuick.value) {
                    notClickQuick.value = false
                    if (slide_wrap_width < 0 || isanimate) return;
                    isanimate = true;
                    let n = 180;
                    if (ltr) {
                        nowMg = nowMg - (n * 4)
                        ul.style.marginLeft = (slide_wrap_width >= Math.abs(nowMg) ? nowMg : nowMg + n * 4) + 'px'
                    } else {
                        nowMg = nowMg + (n * 4)
                        ul.style.marginLeft = (nowMg <= 0 ? nowMg : 0) + 'px'
                    }
                    proxy.$refs.next.style.backgroundColor = slide_wrap_width > Math.abs(nowMg) + 4 * 180 ? "#8d8b8b" : "#ccc"
                    proxy.$refs.prev.style.backgroundColor = nowMg >= 0 ? "#ccc" : "#8d8b8b"
                    isanimate = false;
                    setTimeout(function () {
                        notClickQuick.value = true;
                    }, 500)
                }

            }
            onMounted(() => {
                if (props.item.goodsList.length < 5) {
                    proxy.$refs.next.style.backgroundColor = "#ccc"
                    proxy.$refs.prev.style.backgroundColor = "#ccc"
                }
            })

            return { slideWin, indexNum, isOnLogic, score, colors }

        },
    }
</script>

<style lang="scss" scoped>
    @import "@/style/storeList.scss";
    @import "@/style/base.scss";

    .empty {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            color: #666;
            margin-left: 11px;
        }
    }


    .cr_img {
        position: relative;

        &:hover {
            .bd122 {
                display: block;
            }
        }
    }

    .bd122 {
        position: absolute;
        z-index: 99;
        display: none;
        transition: all .5s ease;
        background: #fff;
        border: 1px solid #f0f0f0;
        box-shadow: rgb(0 0 0 / 15%) 3px 3px 10px;

        .bd122_img {
            max-width: 200px;
            max-height: 200px;
            border-radius: 8px;
        }
    }
</style>