<template>
     <div :class="{
          act_banner: true,
          sec_not: seckillInfo.data.state == 1,
          sec_do: seckillInfo.data.state == 2
     }" v-if="JSON.stringify(seckillInfo.data) != '{}'">
          <div class="title">{{ L['秒杀'] }}</div>
          <div class="time_title">
               {{
                         seckillInfo.data.state == 1
                              ? L['距离开始剩余：']
                              : L['距离结束剩余：']
               }}
          </div>
          <div class="time">
               <span v-if="time.day" class="countDown0">{{ time.day }}{{ L['天'] }}</span>
               <span class="countDown1">{{ time.hours }}</span>
               <span class="countDown2">:</span>
               <span class="countDown1">{{ time.minutes }}</span>
               <span class="countDown2">:</span>
               <span class="countDown1">{{ time.seconds }}</span>
          </div>
          <div class="desc">
               <div class="SorC_remind" @click="sorcRemind" v-show="seckillInfo.data.state == 1" :class="{
                    remind_on: !seckillInfo.data.isRemind,
                    remind_off: seckillInfo.data.isRemind
               }">
                    <img src="@/assets/activity/clock.png" alt="" v-if="!seckillInfo.data.isRemind" />
                    <!-- <img src="@/assets/activity/clock_grey.png" alt="" v-if="seckillInfo.data.isRemind" /> -->
                    <div>
                         {{ seckillInfo.data.isRemind ? L['取消提醒'] : L['提醒我'] }}
                    </div>
               </div>
          </div>

          <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="loginModalVisibleFlag = false"
               @refreshInfo="refreshInfo" />
     </div>
</template>

<script>
import { onMounted, ref, reactive, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import SldLoginModal from "@/components/loginModal";
import { useStore } from 'vuex';

export default {
     props: {
          promotionId: String | Number
     },

     components: {
          SldLoginModal
     },

     setup(props, { emit }) {
          const { proxy } = getCurrentInstance()
          const store = useStore()
          const seckillInfo = reactive({ data: {} })
          const L = proxy.$getCurLanguage()
          const route = useRoute()
          const router = useRouter()
          const secInt = ref(null)
          const time = reactive({
               day: '00',
               hours: '00',
               minutes: '00',
               seconds: '00'
          })

          const loginModalVisibleFlag = ref(false)

          const getSeckill = () => {
               let param = {
                    productId: route.query.productId,
                    promotionId: props.promotionId
               }
               proxy.$get('v3/promotion/front/seckill/detail', param).then(res => {
                    if (res.state == 200) {
                         seckillInfo.data = res.data
                         emit('getSeckillInfo', seckillInfo.data)
                         let now = new Date()
                         let countTime = 0
                         let startTime = new Date(res.data.startTime)

                         if (seckillInfo.data.state == 1 || seckillInfo.data.state == 2) {
                              countTime = res.data.distanceEndTime
                              countDown(countTime)
                         } else {
                              countTime = startTime.getTime() - now.getTime()
                         }
                    }
               })
          }

          const countDown = countTime => {
               secInt.value = setInterval(() => {
                    if (countTime == 0) {
                         emit('refresh', route.query.productId)
                         clearInterval(secInt.value)
                    } else {
                         countTime--
                         let day = parseInt(countTime / 60 / 60 / 24)
                         let hours = parseInt((countTime / 60 / 60) % 24)
                         let minutes = parseInt((countTime / 60) % 60)
                         let seconds = parseInt(countTime % 60)
                         time.day = day
                         time.hours = hours > 9 ? hours : '0' + hours
                         time.minutes = minutes > 9 ? minutes : '0' + minutes
                         time.seconds = seconds > 9 ? seconds : '0' + seconds
                    }
               }, 1000)
          }



          const sorcRemind = () => {


               if (!store.state.loginFlag) {
                    loginModalVisibleFlag.value = true
               }



               let param = {
                    stageProductId: seckillInfo.data.stageProductId
               }

               proxy.$post('v3/promotion/front/seckill/isRemind', param).then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         seckillInfo.data.isRemind = !seckillInfo.data.isRemind
                    }
               })
          }


          const refreshInfo = () => {
               history.go(0)
          }


          onMounted(() => {
               getSeckill()
          })

          return {
               time,
               sorcRemind,
               getSeckill,
               seckillInfo,
               L,
               loginModalVisibleFlag,
               refreshInfo
          }
     }
}
</script>

<style lang="scss" scoped>
.act_banner {
     width: 100%;
     height: 50px;
     background-position: center center;
     padding-left: 18px;
     padding-right: 20px;
     display: flex;
     align-items: center;
     position: relative;

     &.sec_not {
          background: linear-gradient(90deg, #FD5544 0%, #FF1434 100%);
     }

     &.sec_do {
          background: linear-gradient(90deg, #FD5544 0%, #FF1434 100%);
     }

     .SorC_remind {
          padding: 0 15px;
          height: 25px;
          background: #ffffff;
          border-radius: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
               width: 20px;
               height: 20px;
               margin-right: 3px;
          }

          &.remind_on {
               color: #e60012;
               font-size: 13px;
               letter-spacing: 1px;
               font-weight: bold;
               font-family: Microsoft YaHei;
          }

          &.remind_off {
               color: #fff;
               font-size: 13px;
               letter-spacing: 1px;
               font-weight: bold;
               font-family: Microsoft YaHei;
               background-color: #FF1434;
               border: 1px solid #fff;
          }
     }

     .countDown0 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 700;
          color: #ffffff;
     }

     .countDown1 {
          // width: 20px;
          // height: 18px;
          padding: 2px 3px;
          background: #ffffff;
          border-radius: 50%;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #f12826;
          text-align: center;
          line-height: 18px;
          margin-left: 5px;
     }

     .countDown2 {
          font-size: 13px;
          color: #fff;
          margin-left: 5px;
     }

     .title {
          font-size: 20px;
          font-family: HelloFont WenYiHei;
          font-weight: bold;
          font-style: italic;
          color: #ffffff;
     }

     .time_title {
          font-size: 14px;
          margin-left: 20px;
          color: #fff;
     }

     .desc {
          font-size: 14px;
          color: #fff;
          position: absolute;
          right: 20px;
          display: flex;
     }
}
</style>