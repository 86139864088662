<template>
    <div class="sld_member_top">
        <div class="sld_member_top_zoom clearfix">
            <div class="container">
                <dl class="fl sld_left_part">
                    <dt class="sld-user-logo sld_img_center">
                        <router-link tag="a" :to="`/index`" class="flex_row_start_center">
                            <img :src="configInfo.main_user_center_logo ? configInfo.main_user_center_logo : defaultImg"
                                :onerror='defaultImg' alt />
                        </router-link>
                    </dt>
                    <dd>
                        <router-link tag="a" :to="`/member/index`" :class="{cur_tag_active:curTag == 1}">
                            {{L['首页']}}
                        </router-link>
                    </dd>
                    <dd>
                        <a href="javascript:void(0)"
                            :class="{account_tag:true,cur_tag_active:curTag == 2}">{{L['账户管理']}}
                            <span class="iconfont iconziyuan11-copy account_arrow"></span>
                            <div class="account_popup">
                                <div class="content">
                                    <router-link to="/member/info" class="content_item">
                                        <span>{{L['会员信息']}}</span>
                                    </router-link>
                                    <router-link to="/member/account" class="content_item">
                                        <span>{{L['账号安全']}}</span>
                                    </router-link>
                                    <router-link to="/member/phone" class="content_item">
                                        <span>{{L['手机号管理']}}</span>
                                    </router-link>
                                    <router-link to="/member/email" class="content_item">
                                        <span>{{L['邮箱管理']}}</span>
                                    </router-link>
                                    <router-link to="/member/pwd/login" class="content_item">
                                        <span>{{L['登录密码']}}</span>
                                    </router-link>
                                    <router-link to="/member/pwd/pay" class="content_item">
                                        <span>{{L['支付密码']}}</span>
                                    </router-link>
                                    <router-link to="/member/pwd/reset" v-if="memberInfo.data.hasPayPassword==1"
                                        class="content_item">
                                        <span>{{L['重置密码']}}</span>
                                    </router-link>
                                    <router-link to="/member/address/list" class="content_item">
                                        <span>{{L['收货地址']}}</span>
                                    </router-link>
                                </div>
                            </div>
                        </a>
                    </dd>
                    <dd>
                        <router-link tag="a" :to="`/msg/list?curTplTypeCode=msg_all`"
                            :class="{cur_tag_active:curTag == 3}">
                            {{L['消息']}}
                            <span class="msg_num" v-if="memberInfo.data.msgNum > 0">{{memberInfo.data.msgNum > 99 ?
                                '99+'
                                : memberInfo.data.msgNum}}</span>
                        </router-link>
                    </dd>
                </dl>
                <div class="fr">
                    <div class="sld_search_part fl">
                        <el-select class="form_select" v-if="categories.data.length > 0" v-model="categoriesVal"
                            :placeholder="L['全部分类']" clearable>
                            <el-option v-for="item in categories.data" :key="item.categoryId" :label="item.categoryName"
                                :value="item.categoryId">
                            </el-option>
                        </el-select>
                        <el-select class="form_select" v-else :placeholder="L['全部分类']" clearable
                            v-model="categoriesVal"></el-select>
                        <input v-model="keyword" type="text" class="text" placeholder="请输入产品名称/型号/物料编码"
                            autocomplete="off" @focus="inputFocus" ref="searchInput">
                        <input type="submit" :value="L['搜索']" class="button" @click="search" />
                    </div>
                    <div class="fl sld_cart_part"
                        v-if="memberInfo.data.memberType==2 || memberInfo.data.memberType==3&&memberInfo.data.enterpriseMaster!=1">
                        <dl>
                            <dt class="ld first_dt center_text">
                                <span @click="navto('/cart/request')" class="iconfont iconrz3"></span>
                                <s></s>
                                <router-link target="_blank" :to="`/cart/request`">{{L['样品申请单']}}</router-link>
                                <!-- 没有商品显示0 -->
                                <div class="addcart_goods_num">{{sampleData.data.availableSampleCartNum ?
                                    sampleData.data.availableSampleCartNum : sampleData.data.totalCheck ?
                                    sampleData.data.totalCheck : 0}}</div>
                            </dt>
                            <dd class="cart_more_view">
                                <request-model></request-model>
                            </dd>
                        </dl>
                    </div>
                    <div class="fl sld_cart_part" v-else>
                        <dl class>
                            <dt class="ld first_dt center_text">
                                <img @click="navto('/quickOrder')" class="cart_icon_text_img"
                                    src="@/assets/home_sampleCart.png" />
                                <router-link target="_blank" :to="`/quickOrder`">{{ L["快速下单"] }}</router-link>
                            </dt>
                        </dl>
                    </div>
                    <div class="fl sld_cart_part">
                        <dl>
                            <dt class="ld first_dt center_text">
                                <img @click="navto('/cart/index')" class="cart_icon_text_img"
                                    src="@/assets/home_cart.png" />
                                <s></s>
                                <router-link target="_blank" :to="`/cart/index`">{{L['我的购物车']}}</router-link>
                                <!-- 没有商品显示0 -->
                                <div class="addcart_goods_num">{{cartData.data.availableCartNum ?
                                    cartData.data.availableCartNum : cartData.data.totalCheck ?
                                    cartData.data.totalCheck : 0}}</div>
                            </dt>
                            <dd class="cart_more_view">
                                <cart-model></cart-model>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, reactive, getCurrentInstance, watchEffect, onMounted } from 'vue';
    import { useRouter } from "vue-router";
    import { useStore } from 'vuex';
    import CartModel from '@/components/CartModel';
    import RequestModel from '@/components/RequestModel';

    export default {
        name: 'MemberTop',
        components: { CartModel, RequestModel },
        setup() {
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const keyword = ref('');
            const curTag = ref(1);//当前选中的tag，1为首页，2为账户管理，3为消息
            const router = useRouter();
            const currentHref = ref('');
            const store = useStore();
            const cartData = reactive({ data: {} }); //获取vux的store中的购物车数据
            const sampleData = reactive({ data: {} }); //获取vux的store中的申请单数据
            const memberInfo = reactive({ data: {} }); //个人信息
            const configInfo = ref(store.state.configInfo);
            const defaultImg = ref('this.src="' + require('../assets/member/top_logo.png') + '"');
            const categories = reactive({ data: [] });
            const categoriesVal = ref('');

            onMounted(() => {
                if (store.state.loginFlag) { //已登录，重新获取购物车数据
                    proxy.$getLoginCartListData();
                    proxy.$getLoginSampleListData();
                }
                memberInfo.data = store.state.memberInfo;
                cartData.data = store.state.cartListData;
                sampleData.data = store.state.sampleListData;
                if (store.state.loginFlag) {
                    //获取购物车数量
                    proxy.$get("v3/business/front/cart/cartNum").then((res) => {
                        if (res.state == 200) {
                            cartData.data.availableCartNum = res.data ? res.data : 0;
                        }
                    });
                    if (memberInfo.data.memberType == 2) {
                        //获取样品购物车数量
                        proxy.$get("v3/business/front/sampleCart/cartNum").then((res) => {
                            if (res.state == 200) {
                                sampleData.data.availableSampleCartNum = res.data ? res.data : 0;
                                store.commit('updateSampleListData', sampleData.data);
                            }
                        });
                    }
                }
                getCategories();
            })

            //搜索事件
            const search = () => {
                proxy.$refs.searchInput.style.color = 'rgb(153,153,153)'
                if (keyword.value) {
                    router.push({ path: `/goods/list`, query: { keyword: keyword.value } });
                }
            }

            const inputFocus = () => {
                proxy.$refs.searchInput.style.color = '#333'
            }

            watchEffect(() => {
                let tmpRoute = router.currentRoute.value;
                if (tmpRoute.fullPath.indexOf('/msg/') > -1) {
                    curTag.value = 3;
                }
                cartData.data = store.state.cartListData;
                sampleData.data = store.state.sampleListData;
                memberInfo.data = store.state.memberInfo;
                //获取购物车数量
                if (store.state.loginFlag && currentHref.value != router.currentRoute.value.href) {
                    currentHref.value = router.currentRoute.value.href;
                    proxy.$get("v3/business/front/cart/cartNum").then((res) => {
                        if (res.state == 200) {
                            cartData.data.availableCartNum = res.data ? res.data : 0;
                        }
                    });
                    if (memberInfo.data.memberType == 2) {
                        //获取样品购物车数量
                        if (tmpRoute != '/login') {
                            proxy.$get("v3/business/front/sampleCart/cartNum").then((res) => {
                                if (res.state == 200) {
                                    sampleData.data.availableSampleCartNum = res.data ? res.data : 0;
                                    store.commit('updateSampleListData', sampleData.data);
                                }
                            });
                        }

                    }
                }
            });

            // 获取分类
            const getCategories = () => {
                proxy
                    .$get('v3/goods/front/goods/category/topCategory')
                    .then(res => {
                        categories.data = res.data;
                    })
            };

            const navto = (url) => {
                let newWin = router.resolve({
                    path: url
                })
                window.open(newWin.href, '_blank');
            };

            return {
                keyword, search, L, cartData, sampleData, curTag, memberInfo, configInfo, navto,
                defaultImg, inputFocus, categories, getCategories, categoriesVal, currentHref
            };
        }
    }
</script>

<style lang="scss" scoped>
    .sld_member_top {
        .sld_member_top_zoom {
            width: 100%;
            height: 64px;
            background-color: $colorMain;
            margin-bottom: 10px;

            .container {
                height: 64px;
                width: 1210px;
                margin: 0 auto;
                padding-left: 0px;
                padding-right: 0px;

                &:after,
                &:before {
                    display: table;
                    content: " ";
                }

                .fl {
                    float: left;
                }

                .fr {
                    float: right;
                }
            }

            .sld_left_part {
                color: #fff;

                dt {
                    float: left;
                    width: 127px;
                    height: 60px;
                    margin: 10px 35px 10px 0px;

                    img {
                        cursor: pointer;
                    }

                    &.sld_img_center {
                        position: relative;
                        width: 120px;
                        height: 44px;
                        overflow: hidden;

                        a {
                            width: 100%;
                            height: 100%;
                        }

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }

                dd {
                    position: relative;
                    float: left;

                    a {
                        display: block;
                        line-height: 64px;
                        color: rgba(255, 255, 255, .8);
                        margin: 0 25px;
                        font-size: 15px;

                        &.router-link-active {
                            &::after {
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                content: '';
                                width: 0;
                                height: 0;
                                transform: translateX(-50%);
                                border-width: 6px;
                                border-style: solid;
                                border-color: transparent transparent #fff transparent;
                            }
                        }

                        .msg_num {
                            display: block;
                            position: absolute;
                            background: #ffffff;
                            color: $colorMain2;
                            min-width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            right: 5px;
                            top: 10px;
                            line-height: 20px;
                            text-align: center;
                        }

                        // &.cur_tag_active {
                        //     &::after {
                        //         position: absolute;
                        //         bottom: 0;
                        //         left: 50%;
                        //         content: '';
                        //         width: 0;
                        //         height: 0;
                        //         transform: translateX(-50%);
                        //         border-width: 6px;
                        //         border-style: solid;
                        //         border-color: transparent transparent #fff transparent;
                        //     }
                        // }

                        .account_arrow {
                            color: rgba(255, 255, 255, 0.8);
                            font-size: 10px;
                            margin-left: 2px;
                            display: inline-block;
                        }

                        &:hover {
                            .account_arrow {
                                transform: rotate(180deg);
                                transition: transform 0.2s ease-in 0s, -webkit-transform 0.2s ease-in 0s;
                            }
                        }
                    }

                    .account_tag:hover {
                        .account_popup {
                            display: block;
                            opacity: 1;
                            -webkit-animation: showPopUp .4s ease-in-out;
                            animation: showPopUp .4s ease-in-out;

                            @-webkit-keyframes showPopUp {
                                0% {
                                    opacity: 0
                                }

                                to {
                                    opacity: 1
                                }
                            }

                            @keyframes showPopUp {
                                0% {
                                    opacity: 0
                                }

                                to {
                                    opacity: 1
                                }
                            }

                        }
                    }

                    .account_popup {
                        display: none;
                        opacity: 0;
                        top: 40px;
                        left: 50%;
                        margin-left: -50px;
                        padding: 15px 0;
                        width: 100px;
                        position: absolute;
                        z-index: 11;

                        &::before {
                            z-index: 1;
                            top: 10px;
                            margin-left: -5px;
                            width: 10px;
                            height: 10px;
                            box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
                            background-color: #fff;
                        }

                        &::after,
                        &::before {
                            content: '';
                            position: absolute;
                            left: 50%;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }

                        .content {
                            padding: 10px 0 10px 23px;
                            overflow: hidden;
                            box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
                            background-color: #fff;

                            .content_item {
                                float: left;
                                width: 80px;
                                color: #333;
                                -webkit-transition: all .4s ease;
                                transition: all .4s ease;
                                height: 30px;
                                line-height: 30px;
                                font-size: 12px;
                                margin: 0;

                                &:hover {
                                    color: $colorMain;
                                }
                            }
                        }
                    }
                }
            }

            .sld_cart_part {
                float: right;
                position: relative;
                z-index: 99;
                margin-top: 16px;

                dd {
                    top: 32px;
                    position: absolute;
                    right: 0;
                    width: 355px;
                    border: 1px solid #E3E3E3;
                    background: #fff;
                    z-index: 3;
                }

                dl {
                    display: flex;
                    align-items: center;
                    height: 34px;
                    background-color: #FFF;
                    padding-left: 8px;
                    padding-right: 8px;
                    margin-left: 15px;

                    dd {
                        .incart_goods {
                            dl {
                                padding-top: 8px;
                            }

                            dd {
                                &.mcart_price {
                                    position: static;

                                    em {
                                        margin-right: 6px;
                                        width: auto;
                                        color: #666;

                                        &:nth-child(1) {
                                            display: block;
                                            font-weight: 600;
                                        }

                                        &:nth-child(2) {
                                            display: block;
                                            text-align: right;
                                            margin-top: 6px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .addcart_goods_num {
                        font: 11px/16px Verdana;
                        color: #FFF;
                        background: $colorMain2;
                        text-align: center;
                        display: inline-block;
                        height: 16px;
                        min-width: 16px;
                        border: none 0;
                        border-radius: 8px;
                        margin-left: 10px;
                    }

                    .iconfont {
                        margin: 0 8px;
                        color: $colorMain;
                        font-size: 17px;
                        font-weight: 600;
                        vertical-align: bottom;
                    }
                }

                dt {
                    position: absolute;
                    z-index: 3;
                    cursor: pointer;
                    font-weight: 400;
                    line-height: 30px;
                }

                .first_dt {

                    a {
                        color: #666666;
                        font-size: 14px;
                        line-height: 30px;
                    }

                    .cart_icon_text_img {
                        position: relative;
                        top: 1px;
                        width: 17px;
                        margin-right: 6px;
                    }
                }

                .cart_more_view {
                    display: none;

                    .empty_cart {
                        width: 100%;
                        position: relative;

                        .empty_cart_line {
                            position: absolute;
                            width: 163px;
                            right: 0;
                            height: 2px;
                            top: -2px;
                            z-index: 999;
                            background: #fff;
                        }

                        .empty_cart_txt {
                            padding: 10px;
                            color: #999;
                        }
                    }
                }

                &:hover .cart_more_view {
                    display: inline-block;
                }

                .ld {
                    position: relative;
                    zoom: 1;
                    display: flex;
                    align-items: center;
                }
            }

            .sld_search_part {
                width: 420px;
                height: 34px;
                background-color: #fff;
                margin-top: 15px;
                margin-right: 20px;
                display: flex;
                align-items: center;

                input {
                    height: 34px;
                    border: none;
                    outline: none;
                    background-color: #fff;

                    &.text {
                        width: 255px;
                        line-height: 34px;
                        padding-left: 10px;
                    }

                    &.button {
                        height: 34px;
                        color: $colorG;
                        font-size: 14px;
                        cursor: pointer;
                        padding: 0 18px;
                        background-color: $colorJ;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .sld_search_part {
        .el-select {
            height: 34px;
            line-height: 34px;

            .el-input {
                width: 100px;
                height: 34px;
            }

            .el-input input::-webkit-input-placeholder {
                color: $colorG;
            }

            .el-input input::-moz-input-placeholder {
                color: $colorG;
            }

            .el-input input::-ms-input-placeholder {
                color: $colorG;
            }

            .el-input__inner:focus {
                border-color: $colorI;
            }

            .el-input.is-focus {
                .el-input__inner {
                    border-color: $colorI;
                }
            }
        }

        .form_select+input::-webkit-input-placeholder {
            color: #AAAAAA;
        }

        .form_select+input::-moz-input-placeholder {
            color: #AAAAAA;
        }

        .form_select+input::-ms-input-placeholder {
            color: #AAAAAA;
        }

        .el-input--suffix .el-input__inner {
            height: 34px;
            line-height: 34px;
            font-size: 13px;
            padding-left: 10px;
            padding-right: 25px;
            border-radius: 0;
            padding-bottom: 1px;
            border-left: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .el-input__suffix-inner {
            position: relative;
            bottom: 3px;
        }

    }

    .el-input__inner+.el-input__suffix-inner {
        position: relative;
        bottom: 3px;
    }
</style>