<template>
     <div class="contrast_main">
          <div class="contrast_con">
               <div class="title">{{targetIds.type=='series'?'系列对比结果':'产品对比结果'}}</div>
               <div class="bd4 flex_row_start_center">
                    <el-checkbox label="高亮不同项" @change="showDiff($event,'diff')" />
                    <el-checkbox label="隐藏相同项" @change="showDiff($event,'same')" />
               </div>
               <div class="table">
                    <div class="top_title" v-if="targetIds.type=='goods'">基本信息</div>
                    <div class="section2">

                         <div class="wrap1 flex-row">
                              <div class="main6">
                                   <div class="info9 flex_row_center_center">{{targetIds.type=='product'?'产品信息':'系列信息'}}
                                   </div>
                              </div>
                              <div class="main7 flex_row_around_start">
                                   <div class="col_box" v-for="(attr,atx) in imgList">
                                        <div class="box2 flex_column_center_center">
                                             <img :src="attr.image" referrerpolicy="no-referrer" />
                                        </div>
                                        <p class="col_name">{{attr.name}}</p>
                                   </div>
                              </div>
                         </div>

                         <div v-if="contrastSpecs.length">
                              <div class="part_title">规格值</div>
                              <div class="wrap1 flex-row" v-for="(spec,specIdx) in contrastSpecs" :key="specIdx">
                                   <div class="main6 flex_row_center_center">
                                        <div class="info91 ">{{spec.specName}}</div>
                                   </div>
                                   <div class="main7 flex_row_around_center">
                                        <div class="col_box flex_row_center_center"
                                             v-for="(spec1,specIdx1) in spec.specValueList" :key="specIdx1">
                                             <div class="word1612 flex_column_center_center" v-if="spec1.length">
                                                  <span class="word161_item" :class="{checked:specValue.checkState==1}"
                                                       v-for="(specValue,valIdx) in spec1" :key="valIdx"
                                                       @click="selectSpecVal(specValue,spec)">{{specValue.specValue}}</span>
                                             </div>
                                             <div class="word1612 flex_column_center_center" v-else>--</div>
                                        </div>
                                   </div>
                              </div>
                         </div>


                         <div v-if="contrastList.length">
                              <div class="part_title">属性值</div>
                              <div class="wrap1 flex-row" v-for="(attr,atx) in contrastList" :key="atx">
                                   <div class="main6 flex_row_center_center"
                                        :class="{hide:attr.isSame,isHighLight:attr.isDiff}">
                                        <div class="info91 ">{{attr.attributeName}}</div>
                                   </div>
                                   <div class="main7 flex_row_around_center">
                                        <div class="col_box flex_row_center_center"
                                             :class="{hide:attr.isSame,isHighLight:attr.isDiff}"
                                             v-for="(attr1,atx1) in attr.attributeValueList" :key="atx1">
                                             <div class="word161 ">{{attr1||'--'}}</div>
                                        </div>
                                   </div>
                              </div>
                         </div>


                    </div>
               </div>
               <div class="table" v-if="conGoodsInfo.length">
                    <div class="top_title">销售信息</div>
                    <div class="section2 flex-row">
                         <div class="main6">
                              <div :class="{word16:true,flex_row_center_center:true,hide:cGc[index]=='same'}"
                                   v-for="(item,index) in ['单价']" :key="index">{{item}}</div>
                         </div>
                         <div class="main7 flex_row_around_start">
                              <div class="col_box flex_column_center_center" v-for="(info,index1) in conGoodsInfo"
                                   :key="index1">
                                   <div
                                        :class="{word16:true,flex_row_center_center:true,isHighLight:cGc[0]=='diff',hide:cGc[0]=='same'}">
                                        <span>
                                             {{info.productPrice>0?info.productPrice.toFixed(2):0}}/吨</span>
                                   </div>

                                  
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, toRefs, computed } from "vue";
     import { useRoute, useRouter } from 'vue-router'
     import { ElMessage } from 'element-plus'
     export default {
          setup() {
               const route = useRoute();
               const router = useRouter();
               const { proxy } = getCurrentInstance();
               const targetIds = reactive({
                    idSet: route.query.ids,
                    type: route.query.type
               })

               const attrNameList = ref([])
               const contrastList = ref([])
               const scrollTop = ref(null)
               const conGoodsInfo = ref([])
               const imgList = ref([])
               const rawContrastSpecs = ref([])
               const contrastSpecs = ref([])
               const cGc = ref(['', '', ''])

               const initDetail = () => {
                    let url = targetIds.type == 'product' ? 'v3/goods/front/goods/productCompare' : 'v3/goods/front/goods/seriesCompare'
                    let param = {
                         [`${targetIds.type=='product'?'goods':targetIds.type}Ids`]: decodeURIComponent(targetIds.idSet)
                    }

                    return new Promise(resolve => {
                         proxy.$get(url, param).then(res => {
                              if (res.state == 200) {
                                   let result = res.data
                                   res.data.forEach(item=>{
                                        let obj1 = [
                                        {
                                             attributeName:'品名',
                                             attributeValue:item.goods.goodsName,
                                        },
                                        {
                                             attributeName:'规格',
                                             attributeValue:item.goodsProperty.specModel?item.goodsProperty.specModel:'--',
                                        },
                                        {
                                             attributeName:'材质',
                                             attributeValue:item.goodsProperty.material?item.goodsProperty.material:'--',
                                        },
                                        {
                                             attributeName:'产地',
                                             attributeValue:item.goodsProperty.originPlace?item.goodsProperty.originPlace:'--',
                                        },
                                        {
                                             attributeName:'货物存在地',
                                             attributeValue:item.goodsProperty.presencePlace?item.goodsProperty.presencePlace:'--',
                                        },
                                        ]
                                        item.attributes = obj1
                                   })
                                   if (route.query.foreignId) {
                                        resolve(...result)
                                   } else {
                                        result
                                        resolve(result)
                                   }
                              } else {
                                   ElMessage.error(res.msg)
                              }
                         })
                    })
               }

               const getForeignDetail = () => {
                    let param = {
                         foreignId: route.query.foreignId
                    }
                    return new Promise(resolve => {
                         proxy.$get('v3/goods/front/goods/foreign/detail', param).then(res => {
                              if (res.state == 200) {
                                   resolve(res.data)
                              }
                         })
                    })
               }

               const handleBoth = () => {
                    window.scrollTo(0, scrollTop.value)
                    if (route.query.foreignId) {
                         Promise.all([getForeignDetail(), initDetail()]).then(res => {
                              handleForContrast(res)
                         })
                    } else {
                         initDetail().then(res => {

                              targetIds.type == 'product' && handleForSpecContrast(res)
                              handleForContrast(res)
                         })
                    }
               }

               const handleForContrast = (result) => {
                    let falsTmp = result.map(i => (targetIds.type == 'series' ? i.seriesAttributeList : i.attributes))
                    let attrArrayStep1 = [].concat.apply([], falsTmp).map(i => i.attributeName) //提取出属性名
                    let attrArrayStep2 = Array.from(new Set(attrArrayStep1)) //由Set特性 合并相同的项来去重
                    let obj = {}
                    let obj2 = {} //基本信息属性是否重复

                    //二次循环 用已去重的attrArrayStep2 来进行行列转换，利用Object属性赋值来集合相同属性名下的属性值
                    result.forEach(item1 => {
                         let arrtList = (targetIds.type == 'series' ? item1.seriesAttributeList : item1.attributes)
                         attrArrayStep2.forEach(item => {
                              let idx = arrtList.findIndex(i => i.attributeName === item)
                              if (idx > -1) {
                                   if (!obj[item]) {
                                        obj[item] = [arrtList[idx].attributeValue]
                                   } else {
                                        obj[item].push(arrtList[idx].attributeValue)
                                   }
                              } else {
                                   if (!obj[item]) {
                                        obj[item] = ['--']
                                   } else {
                                        obj[item].push('--')
                                   }
                              }
                         })
                    })

                    //图片的行列转换
                    imgList.value = result.map(i => {
                         let image
                         if (targetIds.type == 'series') {
                              image = i.goods.mainImage
                         } else {
                              if (i.goods.mainImage) {
                                   image = i.goods.mainImage
                              } else {
                                   image = i.goodsImageUrl
                              }
                         }

                         return {
                              image,
                              name: targetIds.type == 'series' ? (i.goods.goodsName || '--') : (i.goods.goodsName),
                              goodsId: targetIds.type == 'series' ? i.seriesId : i.goodsId
                         }
                    })

                    //最后Object来转换成数组
                    contrastList.value = Object.keys(obj).map(item => {
                         return {
                              isSame: 0,
                              attributeName: item,
                              attributeValueList: obj[item]
                         }
                    })


                    if (contrastList.value > 0) {
                         for (let l in contrastList.value) {
                              let value = contrastList.value[l].attributeValueList
                              let valueSet = new Set(value)
                              if (valueSet.size == 1) {
                                   contrastList.value[l].isSame2 = true
                              } else {
                                   contrastList.value[l].isSame2 = false
                              }
                         }
                    }



                    if (targetIds.type == 'product') {
                         conGoodsInfo.value = result.map(i => {
                              let obj = {
                                   productPrice: i.goodsProperty.goodsPrice,
                              }

                              return obj
                         })
                    }
               }

               //处理对比产品之间的规格列表，将相同的规格名集合产品下的对应的规格值
               const handleForSpecContrast = (result) => {
                    rawContrastSpecs.value = result
                    let rowObj = Object.create(null)
                    let specsMap = result.map(i => {
                         return {
                              productId: i.productId,
                              specs: i.specs
                         }
                    })
                    specsMap.forEach((item, index) => {
                         item.specs.forEach((items, index1) => {
                              rowObj[items.specId] = rowObj[items.specId] || {}
                              rowObj[items.specId].specName = items.specName
                              rowObj[items.specId].specId = items.specId
                              rowObj[items.specId].specValueList = rowObj[items.specId].specValueList || []
                              let specWpro = items.specValueList.map(k => {
                                   return {
                                        productId: item.productId,
                                        ...k
                                   }
                              })
                              rowObj[items.specId].specValueList.push(specWpro)
                         })
                    })
                    contrastSpecs.value = Object.keys(rowObj).map(s => rowObj[s])
               }

               watch(() => route.query.ids, () => {
                    targetIds.idSet = route.query.ids
                    handleBoth()
               })

               const showDiff = (e, type) => {
                    showDiffForSpec(e, type)
                    showDiffForInfo(e, type)
               }


               const showDiffForInfo = (e, type) => {
                    ['productPrice', 'productState', 'deliverTime'].forEach((item, index) => {
                         let props = conGoodsInfo.value.map(i => i[item])
                         let valueSet = new Set(props)
                         if (valueSet.size == 1) {
                              if (type == 'same') {
                                   cGc.value[index] = e ? 'same' : ''
                              }

                         } else {
                              if (type == 'diff') {
                                   cGc.value[index] = e ? 'diff' : ''
                              }

                         }

                    })
               }


               const showDiffForSpec = (e, type) => {
                    if (type == 'same') {
                         for (let l in contrastList.value) {
                              let value = contrastList.value[l].attributeValueList
                              let valueSet = new Set(value)
                              if (valueSet.size == 1) {
                                   contrastList.value[l].isSame = e ? true : false
                              }
                         }
                    }

                    if (type == 'diff') {
                         for (let l in contrastList.value) {
                              let value = contrastList.value[l].attributeValueList
                              let valueSet = new Set(value)
                              if (valueSet.size > 1) {
                                   contrastList.value[l].isDiff = e ? true : false
                              }
                         }
                    }
               }




               //选中其他规格值的操作
               const selectSpecVal = (specValue, spec) => {
                    let { productId, specValueId } = specValue
                    let { specId } = spec
                    let target = rawContrastSpecs.value.find(i => i.productId == productId)
                    let { specs, specProductMap } = target

                    let choiceSpecIds = [];
                    //从选择该规格值的产品中找出已选择的其他规格值，重新组成新的规格值组合
                    specs.forEach((item) => {
                         if (item.specId != specId) {
                              item.specValueList.forEach((item1) => {
                                   if (item1.checkState == "1") {
                                        // checkState: 1-选中，2-可选，3-禁用
                                        choiceSpecIds.push(item1.specValueId);
                                   }
                              });
                         } else {
                              choiceSpecIds.push(specValueId);
                         }
                    });

                    //从接口中返回的该产品列表的specProductMap中找出规格值组合对应的ProductId
                    let matchProductId = specProductMap[choiceSpecIds.join(',')]

                    //在路由获取的targetIds.idSet中匹配替换掉选该产品的默认productId，或者上一个productId
                    let reg = new RegExp(productId, 'i')
                    let finalIds = targetIds.idSet.replace(reg, matchProductId)

                    console.log(specProductMap, 'matchProductId', choiceSpecIds.join(','))

                    //记住当前的滚条位置，防止push后滚动条跳动
                    scrollTop.value = document.body.scrollTop || document.documentElement.scrollTop


                    //进行跳转
                    router.push({
                         path: '/goods/Contrast',
                         query: {
                              ids: finalIds,
                              type: 'product'
                         }
                    })
               }

               onMounted(() => {
                    handleBoth()
               })

               return {
                    attrNameList,
                    contrastList,
                    targetIds,
                    conGoodsInfo,
                    showDiff,
                    imgList,
                    contrastSpecs,
                    selectSpecVal,
                    cGc
               }
          }
     }
</script>

<style lang="scss">
     .flex-col {
          display: flex;
          flex-direction: column;
     }

     .flex-row {
          display: flex;
          flex-direction: row;
     }

     .part_title {
          height: 40px;
          background-color: #f7f7f7;
          line-height: 40px;
          padding-left: 20px;
          font-size: 14px;
     }



     .contrast_main {
          background-color: #F4F4F4;

          .contrast_con {
               width: 1200px;
               margin: 0 auto;
               padding-top: 30px;
               padding-bottom: 50px;

               .title {
                    color: rgba(51, 51, 51, 1);
                    font-size: 22px;
                    font-family: SourceHanSansCN-Bold;
                    white-space: nowrap;
                    text-align: center;
                    align-self: center;
               }

               .bd4 {
                    margin-top: 30px;
               }

               .table {
                    margin-top: 20px;
                    background-color: #fff;

                    .top_title {
                         width: 100%;
                         height: 50px;
                         padding-left: 20px;
                         font-size: 16px;
                         font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                         font-weight: 500;
                         color: #333333;
                         background: #F9F9F9;
                         border-top: 1px solid #EEEEEE;
                         border-right: 1px solid #EEEEEE;
                         border-left: 1px solid #EEEEEE;
                         line-height: 50px;
                         font-weight: bold;
                    }


                    .section2 {
                         z-index: auto;
                         width: 1200px;

                         justify-content: space-between;
                    }

                    .main6 {

                         border-top: 1px solid rgba(238, 238, 238, 1);
                         border-right: 1px solid rgba(238, 238, 238, 1);

                         background-color: rgba(255, 255, 255, 1);
                         width: 194px;
                         justify-content: flex-end;

                         align-items: center;

                         &.isHighLight {
                              background: #F1F5FD;
                         }

                         &.hide {
                              height: 0;
                              overflow: hidden;
                              border-top: none;
                              opacity: 0;
                         }
                    }

                    .info9 {

                         width: 100%;
                         height: 168px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Medium;
                         white-space: nowrap;
                    }

                    .info91 {
                         width: 100%;
                         padding: 10px;
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Medium;
                         white-space: nowrap;
                         text-align: center;
                    }

                    .word16 {
                         border-top: 1px solid #eeeeee;
                         height: 45px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Medium;
                         width: 100%;
                         transition: all 0.2s;
                         text-align: center;
                         word-break: break-all;

                         &.isHighLight {
                              background: #F1F5FD;
                         }

                         &.hide {
                              height: 0;
                              overflow: hidden;
                              border-top: none;
                              opacity: 0;
                         }
                    }

                    .word161 {
                         padding: 15px 10px;
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Medium;
                         width: 100%;
                         transition: all 0.2s;
                         text-align: center;
                         word-break: break-all;
                    }

                    .word1612 {
                         padding: 15px 10px;
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Medium;
                         width: 100%;
                         transition: all 0.2s;

                         .word161_item {
                              display: inline-block;
                              padding: 0px 4px;
                              min-width: 107px;
                              height: 32px;
                              border: 1px solid #ABABAB;
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              line-height: 32px;
                              text-align: center;
                              color: #333333;
                              margin-bottom: 12px;
                              cursor: pointer;

                              &:last-child {
                                   margin-bottom: 0px;

                              }

                              &.checked {
                                   border: 1px solid #285FDE !important;
                                   color: #285FDE !important;
                              }
                         }
                    }


                    .main7 {
                         z-index: auto;
                         width: 1005px;


                    }

                    .mod3 {
                         z-index: auto;
                         width: 891px;
                         height: 140px;
                         margin-left: 55px;
                    }

                    .col_box {
                         flex: 1;
                         border-right: 1px solid #eeeeee;
                         border-top: 1px solid #eeeeee;
                         height: 100%;

                         .col_name {
                              font-size: 14px;
                              text-align: center;
                              margin: 5px 0;
                         }

                         &.isHighLight {
                              background: #F1F5FD;
                         }

                         &.hide {
                              height: 0;
                              overflow: hidden;
                              border-top: none;
                              opacity: 0;
                         }
                    }

                    .box2 {
                         border-top: 1px solid #eeeeee;
                         width: 100%;

                         height: 169px;
                         overflow: hidden;

                         img {
                              height: 154px;
                              max-width: 246px;
                              object-fit: contain;
                         }

                    }

                    .img3 {
                         z-index: 24;
                         width: 130px;
                         height: 118px;
                    }

               }
          }
     }
</style>