<template>
  <div class="store_swiper">
    <el-carousel v-if="imgList.data.length" arrow="never" indicator-position="none">
      <el-carousel-item v-for="item in imgList.data" :key="item">
        <div class="bg_img" :style="`background-image:url('${item}')`"></div>
      </el-carousel-item>
    </el-carousel>
    <template v-if="storeList.data.length > 0">
    <div class="store_main flex_row_center_center">
        <div class="store_list" v-if="storeList.data.length > 0">
            <template v-for="(item,index) in storeList.data" :key="item.storeId">
                <div
                    v-if="index < 6"
                    class="store_item"
                    :class="{active:activeIndex == index}"
                    @mouseenter="changeIndex(index)"
                    @click="storeInfo(item.storeId)"
                    :title="item.storeName">
                    <img class="logo" :src="item.storeUrl || item.storeLogo" />
                    <!-- <span class="name">{{item.storeName}}</span> -->
                    <span class="line"></span>
                </div>
            </template>
        </div>
        <div class="right_img flex_row_center_center">
            <img :src="storeList.data[activeIndex].mainProductImage" />
        </div>
    </div>
    </template>
  </div>
</template>
<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "StoreSwiper",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const bgHeight = ref(555);
    const imgList = reactive({
        data: []
    });
    const defaultImg = require('@/assets/store_swiper_product.png');
    const storeList = reactive({ data:[] });
    const activeIndex = ref(0);

    const storeImgs = ref([
        {name:'友晟',path:require('@/assets/store/store_1.png')},
        {name:'友益',path:require('@/assets/store/store_2.png')},
        {name:'晨晶电子',path:require('@/assets/store/store_3.png')},
        {name:'七星飞行',path:require('@/assets/store/store_4.png')},
        {name:'七星微电子',path:require('@/assets/store/store_5.png')},
        {name:'三水日明',path:require('@/assets/store/store_6.png')},
    ])

    const changeIndex =(index)=> {
        activeIndex.value = index;
    };

    const getStoreInfo =()=> { //获取背景图、店铺列表
        proxy
          .$get('v3/system/front/setting/getSettings', { names: 'supplier_list_background' })
          .then(res => {
            if (res.state == 200) {
              if(res.data[0]){
                imgList.data = [res.data[0]];
              }else{
                imgList.data = [require('@/assets/store_swiper.png')];
              }
            } else {
                imgList.data = [require('@/assets/store_swiper.png')];
            }
          })

        let param = {
            current: 1,
            pageSize: 6
        }
        proxy
          .$get('v3/seller/front/store/storeList', param)
          .then(res=>{
              if(res.state == 200){
                res.data.forEach(item => {
                    storeImgs.value.forEach(items=>{
                        if(item.storeName == items.name){
                            item.storeUrl = items.path
                        }
                    })
                });
                storeList.data = res.data;
              }
          })
    };

    const storeInfo =(storeId)=> { //店铺详情
        router.push({
            path: '/store/index',
            query: {
                vid: storeId
            }
        })
    };

    onMounted(() => {
        bgHeight.value = window.innerHeight - 199;
        window.addEventListener('resize',()=>{
            bgHeight.value = window.innerHeight - 199;
        })
        getStoreInfo();
    });


    return {
        imgList,
        storeList,
        getStoreInfo,
        storeInfo,
        bgHeight,
        activeIndex,
        changeIndex,
        defaultImg,
        storeImgs
    };
  },
};
</script>

<style lang="scss" >
.store_swiper {
    .el-carousel__container {
        min-height: 555px;
    }
}
</style>
<style lang="scss" scoped>
.store_swiper {
    position: relative;
    .bg_img {
        width: 100%;
        min-height: 555px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .store_main {
        width: 1210px;
        min-height: 550px;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -605px;
        .store_list {
            width: 424px;
            margin-right: 100px;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            .store_item {
                position: relative;
                width: 192px;
                height: 116px;
                margin: 22px 20px 22px 0;
                padding-left: 10px;
                padding-right: 10px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all .3s ease;
                .logo {
                    width: 170px;
                }
                .line {
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: block;
                    width: 40%;
                    height: 3px;
                    background: #FFFFFF;
                    transition: all .3s ease;
                }
                &.active,
                &:hover {
                    background: rgba(255,255,255,0.08);
                    .line {
                        opacity: 0;
                    }
                }
            }
        }
        .right_img {
            width: 450px;
            height: 480px;
            img {
                max-width: 450px;
                margin-top: 30px;
            }
        }
    }
}
</style>
