<!--个人中心左侧公共导航 @zjf-2020-12-26-->
<template>
  <div class="sld_member_left_nav">
    <template v-for="(item, index) in leftNavMenu" :key="index">
      <template v-if="!item.limit">
        <router-link :to="{ path: item.path }" class="menu">
          <i :class="{ iconfont: true, [item.icon]: true }"></i>
          {{ item.name }}
        </router-link>
        <template v-if="item.child.length">
          <template v-for="(item_child, index_child) in item.child" :key="index_child">
            <router-link v-if="!item_child.limit && testIfShow(item_child)"
              :to="{ path: item_child.path, query: item_child.query }"
              :class="{ submenu: true, cur_code_active: (curCode == item_child.code) || (curCode.indexOf(item_child.code) != -1) }">
              {{ item_child.name }}
            </router-link>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "MemberLeftNav",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const curCode = ref("");
    const roleType = ref(store.state.memberInfo.memberType); //	会员类型，1-普通会员；2-设计师；3-企业会员
    const isMaster = ref(store.state.memberInfo.enterpriseMaster); //	是否为企业主账号，1-是；0-否
    const memberInfo = reactive({ data: store.state.memberInfo });
    const purchaserCheck = ref(store.state.purchaserCheck)
    const supplierCheck = ref(store.state.supplierCheck)
    const leftNavMenu = ref([
      {
        code: "tarde",
        icon: "iconcaigouguanli",
        name: L["采购管理"],
        path: "/member/index",
        child: [
          {
            code: "memberOrderList",
            name: L["我的订单"],
            path: "/member/order/list"
          },
          {
            code: "MemberOrderEvaluation",
            name: '交易评价',
            path: "/member/order/evaluation"
          },
          {
            code: "memberSampleList",
            name: L["样品订单"],
            path: "/member/sample/list",
            limit: roleType.value == 2 ? false : true //企业用户展示
          },
          {
            code: "memberProjectList",
            name: L["我的项目"],
            path: "/member/project/list"
          },
          // {
          //   code: "memberDesignerApply",
          //   name: L["采购申请"],
          //   path: "/member/apply",
          //   limit: roleType.value == 2 ? false : true //设计师展示
          // },
          {
            code: "memberRegularList",
            name: L["常购清单"],
            path: "/member/regular/list"
          },
          {
            code: "matCode",
            name: '物料编码',
            path: "/member/matCode",
            limit: roleType.value == 3 ? false : true //企业用户展示
          },

          {
            code: "desAppList",
            name: "设计师申请单",
            path: "/member/desApp/list",
            limit: roleType.value == 3 ? false : true //企业用户展示
          },

          // {
          //   code: "myInquiry",
          //   name: "我的询价",
          //   path: "/member/inquiry/myInq",
          //   // limit: purchaserCheck.value.pass ? false : true //企业用户展示
          // },

          // {
          //   code: "myQuotation",
          //   name: "我的报价",
          //   path: "/member/inquiry/myQuo",
          //   limit: supplierCheck.value.pass ? false : true //企业用户展示
          // },

          // {
          //   code: "myContract",
          //   name: "合约订单",
          //   path: "/member/inquiry/myCon",
          //   limit: !supplierCheck.value.pass || !purchaserCheck.value.pass//企业用户展示
          // },
          {
            code: "memberBid",
            name: "我的招标",
            path: "/member/bid",
            // limit: roleType.value == 3 ? false : true //企业用户展示
          },
          {
            code: "bidInvite",
            name: "投标邀请",
            path: "/member/bidInvite",
            // limit: roleType.value == 3 ? false : true //企业用户展示
          },
          {
            code: "myPurchase",
            name: "我的询价",
            path: "/member/inquiry/purchase",
            // limit: roleType.value == 3 ? false : true //企业用户展示
          },
        ]
      },
      {
        code: "collect",
        icon: "iconcaiwu",
        name: L["财务"],
        path: "/member/index",
        child: [
          {
            code: "memberCreditIndex",
            name: L["信用账单"],
            path: "/member/credit/index",
            limit: (roleType.value == 1 || (roleType.value == 3 && isMaster.value == 1)) ? false : true //设计师、企业用户子账号不展示
          },
          {
            code: "FinacePermission",
            name: L["授信申请"],
            path: "/member/permission",
            limit: (roleType.value == 1 || (roleType.value == 3 && isMaster.value == 1)) ? false : true //设计师、企业用户子账号不展示
          },
          {
            code: "FinaceInvoice",
            name: L["发票"],
            path: "/member/invoice"
          },
          {
            code: "FinaceContract",
            name: L["合同"],
            path: "/member/contract/index"
          }
        ]
      },
      {
        limit: (roleType.value == 3 && isMaster.value == 1) ? false : true, //企业用户主账号展示
        code: 'buzMani',
        icon: 'iconrz3',
        name: '企业管理',
        path: "/member/index",
        child: [
          {
            code: 'subAccount',
            name: '子账号管理',
            path: '/member/subAccount'
          }
        ]
      },
      {
        code: "service",
        icon: "iconshouhoufuwu",
        name: L["售后服务"],
        path: "/member/index",
        child: [
          {
            code: "AfterSalesList",
            name: L["退货/退款"],
            path: "/member/order/aftersales"
          }
        ]
      },
      {
        limit: true, //模块不展示
        code: "collect",
        icon: "icongerenzhongxinguanzhu",
        name: L["关注中心"],
        path: "/member/index",
        child: [
          {
            code: "MemberCollect",
            name: L["我的收藏"],
            path: "/member/collect"
          },
          {
            code: "memberCollectStore",
            query: { type: "store" },
            name: L["供应商店铺"],
            path: "/member/collect"
          },
          {
            code: "MemberFootprint",
            name: L["我的足迹"],
            path: "/member/footprint"
          }
        ]
      },
      {
        limit: true, //模块不展示
        code: "service",
        icon: "icon-gerenzhongxinkehufuwu",
        name: L["客户服务"],
        path: "/member/index",
        child: [
          {
            code: "AfterSalesList",
            name: L["我的退款"],
            path: "/member/order/aftersales"
          },
          {
            code: "AfterSalesListReturn",
            query: { type: "return" },
            name: L["我的退货"],
            path: "/member/order/aftersales"
          }
        ]
      },
      {
        code: "member",
        icon: "iconshezhi",
        name: L["设置"],
        path: "/member/index",
        child: [
          {
            code: "MemberInfo",
            name: L["账户信息"],
            path: "/member/info"
          },
          {
            code: "MemberAccount",
            name: L["账户安全"],
            path: "/member/account"
          },
          {
            code: "MemberAuthen",
            name: L["企业认证"],
            path: "/member/authen",
            limit: roleType.value != 2 ? false : true //设计师不展示
          },
          {
            code: "memberAddressList",
            name: L["地址管理"],
            path: "/member/address/list"
          }
        ]
      },
      {
        limit: true, //模块不展示
        code: "member",
        icon: "icongerenzhongxinhuiyuanzhongxin",
        name: L["会员中心"],
        path: "/member/index",
        child: [
          {
            code: "MemberInfo",
            name: L["会员信息"],
            path: "/member/userinfo"
          },
          {
            code: "MemberAccount",
            name: L["账号安全"],
            path: "/member/account"
          },
          {
            code: "MemberPhoneMange",
            name: L["手机号管理"],
            path: "/member/phone"
          },
          {
            code: "memberEmailMange",
            name: L["邮箱管理"],
            path: "/member/email"
          },
          {
            code: "MemberLoginPassword",
            name: L["登录密码"],
            path: "/member/pwd/login"
          },
          {
            code: "MemberPayPassword",
            name: L["支付密码"],
            path: "/member/pwd/pay"
          },
          {
            code: "MemberResetPassword",
            name: L["重置密码"],
            path: "/member/pwd/reset"
          },
          {
            code: "memberAddressList",
            name: L["收货地址"],
            path: "/member/address/list"
          }
        ]
      },
      {
        code: "collect",
        icon: "iconguanzhu1",
        name: L["关注中心"],
        path: "/member/index",
        child: [
          {
            code: "MemberCollect",
            name: L["产品收藏"],
            path: "/member/collect"
          },
          // {
          //   code: "memberCollectStore",
          //   query: { type: "store" },
          //   name: L["供应商"],
          //   path: "/member/collect"
          // },
          {
            code: "memberArticleCollect",
            name: L["文章收藏"],
            path: "/member/article"
          },
          {
            code: "MemberFootprint",
            name: L["我的足迹"],
            path: "/member/footprint"
          }
        ]
      },
      {
        limit: true, //模块不展示
        code: "money",
        icon: "icongerenzhongxincaichanzhongxin",
        name: L["我的钱包"],
        path: "/member/index",
        child: [
          {
            code: "MemberBalance",
            name: L["我的余额"],
            path: "/member/balance"
          },
          {
            code: "MemberRecharge",
            name: L["账户充值"],
            path: "/member/recharge"
          },
          {
            code: "MemberCoupon",
            name: "我的优惠券",
            path: "/member/coupon"
          },
          {
            code: "MyPoint",
            name: "我的积分",
            path: "/member/myPoint"
          }
        ]
      },

      {
        code: "assets",
        // limit: true,
        icon: "icongerenzhongxincaichanzhongxin",
        name: "资产管理",
        path: "/member/index",
        child: [
          {
            code: "MemberCoupon",
            name: "我的优惠券",
            path: "/member/coupon"
          }
        ]
      },



    ]);

    const testIfShow = nav_info => {
      if (nav_info.code == "MemberResetPassword") {
        if (store.state.memberInfo.hasPayPassword == 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    const getMsgNum = () => {
      if (memberInfo.data.memberId) {
        proxy.$get('v3/msg/front/msg/msgListNum').then(res => {
          let msgListNumSum = res.data.map(i => i.msgNum).reduce((prev, total) => total + prev)
          if (memberInfo.data != msgListNumSum) {
            memberInfo.data.msgNum = msgListNumSum;
            store.commit('updateMemberInfo', memberInfo.data);
          }
        })
      }
    };

    watchEffect(() => {
      let tmpPath = router.currentRoute.value.fullPath;
      if (
        router.currentRoute.value.name != undefined &&
        router.currentRoute.value.name
      ) {
        curCode.value = router.currentRoute.value.name;
        if (tmpPath == "/member/collect?type=store") {
          curCode.value = "memberCollectStore"; //关注店铺
        } else if (
          tmpPath == "/member/order/aftersales?type=return" ||
          tmpPath.indexOf("/member/order/refund/detail?curTitleId=2") > -1
        ) {
          curCode.value = "AfterSalesListReturn"; //我的退货
        } else if (
          tmpPath == "/member/order/aftersales" ||
          tmpPath.indexOf("/member/order/refund/detail?curTitleId=1") > -1
        ) {
          curCode.value = "AfterSalesList"; //我的退款
        }
      }

      let breakFlag = false;
      let limitFlag = false;
      for (var i = 0; i < leftNavMenu.value.length; i++) {
        if (leftNavMenu.value[i].child.length) {
          for (var j = 0; j < leftNavMenu.value[i].child.length; j++) {
            if (leftNavMenu.value[i].child[j].code == router.currentRoute.value.name) {
              breakFlag = true;
              limitFlag = leftNavMenu.value[i].child[j].limit;
              break;
            }
          }
        }
        if (breakFlag) {
          break;
        }
      }

      // console.log('路由存在:'+breakFlag,'路由受限:'+limitFlag)
      let designerRole = ['memberDesignerInfo']; //设计师角色路由
      let companyRole = ['desAppDetail']; //企业角色路由
      if ((breakFlag && limitFlag) ||
        (roleType.value != 2 && designerRole.indexOf(router.currentRoute.value.name) != -1) ||
        (roleType.value != 3 && companyRole.indexOf(router.currentRoute.value.name) != -1)) {
        router.replace('/member/index')
      }



      purchaserCheck.value = store.state.purchaserCheck
      supplierCheck.value = store.state.supplierCheck

      getMsgNum();
    });

    return {
      leftNavMenu, curCode, testIfShow, roleType, isMaster, getMsgNum, memberInfo, purchaserCheck, supplierCheck
    };
  }
};
</script>

<style lang="scss" scoped>
.sld_member_left_nav {
  background-color: #fff;
  color: #666;
  font-size: 12px;
  border: 1px solid #ebebeb;
  border-top: 1px solid #fff;
  margin-top: -1px;
  padding-bottom: 100px;
  font-family: MicrosoftYaHei;
  width: 180px;
  float: left;
  margin-bottom: 20px;

  .menu {
    margin: 10px 0;
    padding-left: 15px;
    font-size: 15px;
    font-weight: 600;
    font-family: MicrosoftYaHei-Bold;
    display: block;
    color: #666;

    i {
      font-size: 16px;
      font-weight: 400;
      color: #888;
      margin-right: 8px;
    }
  }

  .submenu {
    line-height: 26px;
    padding-left: 53px;
    color: #333333;
    display: block;

    &:hover {
      color: $colorMain;
    }

    &.cur_code_active {
      font-weight: bold;
      color: $colorMain;
    }
  }
}
</style>