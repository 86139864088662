<template>
   <NavTopBar />
   <div class="search_header flex_row_start_center">
      <SldHomeTopSearch></SldHomeTopSearch>
   </div>
   <div class="nav_cat_header flex_row_center_center">
      <nav>
         <div id="nav" ref="nav">
            <li><a href="/" :class="{ chosenSel: false }">商城首页</a></li>
            <li><a href="/information?from=main" :class="{ chosenSel: route.query.from }">行业知识首页</a></li>
            <li v-for="(item, index) in cateList" :key="index">
               <a :href="`/information/list?categoryId=${item.categoryId}&g=1`" @click="navClick(item)"
                  :class="{ chosenSel: route.query.categoryId == item.categoryId }">{{ item.categoryName }}</a>
            </li>
         </div>
         <li><a v-if="showMore" @click="moreCateShow = !moreCateShow" style="cursor: pointer;">全部>></a></li>
      </nav>
      <div :class="{ more_cate: true, flex_row_start_start: true, show: moreCateShow }">
         <li v-for="(item, index) in cateList.slice(visibleIndex - 1)" :key="index">
            <a :href="`/information/list?categoryId=${item.categoryId}&g=1`" @click="navClick(item)"
               :class="{ chosenSel: route.query.categoryId == item.categoryId }">{{ item.categoryName }}</a>
         </li>
      </div>
   </div>
   <div class="bottom_line"></div>
   <router-view v-if="isRouterAlive"></router-view>
   <FooterService />
   <FooterLink />
</template>

<script>
   import SldHomeTopSearch from "../../components/SldHomeTopSearch";
   import { reactive, getCurrentInstance, ref, watchEffect, onMounted, nextTick } from "vue";
   import { useRouter, useRoute } from "vue-router";
   import { useStore } from "vuex";
   import { ElMessage } from "element-plus";
   import NavTopBar from "../../components/NavTopBar";
   import FooterService from "../../components/FooterService";
   import FooterLink from "../../components/FooterLink";
   export default {
      name: "informationHeader",
      components: {
         NavTopBar,
         FooterService,
         FooterLink,
         SldHomeTopSearch
      },
      setup() {
         const { proxy } = getCurrentInstance();
         const router = useRouter();
         const route = useRoute();
         const L = proxy.$getCurLanguage();
         const store = useStore();
         const logoUrl = "";
         const configInfo = ref(store.state.configInfo);
         const defaultImg = ref(
            'this.src="' + require("@/assets/common_top_logo.png") + '"'
         );
         const keyword = ref(route.query.keyword);
         const searchUrl = require("@/assets/header/magnify.png");
         const cartUrl = require("@/assets/header/cart.png");
         const picture = require("@/assets/picture.png");
         const goods_name = "";
         const searchBarFixed = ref(false);
         const SAList = ref([]);
         const SAShow = ref(false);
         const categories = ref([]);
         const categoriesVal = ref("");
         const isRouterAlive = true
         const cateList = ref([])
         const showMore = ref(false)
         const moreCateShow = ref(false)
         const visibleIndex = ref(0)
         onMounted(() => {
            getInitData();
            getCategories();
         });

         const getInitData = () => {
            let param = {
               categoryId: 0
            }
            proxy.$get("v3/cms/front/information/categoryList", param).then((res) => {
               if (res.state == 200) {
                  cateList.value = res.data.list
               }
            }).then(() => {
               nextTick(() => {
                  let navEl = document.getElementById('nav')
                  let width = getComputedStyle(navEl, null)
                  if (parseInt(width.width) >= 1077) {
                     showMore.value = true
                  }
               })

               setTimeout(() => {
                  let children = proxy.$refs.nav.children;
                  let width = 0
                  for (let i = 0; ; i++) {
                     width += (children[i].offsetWidth)
                     if (width <= 1077) {
                        // console.log(width, 'widhtr')
                        visibleIndex.value = i
                     } else {
                        break
                     }
                  }
                  // console.log(visibleIndex.value, 'visibleIndex.value')
               }, 50)
            });
         };

         const cart_num = "";
         watchEffect(() => {
            if (route.query.keyword) {
               keyword.value = route.query.keyword;
            } else {
               keyword.value = "";
            }
         });

         //搜索事件
         const search = () => {
            proxy.$refs.searchInput.style.color = "rgb(153,153,153)";
            // proxy.$refs.searchInput.style.background = 'transparent'

            if (keyword.value) {
               router.push({
                  path: `/goods/list`,
                  query: { keyword: keyword.value },
               });
            }
         };



         //滚动事件
         const handleScroll = () => {
            var height = 600;
            var scrollTop =
               window.pageYOffset ||
               document.documentElement.scrollTop ||
               document.body.scrollTop;
            if (scrollTop > height) {
               searchBarFixed.value = true;
            } else {
               searchBarFixed.value = false;
            }
         };
         window.addEventListener("scroll", handleScroll);

         // 获取分类
         const getCategories = () => {
            proxy
               .$get('v3/goods/front/goods/category/topCategory')
               .then(res => {
                  categories.val = res.data;
               })
         };

         return {
            L,
            logoUrl,
            searchUrl,
            cartUrl,
            picture,
            goods_name,
            cateList,
            searchBarFixed,
            keyword,
            configInfo,
            defaultImg,
            SAShow,
            categories,
            categoriesVal,
            getCategories,
            route,
            isRouterAlive,
            showMore,
            route,
            moreCateShow,
            visibleIndex
         };
      },
   };
</script>





<style lang="scss" scoped>
   .search_header {
      height: 99px;
      position: relative;
      z-index: 12;
      width: 1210px;
      margin: 7px auto 15px;
      padding-left: 0px;
      padding-right: 0px;

      .container_header {
         width: 100%;
         position: fixed;
         background-color: #fff;
         left: 0;
         right: 0;
         top: 0;
         z-index: 999;
         border-bottom: 2px solid $colorMain;

         .sld_seach_wrap,
         .sld_cart_wrap {
            margin: 5px;
            margin-right: 40px;
            position: relative;
         }

         .container_header_box {
            width: 1200px;
            margin: 0 auto;

            .fixed_sld_cart_wrap {
               margin-right: 170px;

               a {
                  width: 100%;
                  height: 100%;

                  img {
                     max-width: 100%;
                     max-height: 100%;
                  }
               }
            }
         }
      }

      .container_header {
         -webkit-animation: searchTop 0.5s ease-in-out;
         animation: searchTop 0.5s ease-in-out;
      }

      @-webkit-keyframes searchTop {
         0% {
            top: -50px;
         }

         to {
            top: 0;
         }
      }

      @keyframes searchTop {
         0% {
            top: -50px;
         }

         to {
            top: 0;
         }
      }

      .sld_home_top_search_left {
         position: relative;
         float: left;
         width: 254px;
         margin-top: 24px;

         .sld_logo_wrap {
            display: block;
            width: 160px;
            height: 60px;

            img {
               max-width: 100%;
               max-height: 100%;
            }
         }
      }

      .sld_seach_wrap {
         float: left;
         padding-top: 4px;
         margin-top: 10px;
         position: relative;

         .sld_search_wrap_top {
            display: flex;
            align-items: center;

            .sld_search_wrap_top_item {
               text-align: center;
               line-height: 22px;
               padding-left: 15px;
               padding-right: 15px;
               color: $colorF;
               cursor: pointer;
            }

            .sld_search_wrap_top_active {
               color: $colorMain;
               font-weight: 700;
            }
         }

         .sld_seach_box {
            width: 520px;
            z-index: 11;
            height: 38px;
            margin-bottom: 3px;
            border-right: none;

            &.sld_seach_box_fixed {
               display: flex;
               align-items: center;
               width: 577px;
               height: 37px;
            }

            .form {
               border: 2px solid $colorMain;
               background-color: $colorMain;
               overflow: hidden;

               .form_select {
                  width: 100px;
               }

               .text {
                  height: 36px;
                  background-color: #fff;
                  width: 425px;
                  -webkit-appearance: none;
                  -webkit-border-radius: 0;
                  height: 34px;
                  color: rgb(153, 153, 153);
                  padding: 5px 5px 5px 10px;
                  background-position: 0 -360px;
                  background-color: #fff;
                  background-repeat: repeat-x;
                  line-height: 20px;
                  font-family: arial, "\5b8b\4f53";
                  font-size: 12px;
                  outline: none;
                  border: none;
               }

               input {
                  margin: 0;
                  padding: 0;
                  height: 34px;
                  border: 0;
               }

               input::placeholder {
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: rgb(153, 153, 153);
               }

               ::-webkit-input-placeholder {
                  margin-left: 20px;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: rgb(153, 153, 153);
               }

               /* 使用webkit内核的浏览器 */
               :-moz-placeholder {
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: rgb(153, 153, 153);
               }

               /* Firefox版本19+ */
               :-ms-input-placeholder {
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: rgb(153, 153, 153);
               }

               /* IE浏览器 */

               .button {
                  width: 100px;
                  background: $colorMain;
                  font-size: 16px;
                  font-weight: 600;
                  color: #fff;
                  float: right;
                  cursor: pointer;
                  text-align: center;
               }
            }

            .hot_search_wrap {
               height: 30px;
               line-height: 30px;
               color: #999;
               overflow: hidden;
               white-space: nowrap;

               strong {
                  float: left;
                  font-weight: 400;
               }

               a {
                  color: #666;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: break-all;

                  &:link,
                  &:visited {
                     float: left;
                     margin-right: 10px;
                  }

                  &:hover {
                     color: $colorMain;
                  }
               }
            }
         }

         .search_association {
            background: #fff;
            position: absolute;
            top: 60px;
            overflow: hidden;
            position: absolute;
            left: 0;
            width: 580px;
            border: 1px solid #ccc;
            background: #fff;
            z-index: 99;

            .s_a_item {
               display: flex;
               justify-content: space-between;
               overflow: hidden;
               padding: 1px 5px;
               line-height: 24px;
               cursor: pointer;
               font-size: 12px;
               -webkit-font-smoothing: antialiased;
               color: #666;

               div:first-child {
                  width: 250px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
               }

               div:last-child {
                  overflow: hidden;
                  color: #aaa;
               }

               &:hover {
                  background-color: rgb(255, 233, 188);
               }
            }
         }
      }


   }

   .nav_cat_header {
      position: relative;

      nav {
         width: 1200px;
         margin: 0 auto;
         display: flex;
         align-items: center;
         line-height: 30px;
         overflow: hidden;
         flex-wrap: wrap;
         justify-content: space-between;

         #nav {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            max-width: 1077px;
            overflow: hidden;
            word-break: break-all;
            height: 45px;
            flex-wrap: wrap;

            li:first-child {
               a {
                  margin-left: 0;
                  padding-left: 0
               }
            }
         }

         a {
            height: 45px;
            display: inline-block;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #333333;
            font-size: 18px;
            margin: 0 0 0 20px;
            line-height: 45px;
            padding: 0 20px;
            width: max-content;
         }

         .chosenSel {
            color: $colorMain;
            // border-bottom: 3px solid $colorMain;
         }

         a:hover {
            color: $colorMain;

         }
      }

      .more_cate {
         position: absolute;
         top: 50px;
         width: 1150px;
         background-color: #fff;
         z-index: 100;
         flex-wrap: wrap;
         border-radius: 10px;
         box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
         max-height: 0;
         overflow: hidden;
         transition: all 0.3s;

         &:after {
            border-style: solid;
            border-color: transparent transparent #fff transparent;
            content: '';
            position: absolute;
            top: -26px;
            border-width: 15px;
            right: 20px;

         }

         &.show {
            max-height: 100px;
            transition: all 0.3s;
         }

         a:hover {
            color: $colorMain;

         }

         a {
            height: 45px;
            display: inline-block;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #333333;
            font-size: 15px;
            margin: 0 0 0 20px;
            line-height: 45px;
            padding: 0 20px;
            width: max-content;
         }
      }
   }
</style>
<style lang="scss">
   .search_header {
      .form {
         display: flex;
         align-items: center;
      }

      .sld_seach_box {
         .el-select {
            height: 34px;
            line-height: 34px;
         }

         .el-select .el-input {
            width: 100px;
            height: 34px;
         }
      }

      .el-input__suffix,
      .el-input__suffix-inner {
         display: flex;
         align-items: center;
         justify-content: center;
      }

      .el-input--suffix .el-input__inner {
         height: 34px;
         padding-right: 25px;
         border-radius: 0;
         padding-bottom: 1px;
         border: none;
         border-right: 1px solid $colorI;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
      }

      .el-input__suffix-inner {
         position: relative;
      }
   }
</style>