//购物车页面
<template>
   <div class="sld_cart_index">
      <div class="cart_content" v-if="
         cartData.data &&
         ((cartData.data.invalidList && cartData.data.invalidList.length > 0) ||
            (cartData.data.storeCartGroupList &&
      cartData.data.storeCartGroupList.length > 0))">
         <!-- 购物车列表顶部标题 start-->
         <div class="cart_title flex_row_start_center">
            <div class="cart_title_pre flex_row_start_center" v-if="cartData.data.storeCartGroupList.length > 0">
               <img src="@/assets/cart/sel.png" alt="" v-if="cartData.data.checkedAll"
                  @click="changeSelectState('all', cartData.data.checkedAll)" />
               <img src="@/assets/cart/no_sel.png" alt="" v-if="!cartData.data.checkedAll"
                  @click="changeSelectState('all', cartData.data.checkedAll)" />
               <span>{{ L["全选"] }}</span>
            </div>
            <div class="cart_title_pre" style="width:62px;" v-else></div>
            <div class="cart_title_pre">
               <span>{{ L["产品信息"] }}</span>
            </div>
            <div class="word_91">
               <span>规格</span>
            </div>
            <div class="word_92"><span>材质</span></div>
            <div class="word_93"><span>产地</span></div>
            <div class="word_94"><span>货物存在地</span></div>
            <div class="word_95"><span>单价（元/吨）</span></div>
            <!-- <div class="cart_title_pre">
            </div>
            <div class="cart_title_pre">
               <span>{{ L["数量"] }}</span>
            </div>
            <div class="cart_title_pre">
               <span>{{ L["单价"] }}</span>
            </div>
            <div class="cart_title_pre">
               <span>{{ L["小计（元）"] }}</span>
            </div> -->
            <div class="cart_title_pre word_96">
               <span>{{ L["操作"] }}</span>
            </div>
         </div>
         <!-- 购物车列表顶部标题 end-->
         <!-- 购物车列表 start -->
         <div class="cart_lists" v-show="cartData.data">
            <div class="cart_lists_pre" v-for="(cartGroupItem, cartGroupIndex) in
                  firstLoading ? skelelonData : cartData.data.storeCartGroupList" :key="cartGroupIndex"
               :class="{ skelelon_loading: firstLoading }">
               <!-- 店铺信息 start -->
               <div class="store_info flex_row_start_center">
                  <!-- 可选择 -->
                  <img src="@/assets/cart/sel.png" alt="" class="store_sel"
                     v-if="cartGroupItem.checkedAll && !cartGroupItem.lackAll"
                     @click="changeSelectState('store', cartGroupItem.checkedAll, cartGroupItem)" />
                  <img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
                     v-if="!cartGroupItem.checkedAll && !cartGroupItem.lackAll"
                     @click="changeSelectState('store', cartGroupItem.checkedAll, cartGroupItem)" />
                  <!-- 全部无货，不可选择 -->
                  <img src="@/assets/cart/no_sel.png" alt="" class="store_sel" v-if="cartGroupItem.lackAll" />
                  <router-link target="_blank" :to="`/store/index?vid=${cartGroupItem.storeId}`"
                     class="store_des flex_row_start_center cursor_pointer">
                     <span>{{ cartGroupItem.storeName }}</span>
                     <img src="@/assets/cart/right_down.png" alt="" />
                  </router-link>

                  <img class="store_chat_img" @click="service(cartGroupItem.storeId)" :src="storeImg" />


                  <!-- 优惠券start -->
                  <!-- cartGroupItem.hasCoupon -->
                  <div v-show="cartGroupItem.hasCoupon" class="store_coupons"
                     :class="{ active: cartGroupItem.showCoupon }">
                     <img class="store_coupons_bg" src="@/assets/cart_coupons.png"
                        @click="openCouponModel(cartGroupItem.storeId, cartGroupIndex, 'open')" />
                     <div v-if="cartGroupItem.showCoupon" class="store_coupons_list">
                        <div class="store_coupons_close flex_row_end_center">
                           <img src="@/assets/buy/close.png" @click="closeCouponModel(cartGroupIndex)" />
                        </div>
                        <div class="model_coupon_list">
                           <CouponItem v-for="(couponItem, couponIndex) in couponList.data.list" :key="couponIndex"
                              :class="sld_coupon_item" coupon_type="small" :coupon_item="couponItem"
                              @refreshCouponList="openCouponModel(null, null, 'get')">
                           </CouponItem>
                        </div>
                        <div class="flex_row_end_center sld_pagination sld_page_bottom"
                           v-if="couponList.data.pagination && couponList.data.pagination.total">
                           <el-pagination @current-change="handleCurrentChangeCoupon"
                              v-model:currentPage="curCouponPage" :page-size="couponPageSize"
                              layout="prev, pager, next, jumper" :total="couponList.data.pagination.total"
                              :hide-on-single-page="true">
                           </el-pagination>
                        </div>
                     </div>
                  </div>
                  <!-- 优惠券end -->


               </div>
               <!-- 店铺信息 end -->
               <!-- 商品信息 start -->
               <div class="goods_con">
                  <!-- 商品列表 start -->
                  <div class="goods_list">
                     <div class="goods_pre"
                        v-for="(cartItem, cartIndex) in cartGroupItem.storeGoodsList" :key="cartIndex">
                        <div class="goods_cont" :class="{ flex_row_start_start: 4 > 1, flex_row_start_center: 4 == 1 }">
                           <!-- v-for="(cartItem, cartIndex) in promotionCartGroupItem.cartList" :key="cartIndex"> -->
                           <!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
                           <div class="sku_list">
                              <template v-for="(item, index) in cartItem.goodsCartList"
                              :key="index">
                              <div class="sku_listss">
                                 <div class="flex_row_start_center">
                                    <div class="flex_row_start_center">
                                       <div class="goods_pre_sel">
                                          <img src="@/assets/cart/sel.png" alt="" class="store_sel"
                                             v-if="item.isChecked && item.productState != 3"
                                             @click="changeSelectState('goods', item.isChecked, item)" />
                                          <img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
                                             v-if="!item.isChecked && item.productState != 3"
                                             @click="changeSelectState('goods', item.isChecked, item)" />
                                          <!-- 不可选择 无货-->
                                          <img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
                                             v-if="item.productState == 3" @click="changeSelectStates"/>
                                       </div>
                                       <!-- 商品信息 -->
                                       <div :style="{ backgroundImage: 'url(' + item.goodsImage + ')' }" class="goods_img"
                                          @click="goGoodsDetail(cartItem.goodsCartList[0].productId)"
                                          :class="{ no_stock_img: cartItem.productState == 3 }"></div>
                                       <div class="goods_des">
                                          <div class="goods_name_box">
                                             <p class="goods_name" :class="{ no_stock_name: cartItem.productState == 3 }"
                                                @click="goGoodsDetail(cartItem.goodsCartList[0].productId)">
                                                {{ item.goodsName }}
                                             </p>
                                          </div>
                                          <div class="goods_des_bottom">
                                             <span>可供重量：{{item.goodsProperty.goodsStockWeight}}吨</span>
                                             <p style="margin-top: 3px;">可供数量：{{item.goodsProperty.goodsStockNum}}</p>
                                          </div>
                                          <p></p>
                                          <!-- <p></p> -->
                                       </div>
                                    </div>
                                       <div class="flex_row_start_center sku_item">
                                          <div class="bd_word_91 flex_row_center_center">
                                             {{ item.goodsProperty.specModel }}
                                          </div>
                                          <div class="bd_word_92 flex_row_center_center">
                                             {{ item.goodsProperty.material }}
                                          </div>
                                          <div class="bd_word_93 flex_row_center_center">
                                             {{ item.goodsProperty.originPlace }}
                                          </div>
                                          <div class="bd_word_95 flex_row_center_center">
                                             {{ item.goodsProperty.presencePlace }}
                                          </div>
                                          <!-- 单价 -->
                                          <div class="goods_price_prom">
                                             <div class="goods_price"
                                                :class="{ no_stock_price: (item.productState == 3 || item.productStock == 0) }">
                                                ￥{{ item.goodsProperty.goodsPrice ? item.goodsProperty.goodsPrice.toFixed(2) : 0 }}/吨
                                             </div>
            
                                          </div>
                                          <!-- 小计 -->
                                          <!-- <div class="subtotal">
                                             ￥{{ item.totalAmount }}
                                          </div> -->
                                          <!-- 操作 -->
                                          <div class="goods_btn flex_row_center_start">
                                             <span class="goods_collect" @click="moveCartGoods(item.cartId)" v-if="token">{{
                                                L["移入收藏夹"]
                                             }}</span>
                                             <el-popconfirm :title="L['确定删除选中产品？']" @confirm="delCartGoods('goods', item)"
                                                :confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
                                                <template #reference>
                                                   <span class="goods_del">{{ L["删除"] }}</span>
                                                </template>
                                             </el-popconfirm>
                                          </div>
                                       </div>
   
                                 </div>
                                    
                                 <div class="goods_cao">
                                    <div class="goods_cao_o flex_row_end_center">
                                       <div class="goods_company_box goods_box_com">
                                          <p>销售单位：</p>
                                          <div class="flex_row_start_center">
                                             <div class="goods_company_radio flex_row_start_center" v-if="item.goodsProperty.meterWeight" @click="goJiaType(cartGroupIndex,cartIndex,index,1)">
                                                <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==1">
                                                <img src="@/assets/radio_un.png" alt="" v-else>
                                                <span>米</span>
                                             </div>
                                             <div class="goods_company_radio flex_row_start_center"  v-if="item.goodsProperty.isSupportKg==1" @click="goJiaType(cartGroupIndex,cartIndex,index,4)">
                                                <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==4">
                                                <img src="@/assets/radio_un.png" alt="" v-else>
                                                <span>吨</span>
                                             </div>
                                             <div class="goods_company_radio flex_row_start_center"  v-if="item.goodsProperty.roll&&item.goodsProperty.roll.length>0" @click="goJiaType(cartGroupIndex,cartIndex,index,5)">
                                                <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==5">
                                                <img src="@/assets/radio_un.png" alt="" v-else>
                                                <span>卷</span>
                                             </div>
                                             <div class="goods_company_radio flex_row_start_center"  v-if="item.goodsProperty.sliceWeight" @click="goJiaType(cartGroupIndex,cartIndex,index,2)">
                                                <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==2">
                                                <img src="@/assets/radio_un.png" alt="" v-else>
                                                <span>片</span>
                                             </div>
                                             <div class="goods_company_radio flex_row_start_center"  v-if="item.goodsProperty.rootWeight" @click="goJiaType(cartGroupIndex,cartIndex,index,3)">
                                                <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==3"> 
                                                <img src="@/assets/radio_un.png" alt="" v-else>
                                                <span>根</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="goods_longitude_box goods_box_com" v-if="item.types==1">
                                          <p>所需长度：</p>
                                          <div class="longitude_inp flex_row_start_center">
                                             <input type="number" placeholder="请输入长度" v-model="item.chang" @input="numFour(cartGroupIndex,cartIndex,index,'chang')">
                                             <span>米 = {{item.weightSum?item.weightSum.toFixed(3):0}}吨</span>
                                          </div>
                                       </div>
                                       <div class="goods_longitude_box goods_box_com" v-if="item.types==4">
                                          <p>所需吨数：</p>
                                          <div class="longitude_inp flex_row_start_center">
                                             <input type="number" placeholder="请输入吨数" v-model="item.dun" @input="numFour(cartGroupIndex,cartIndex,index,'dun')">
                                             <span>吨</span>
                                          </div>
                                       </div>
                                       <div class="goods_longitude_box goods_box_com" v-if="item.types==5">
                                          <p>卷重：</p>
                                          <div class="longitude_xuan flex_row_start_start">
                                             <template v-for="(it,ind) in item.goodsProperty.roll"  :key="ind">
                                                 <div class="longitude_xuan_o flex_row_center_center" :class="{'longitude_xuan_check':it.rollWight==item.rollWight}" v-if="it.rollNum" @click="goJiaTypejuan(cartGroupIndex,cartIndex,index,5,ind)">
                                             {{it.rollWight}}吨
                                             </div>
                                             </template>
                                          </div>
                                       </div>
                                       <div class="goods_longitude_box goods_box_com" v-if="item.types==2">
                                          <p>所需片数：</p>
                                          <div class="longitude_inp flex_row_start_center">
                                             <input type="number" placeholder="请输入片数" v-model="item.pian" @input="numFour(cartGroupIndex,cartIndex,index,'pian')">
                                             <span>片 =  {{item.weightSum?item.weightSum.toFixed(3):0}}吨</span>
                                          </div>
                                       </div>
                                       <div class="goods_longitude_box goods_box_com" v-if="item.types==3">
                                          <p>所需根数：</p>
                                          <div class="longitude_inp flex_row_start_center">
                                             <input type="number" placeholder="请输入根数" v-model="item.gen" @input="numFour(cartGroupIndex,cartIndex,index,'gen')">
                                             <span>根 =  {{item.weightSum?item.weightSum.toFixed(3):0}}吨</span>
                                          </div>
                                       </div>
                                       <div class="goods_mode_box goods_box_com" >
                                          <p>交货方式：</p>
                                          <div class="mode_type flex_row_start_center">
                                             <div class="mode_type_box flex_row_start_center" v-if="item.goodsProperty.deliverType==1">
                                                <img src="@/assets/cart/sel.png" alt="" v-if="item.deliverType==1" @click="editNum(cartGroupIndex,cartIndex,index,'',item.types,0,-2)"/>
                                                <img src="@/assets/cart/no_sel.png" alt="" v-else @click="editNum(cartGroupIndex,cartIndex,index,'',item.types,0,-2)"/>
                                                <span>净板交货 {{ item.goodsProperty.deliverTypePrice }}元/吨</span>
                                             </div>
                                             <div class="mode_type_box flex_row_start_center" style="cursor: auto;" v-else>
                                                <span>带头尾交货</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="goods_cao_t flex_row_start_center">
                                       <div class="goods_cao_t_box">
                                          <div class="goods_cao_t_1 flex_row_start_center">
                                             <p>合计重量：</p>
                                             <span>{{item.weightSum?item.weightSum.toFixed(3):0}}吨</span>
                                          </div>
                                       </div>
                                       <div class="goods_cao_t_box flex_column_start_center">
                                          <div class="goods_cao_t_2">合计单价：<span>￥{{item.singlePrice?item.singlePrice.toFixed(2):0}}/吨</span></div>
                                          <div class="goods_cao_t_3">合计单价=产品单价+增值服务单价</div>
                                       </div>
                                       <div class="goods_cao_t_box">
                                          <div class="goods_cao_t_box_o flex_column_start_end">
                                             <div class="goods_cao_t_2">合计金额：<span>￥{{item.sumPrice?item.sumPrice.toFixed(2):0}}</span></div>
                                             <div class="goods_cao_t_3">不含物流运费</div>      
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                              </template>
                           </div>

                        </div>
                        <div class="goods_fen" v-if="cartGroupItem.storeGoodsList.length>cartIndex+1"></div>
                     </div>
                  </div>
                  <!-- 商品列表 end -->
               </div>
               <!-- 商品信息 end -->
            </div>
            <!-- 失效商品 start -->
            <div class="invalid_goods" v-if="cartData.data.invalidList && cartData.data.invalidList.length > 0">
               <div class="invalid_goods_title">{{ L["失效产品"] }}</div>
               <div class="invalid_goods_list">
                  <div class="invalid_goods_pre flex_row_start_center"
                     v-for="(invalidItem, invalidIndex) in cartData.data.invalidList" :key="invalidIndex">
                     <span class="invalid_tips">{{ L["失效"] }}</span>
                     <img src="@/assets/cart/no_sel.png" alt="" class="store_sel" />
                     <div :style="{ backgroundImage: 'url(' + invalidItem.goodsImage + ')' }" class="invalid_img"></div>
                     <div class="invalid_des">
                        <p class="invalid_name">{{ invalidItem.goodsName }}</p>
                        <p class="invalid_spec">{{ invalidItem.specValues }}</p>
                        <p class="invalid_des_bottom">
                           {{
                              invalidItem.produceState == 1 ? '现货' : invalidItem.produceState == 2 ?
                                 '半成品' : '待产'
                           }}
                           <span>发货日: {{ invalidItem.deliverTime }}天</span>
                        </p>
                     </div>
                     <div class="invalid_message">
                        <el-scrollbar>
                           <div v-for="arrItem in invalidItem.attributeList" :key="arrItem">
                              {{ arrItem.attributeName }}：{{ arrItem.attributeValue }}
                           </div>
                           <div class="invalid_message_none" v-show="invalidItem.attributeList.length == 0">--
                           </div>
                        </el-scrollbar>
                     </div>
                     <div class="invalid_price">￥{{
                        invalidItem.goodsPrice ?
                           invalidItem.goodsPrice.toFixed(2) : 0
                     }}
                     </div>
                     <div class="invalid_num flex_column_center_center">
                        <div class="invalid_edit_num flex_row_center_center">
                           <span>-</span>
                           <input type="number" disabled v-model="invalidItem.buyNum" />
                           <span>+</span>
                           <span class="invalid_edit_num_after">{{ invalidItem.saleUnit }}</span>
                        </div>
                     </div>
                     <div class="invalid_subtotal">￥{{ invalidItem.subtotal }}</div>
                     <div class="invalid_btn flex_column_center_start">
                        <span class="invalid_collect" v-if="token">{{ L["移入收藏夹"] }}</span>
                        <span class="invalid_del" @click="delCartGoods('goods', invalidItem)">{{
                           L["删除"]
                        }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <!-- 失效商品 -->
         </div>
         <!-- 购物车列表 end -->
         <!-- 操作按钮 start -->
         <div class="options_btn flex_row_between_center" ref="optionsBtn">
            <div class="cart_bottom_btn flex_row_start_center">
               <div class="options_sel" v-if="cartData.data.storeCartGroupList.length > 0">
                  <img src="@/assets/cart/sel.png" alt="" class="store_sel" v-if="cartData.data.checkedAll"
                     @click="changeSelectState('all', cartData.data.checkedAll)" />
                  <img src="@/assets/cart/no_sel.png" alt="" class="store_sel" v-if="!cartData.data.checkedAll"
                     @click="changeSelectState('all', cartData.data.checkedAll)" />
                  <span>{{ L["全选"] }}</span>
               </div>
               <div class="flex_col_start_center">
                  <div class="del_all cursor_pointer" @click="delBatchGoods"
                     v-if="cartData.data.storeCartGroupList && cartData.data.storeCartGroupList.length > 0">
                     {{ L["删除选中的产品"] }}
                  </div>
                  <div class="del_all_invalid cursor_pointer" @click="clearInvalidGoods">
                     {{ L["清空失效的产品"] }}
                  </div>
                  <div class="del_all_invalid cursor_pointer" @click="exportCarts">
                     导出已选商品快速下单清单
                  </div>
               </div>
               <!-- <div>
						<div class="del_all_invalid cursor_pointer" @click="exportAsQuick">
							导出已选产品快速下单清单
						</div>
					</div> -->
               <div class="options_btn_left flex_row_start_center">
                  <div class="options_btn_left_check">已选<span>{{ cartData.data.totalCheck }}</span>件产品</div>
                  <div class="options_btn_left_tip flex_row_start_center" v-if="!cartData.data.hasInquire">
                     <span class="options_btn_left_tit">，小计：</span>
                     <span class="options_btn_left_red">￥{{
                        cartData.data.totalAmount == 0 ? 0 : (buySets *
                           cartData.data.totalAmount).toFixed(2)
                     }}</span>
                  </div>
                  <!-- <div class="options_sel_num">
                     <span class="options_sel_span">产品已选购</span>
                     <div class="options_edit_nem flex_row_center_center">
                        <span @click="editBuySets('minus')">-</span>
                        <input type="number" v-model="buySets" @input="editBuySets('edit')" />
                        <span @click="editBuySets('plus')">+</span>
                        <span class="options_edit_nem_after">套</span>
                     </div>
                  </div> -->
               </div>
            </div>
            <div class="options_right flex_row_start_center">
               <div class="options_btn_left_sum flex_column_start_start" v-if="!cartData.data.hasInquire">
                  <div>
                     <span class="options_btn_left_tit">合计吨数：</span>
                     <span class="options_btn_left_red">{{ cartData.data.sumKg?cartData.data.sumKg.toFixed(3):0 }}吨</span>
                  </div>
                  <div style="margin-top: 10px;">
                     <span class="options_btn_left_tit">合计金额：</span>
                     <span class="options_btn_left_red">￥{{
                        cartData.data.totalAmount == 0 ? 0 : (buySets * cartData.data.totalAmount).toFixed(2)
                     }}</span>
                     <span class="options_btn_left_red" v-if="cartData.data.totalDiscount">￥{{
                        cartData.data.totalDiscount
                     }}</span>
                  </div>
               </div>
               <!-- <div class="go_submit cursor_pointer"
                  :class="{ active: cartData.data.totalAmount > 0 || cartData.data.hasInquire }" @click="goSubmit"
                  v-if="memberInfo && memberInfo.memberType == 2">
                  {{ L["提交需求"] }}
               </div> -->
               <div class="go_buy cursor_pointer" @click="goBuy"
                  :class="{ active: cartData.data.totalAmount > 0 || cartData.data.hasInquire, go_submit: !(memberInfo && memberInfo.memberType == 2) }">
                  {{ L["去结算"] }}
               </div>
            </div>
         </div>
         <!-- 操作按钮 end -->
      </div>
      <div class="cart_content_no flex_column_center_center"
         v-show="!cartData.data || JSON.stringify(cartData.data) == '{}' || (cartData.data && cartData.data.storeCartGroupList && cartData.data.storeCartGroupList.length == 0)">
         <img src="@/assets/new_empty.png" alt="" />
         <p>{{ L["这里空空如也，快去首页逛逛吧～"] }}</p>
         <span @click="goIndex">{{ L["去首页"] }}</span>
      </div>
      <!-- 清空失效商品 弹框 start -->
      <el-dialog title="提示" v-model="clearInvalidGoodsModel" width="20%" center top="30vh">
         <div style="margin:0 auto; text-align: center">{{ L["确定清空失效产品？"] }}</div>
         <template #footer>
            <span class="dialog-footer">
               <el-button @click="cancelClearInvalidModel">{{ L["取 消"] }}</el-button>
               <el-button type="primary" @click="confirmClearInvalidModel">{{ L["确 定"] }}</el-button>
            </span>
         </template>
      </el-dialog>
      <!-- 清空失效商品 弹框 end -->

      <!-- 删除所选商品 弹框 start -->
      <el-dialog title="提示" v-model="chosenGoodsModel" width="20%" center top="30vh">
         <div style="margin:0 auto; text-align: center">{{ L['确定删除选中产品？'] }}</div>
         <template #footer>
            <span class="dialog-footer">
               <el-button @click="chosenGoodsModel = false">{{ L["取 消"] }}</el-button>
               <el-button type="primary" @click="delCartGoods('batch')">{{ L["确 定"] }}</el-button>
            </span>
         </template>
      </el-dialog>
      <!-- 删除所选商品 弹框 end -->


      <!-- 推荐商品 start -->
      <recommend-list :firstLoading="firstLoading"></recommend-list>
      <!-- 推荐商品 end -->

      <!-- 登录弹框 start -->
      <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />
      <!-- 登录弹框 end -->

      <!-- 底部操作悬浮框 start -->
      <div class="bottom_options" v-if="optionsBtnShow && cartData.data.storeCartGroupList.length > 0">
         <div class="bottom_options_con flex_row_between_center">
            <div class="cart_bottom_btn flex_row_start_center">
               <div class="options_sel" v-if="cartData.data.storeCartGroupList.length > 0">
                  <img src="@/assets/cart/sel.png" alt="" class="store_sel" v-if="cartData.data.checkedAll"
                     @click="changeSelectState('all', cartData.data.checkedAll)" />
                  <img src="@/assets/cart/no_sel.png" alt="" class="store_sel" v-if="!cartData.data.checkedAll"
                     @click="changeSelectState('all', cartData.data.checkedAll)" />
                  <span>{{ L["全选"] }}</span>
               </div>
               <div class="flex_col_start_center">
                  <div class="del_all cursor_pointer" @click="delBatchGoods"
                     v-if="cartData.data.storeCartGroupList.length > 0">
                     {{ L["删除选中的产品"] }}
                  </div>
                  <div class="del_all_invalid cursor_pointer" @click="clearInvalidGoods">
                     {{ L["清空失效的产品"] }}
                  </div>
                  <div class="del_all_invalid cursor_pointer" @click="exportCarts">
                     导出已选商品快速下单清单
                  </div>
               </div>

               <div class="options_btn_left flex_row_start_center">
                  <div class="options_btn_left_check">已选<span>{{ cartData.data.totalCheck }}</span>件产品</div>
                  <div class="options_btn_left_tip flex_row_start_center" v-if="!cartData.data.hasInquire">
                     <span class="options_btn_left_tit">，小计：</span>
                     <span class="options_btn_left_red">￥{{
                        cartData.data.totalAmount == 0 ? 0 : (buySets *
                           cartData.data.totalAmount).toFixed(2)
                     }}</span>
                  </div>
                  <!-- <div class="options_sel_num">
                     <span class="options_sel_span">产品已选购</span>
                     <div class="options_edit_nem flex_row_center_center">
                        <span @click="editBuySets('minus')">-</span>
                        <input type="number" v-model="buySets" @input="editBuySets('edit')" />
                        <span @click="editBuySets('plus')">+</span>
                        <span class="options_edit_nem_after">套</span>
                     </div>
                  </div> -->
               </div>
            </div>
            <div class="options_right flex_row_start_center">
               <div class="options_btn_left_sum flex_column_start_start" v-if="!cartData.data.hasInquire">
                  <div>
                     <span class="options_btn_left_tit">合计吨数：</span>
                     <span class="options_btn_left_red">{{ cartData.data.sumKg?cartData.data.sumKg.toFixed(3):0 }}吨</span>
                  </div>
                  <div style="margin-top: 10px;">
                     <span class="options_btn_left_tit">合计金额：</span>
                     <span class="options_btn_left_red">￥{{
                        cartData.data.totalAmount == 0 ? 0 : (buySets * cartData.data.totalAmount).toFixed(2)
                     }}</span>
                     <span class="options_btn_left_red" v-if="cartData.data.totalDiscount">￥{{
                        cartData.data.totalDiscount
                     }}</span>
                  </div>
               </div>
               <!-- <div class="go_submit cursor_pointer"
                  :class="{ active: cartData.data.totalAmount > 0 || cartData.data.hasInquire }" @click="goSubmit"
                  v-if="memberInfo && memberInfo.memberType == 2">
                  提交清单
               </div> -->
               <div class="go_buy cursor_pointer" @click="goBuy"
                  :class="{ active: cartData.data.totalAmount > 0 || cartData.data.hasInquire, go_submit: !(memberInfo && memberInfo.memberType == 2) }">
                  {{ L["去结算"] }}
               </div>
            </div>
         </div>
      </div>
      <!-- 底部操作悬浮框 end -->
   </div>
   <LogisticsFreight></LogisticsFreight>
</template>

<script>
import { reactive, getCurrentInstance, ref, watchEffect, onMounted } from "vue";
import {
   ElMessage,
   ElDialog,
   ElButton,
   ElPopover,
   ElPopconfirm,
} from "element-plus";
import { useRouter } from "vue-router";
import RecommendList from "../../components/RecommendList.vue";
import CouponItem from "@/components/CouponItem";
import { useStore } from "vuex";
import SldLoginModal from "../../components/loginModal";
import LogisticsFreight from "@/components/LogisticsFreight";
export default {
   name: "CartIndex",
   components: {
      ElDialog,
      ElButton,
      ElPopover,
      ElPopconfirm,
      RecommendList,
      CouponItem,
      SldLoginModal,
      LogisticsFreight
   },
   setup() {
      const router = useRouter();
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const store = useStore();
      const token = ref(store.state.loginFlag); //登录缓存是否登录
      const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
      const cartData = reactive({ data: store.state.cartListData }); //购物车数据信息
      const clearInvalidGoodsModel = ref(false); //清空失效商品对话框是否显示
      const delGoodsCartIds = ref(""); //要删除的商品的购物车的id集
      const delType = ref("goods"); //要删除的商品类型：goods：单个商品删除 batch:批量删除
      const curPromotion = ref(-1); //当前选择的商品的活动的促销的id
      const curPromotionCartItem = reactive({ data: {} }); //当前选择的要换促销的商品
      const couponList = reactive({ data: {} }); //当前店铺优惠券列表数据
      const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
      const optionsBtnTop = ref(0); //底部操作按钮元素的top值
      const windowHeight = ref(0); //屏幕元素的高度
      const optionsBtnShow = ref(false); //底部操作按钮悬浮框是否显示
      const optionsBtn = ref(null); //获取底部操作按钮元素
      const curPromotionGoodsProductId = ref(''); //当前点击的促销活动的商品
      const curCouponPage = ref(1); //当前为第一页优惠券
      const couponPageSize = ref(6); //优惠券默认一页显示6条数据
      const curStoreId = ref(-1); //当前打开的优惠券弹框的店铺id
      const elementScrollTop = ref(0); //页面滚动高度
      const chosenGoodsModel = ref(false)
      const buySets = ref(1)
      const storeImg = require('../../assets/cart/storeChat.png');
      const firstLoading = ref(true);
      const skelelonData = ref([]);
      const memberInfo = ref(store.state.memberInfo); //获取vux的store中的购物车数据
      const isSubmit = ref(false); //是否在提交清单中
      const inpshu = reactive({
         chang:'',
         dun:'',
         juan:'',
         pian:'',
         gen:'',
      })
      onMounted(() => {
         window.addEventListener('scroll', scrollHandle); //绑定页面滚动事件
         for (let i = 0; i <= 2; i++) {
            skelelonData.value.push({
               storeGoodsList: [{}]
            })
         }
      });
      //页面监听token是否更新
      watchEffect(() => {
         token.value = store.state.loginFlag;
         // cartData.data = store.state.cartListData;
         //实时获取屏幕的高度
         windowHeight.value = document.documentElement.clientHeight;

         if (optionsBtn.value != null && optionsBtn.value != undefined) {
            optionsBtnTop.value = optionsBtn.value.offsetTop;
            if (optionsBtnTop.value > windowHeight.value) {
               optionsBtnShow.value = true;
            } else {
               optionsBtnShow.value = false;
            }
            if (elementScrollTop.value + windowHeight.value > optionsBtnTop.value) {
               optionsBtnShow.value = false;
            } else {
               optionsBtnShow.value = true;
            }
         }
      });
      const scrollHandle = (e) => {
         elementScrollTop.value = e.srcElement.scrollingElement.scrollTop;
         // elementScrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      };

      //已选择商品的件数,计算合计(未登录使用)
      const colculationOperation = () => {
         if (JSON.stringify(cartData.data) != "{}" && cartData.data != undefined) {
            cartData.data.totalCheck = 0;
            cartData.data.totalAmount = 0;
            cartData.data &&
               cartData.data.storeCartGroupList &&
               cartData.data.storeCartGroupList.map((storeItem) => {
                  storeItem.storeGoodsList.map((cartItem) => {
                     cartItem.subtotal = (Number(cartItem.buyNum) * cartItem.totalAmount).toFixed(2);
                     if (cartItem.isChecked == 1) {
                        cartData.data.totalCheck++;
                        cartData.data.totalAmount += Number(cartItem.subtotal);
                     }
                  });
               });
         }
      };

      //更新本地缓存购物车数据
      const updateLocCartData = (cartData) => {
         //将购物车数据更新到vuex中的store中
         store.commit("updateCartListData", cartData);
      };

      //获取购物车数据
      const getCartData = () => {
         if (store.state.loginFlag) {
            //已登录
            let params = {};
            proxy
               .$get("v3/business/front/cart/cartList", params)
               .then((res) => {
                  if (firstLoading.value) {
                     firstLoading.value = false;
                  }
                  if (res.state == 200) {
                     if (res.data.storeCartGroupList.length > 0) {
                        res.data.storeCartGroupList.forEach(item => {
                           item.storeGoodsList.forEach(item2 => {
                              item2.goodsCartList.forEach(item3=>{
                                 item3.types = item3.type
                                 if(item3.type==1){
                                    item3.chang = item3.buyNumType
                                    item3.dun = ''
                                    item3.pian = ''
                                    item3.gen = ''
                                 }else if(item3.type==2){
                                    item3.chang = ''
                                    item3.dun = ''
                                    item3.pian = item3.buyNumType
                                    item3.gen = ''
                                 }else if(item3.type==3){
                                    item3.chang = ''
                                    item3.dun = ''
                                    item3.pian = ''
                                    item3.gen = item3.buyNumType
                                 }else if(item3.type==4){
                                    item3.chang = ''
                                    item3.dun = item3.buyNumType
                                    item3.pian = ''
                                    item3.gen = ''
                                 }else if(item3.type==5){
                                    item3.chang = ''
                                    item3.dun = ''
                                    item3.pian = ''
                                    item3.gen = ''
                                 }
                              })
                              item2.editState = false;
                              item2.productCode = item2.productCode;
                              item2.addCode = (item2.productCode && item2.productCode.length) ? false : true;
                           })
                        });
                     }
                     cartData.data = proxy.$calculateSubtotal(res.data);
                  } else {
                     ElMessage.error(res.msg);
                  }
               }).then(() => {
                  store.commit("updateCartListData", cartData.data); //将购物车数据存储到vuex的store中
               });
         }
         updateLocCartData(cartData.data);
      };
      getCartData();

      const changeEdit = (item, item2) => {
         if (item.editState) {
            let param = {
               goodsSpec: item.goodsSpec,
               isEnable: 1,
               productCode: item.productCode,
            }
            let url = ''
            item.productCode = item.productCode ? item.productCode.trim() : '';
            if (item.addCode && item.productCode) {
               param.storeId = item2.storeId;
               url = 'v3/goods/front/goods/enterpriseCode/add';
            } else if (item.addCode && !item.productCode) {
               item.editState = false;
               return;
            } else {
               param.id = item.codeId;
               url = 'v3/goods/front/goods/enterpriseCode/update';
            }
            proxy.$post(url, param).then(res => {
               if (res.state == 200) {
                  ElMessage.success(res.msg);
                  item.editState = false;
                  if (item.addCode) {
                     item.codeId = res.data;
                     item.addCode = false;
                  }
               } else {
                  ElMessage(res.msg)
               }
            })
         } else {
            item.editState = true;
         }
      };

      /**编辑购物车商品数量
       * type:编辑类型 reduce：减  edit：编辑 add：加
       * curCartItem：编辑的当前项商品
       * e:事件对象
       */
      const editNums = (type, curCartItem, e) => {
         let curNumber = curCartItem.buyNum;
         let minBuyNum = curCartItem.minBuyNum ? curCartItem.minBuyNum : 1;

         let maxMin = Math.min(curCartItem.produceState != 1 ? 99999 : curCartItem.productStock, 99999)


         switch (type) {
            case 'reduce': {
               if (curCartItem.buyNum > minBuyNum) {
                  curCartItem.buyNum--;
               } else {
                  curCartItem.buyNum = minBuyNum;
                  ElMessage("最低限购" + minBuyNum + "件!");
               }
               break
            }

            case 'add': {
               curCartItem.buyNum++
               if (curCartItem.buyNum > maxMin) {
                  ElMessage(L["数量超出购买范围！"]);
                  curCartItem.buyNum = maxMin
                  return
               }
               break;
            }


            case 'edit': {
               if (e.data == '.') {
                  let num = curCartItem.buyNum.split('.')[0] ?
                     curCartItem.buyNum.split('.')[0] : minBuyNum
                  curCartItem.buyNum = '';
                  setTimeout(() => {
                     curCartItem.buyNum = num;
                  })
                  return;
               }
               curNumber = e.currentTarget.value;
               if (curNumber == 0 && curNumber != '') {
                  curCartItem.buyNum = minBuyNum;
                  curNumber = minBuyNum
                  return
               } else if (curNumber == '') {
                  return
               } else {
                  curNumber = curNumber.replace(/\D/g, "");
                  if (curCartItem.buyNum > maxMin) {
                     ElMessage(L["数量超出购买范围！"]);
                     curCartItem.buyNum = curCartItem.productStock
                     return
                  }
               }
               break
            }
            case 'blur': {
               if (curNumber == '') {
                  curCartItem.buyNum = minBuyNum
               } else if (curNumber < minBuyNum) {
                  curCartItem.buyNum = minBuyNum;
                  ElMessage("最低限购" + minBuyNum + "件!");
               } else if (curNumber > maxMin) {
                  ElMessage(L["超过购买限制!"]);
                  curCartItem.buyNum = maxMin;
               }
            }
         }
         curNumber = curCartItem.buyNum
         if (store.state.loginFlag) {
            //已登录
            let params = {
               cartId: curCartItem.cartId, //商品购物车id
               number: curNumber, //修改后商品数量
            };

            proxy.$post("v3/business/front/cart/changeNum", params).then((res) => {
               if (res.state == 200) {
                  cartData.data = proxy.$calculateSubtotal(res.data);
                  curCartItem.promotionModel = false
                  updateLocCartData(cartData.data);
               } else {
                  ElMessage.error(res.msg);
               }
            });
         }
      };

      const editBuySets = (type) => {
         switch (type) {
            case 'plus': {
               buySets.value++
               if (buySets.value > 999) {
                  buySets.value = 999;
                  ElMessage.warning('购买套数达到最大值');
               }
               break;
            }
            case 'minus': {
               if (buySets.value == 1) {
                  ElMessage.warning('购买套数最少为1套');
               } else {
                  buySets.value--;
               }
               break
            }
            case 'edit': {
               if (!/^[1-9]\d*$/.test(buySets.value)) {
                  buySets.value = 1
               }
               else if (buySets.value > 999) {
                  buySets.value = 999;
                  ElMessage.warning('购买套数达到最大值');
               }
               break;
            }
         }
      }

      /**选中状态的更改
       * type ： goods:商品， store :店铺 all,全部（全选或者全不选）
       * isCheckedState：商品：当前商品的选中状态 店铺:店铺的选中状态
       * curCartStoreGoodsId：商品：当前商品购物车信息 店铺：storeId
       */
      const changeSelectState = (type, isCheckedState, curCartStoreGoodsId) => {
         if (store.state.loginFlag) {
            //已登录
            console.log(11122);
            let cartIds = ""; //商品的购物车id集
            if (type == 'spec') {
               cartIds = curCartStoreGoodsId.cartId
            } else if (type == "goods") {
               //选择商品
               console.log(111);
               // let { goodsCartList } = curCartStoreGoodsId
               // cartIds = goodsCartList.map(i => i.cartId).join(',')
               cartIds = curCartStoreGoodsId.cartId
            } else if (type == "store") {
               //选择店铺
               let { storeGoodsList } = curCartStoreGoodsId
               cartIds = storeGoodsList.map(i => i.goodsCartList).flat(1).map(s => s.cartId).join(',')
            } else if (type == "all") {
               //全选或全不选
               let checkArr = []
               let { storeCartGroupList } = cartData.data
               storeCartGroupList.map((storeItem) => {
                  storeItem.storeGoodsList.map((cartItem) => {
                     cartItem.goodsCartList.forEach(item => {
                        checkArr.push(item.cartId)
                     })
                  });
               })
               cartIds = checkArr.join(',')

            }

            let params = {
               cartIds: cartIds, //购物车id集合
               checked: isCheckedState ? 0 : 1, //是否选中：0=全不选、1=全选中
            };
            proxy.$post("v3/business/front/cart/checkedCarts", params)
               .then((res) => {
                  if (res.state == 200) {
                     // cartData.data = res.data
                     getCartData()
                     // updateLocCartData(cartData.data);
                  } else {
                     ElMessage.error(res.msg);
                  }
               });
         }
      };

      //移入收藏夹 cartId: 商品购物车id集(当前商品购物车id)
      const moveCartGoods = (cartId) => {
         let params = {
            cartIds: cartId,
         };
         proxy
            .$post("v3/business/front/cart/moveToCollection", params)
            .then((res) => {
               if (res.state == 200) {
                  cartData.data = res.data;
                  cartData.data = proxy.$calculateSubtotal(cartData.data);


                  ElMessage.success(L["移入收藏夹成功！"]);
               } else {
                  ElMessage.error(res.msg);
               }
            });
      };

      //删除商品 type:'goods'单个商品 curCartItem: 当前商品信息    batch:批量
      const delCartGoods = (type, curCartItem) => {
         if (store.state.loginFlag) {
            //已登录
            if (type == "goods") {
               delGoodsCartIds.value = curCartItem.cartId;
               curCartItem.promotionModel = false
            } else if (type == "batch") {
               let delGoodsList = []
               cartData.data.storeCartGroupList.map((storeItem) => {
                  storeItem.storeGoodsList.map((cartItem) => {
                     let checkList = cartItem.goodsCartList.filter(i => i.isChecked).map(i => i.cartId)
                     delGoodsList = delGoodsList.concat(checkList)
                  });
               });
               delGoodsCartIds.value = delGoodsList.join(',')
            }
         }
         chosenGoodsModel.value = false
         confirmDelGoodsModel();
      };

      //确定删除商品
      const confirmDelGoodsModel = () => {
         if (store.state.loginFlag) {
            // 已登录
            let params = {
               cartIds: delGoodsCartIds.value,
            };
            proxy
               .$post("v3/business/front/cart/deleteCarts", params)
               .then((res) => {
                  if (res.state == 200) {

                      if (res.data.storeCartGroupList.length > 0) {
                        res.data.storeCartGroupList.forEach(item => {
                           item.storeGoodsList.forEach(item2 => {
                              item2.goodsCartList.forEach(item3=>{
                                 item3.types = item3.type
                                 if(item3.type==1){
                                    item3.chang = item3.buyNumType
                                    item3.dun = ''
                                    item3.pian = ''
                                    item3.gen = ''
                                 }else if(item3.type==2){
                                    item3.chang = ''
                                    item3.dun = ''
                                    item3.pian = item3.buyNumType
                                    item3.gen = ''
                                 }else if(item3.type==3){
                                    item3.chang = ''
                                    item3.dun = ''
                                    item3.pian = ''
                                    item3.gen = item3.buyNumType
                                 }else if(item3.type==4){
                                    item3.chang = ''
                                    item3.dun = item3.buyNumType
                                    item3.pian = ''
                                    item3.gen = ''
                                 }else if(item3.type==5){
                                    item3.chang = ''
                                    item3.dun = ''
                                    item3.pian = ''
                                    item3.gen = ''
                                 }
                              })
                              item2.editState = false;
                              item2.productCode = item2.productCode;
                              item2.addCode = (item2.productCode && item2.productCode.length) ? false : true;
                           })
                        });
                     }
                     cartData.data = proxy.$calculateSubtotal(res.data);


                    //  cartData.data = res.data;
                     ElMessage.success(L["删除成功！"]);
                     proxy.$getLoginCartListData();
                     proxy.$getLoginSampleListData();
                  } else {
                     ElMessage.error(res.msg);
                  }
               });
         }
         updateLocCartData(cartData.data);
      };

      //打开清空失效商品弹框
      const clearInvalidGoods = () => {
         clearInvalidGoodsModel.value = true;
      };
      //确定清空失效商品
      const confirmClearInvalidModel = () => {
         let params = {};
         proxy.$post("v3/business/front/cart/emptyInvalid", params).then((res) => {
            if (res.state == 200) {
               cartData.data.invalidList = [];
               ElMessage.success(L["清空失效产品成功！"]);
            } else {
               ElMessage.error(res.msg);
            }
         });
         clearInvalidGoodsModel.value = false;
      };
      //取消清空失效商品
      const cancelClearInvalidModel = () => {
         clearInvalidGoodsModel.value = false;
      };

      //去商品详情页
      const goGoodsDetail = (productId) => {
         proxy.$goGoodsDetail(productId);
      };

      //提交清单
      const goSubmit = () => {
         if (cartData.data.totalCheck > 0) {
            if (isSubmit.value) {
               return;
            }
            let flag = false;
            let name = '';
            let num = '';
            let productInfos = [];


            let { storeCartGroupList } = cartData.data

            storeCartGroupList.forEach(item => {
               item.storeGoodsList.forEach(items => {
                  items.goodsCartList.forEach(item2 => {
                     if (item2.isChecked == 1) {
                        if (item2.buyNum < item2.minBuyNum && !name) {
                           flag = true;
                           name = item2.goodsName;
                           num = item2.minBuyNum;
                        }
                        productInfos.push(item2.productId + '-' + item2.buyNum)
                     }
                  })
               })
            })
            if (flag) {
               ElMessage.warning('产品：' + name + '起订量为' + num);
               return;
            }
            isSubmit.value = true;
            let param = {
               productInfos: productInfos.join(',')
            }
            proxy.$post('v3/member/front/member/purchase/apply/submit', param)
               .then(res => {
                  if (res.state === 200) {
                     ElMessage.success(res.msg);
                     isSubmit.value = false;
                     setTimeout(() => {
                        location.reload();
                     }, 1000)
                  } else {
                     isSubmit.value = false;
                     ElMessage.error(res.msg);
                  }
               })
         } else {
            ElMessage('提交产品不能为空！');
         }
      };

      const cartArray = reactive({data:[]});
      const arrayFlag = ref(false);
      //去结算
      const goBuy = () => {
         if (store.state.loginFlag) {
            if (isSubmit.value) {
               return;
            }
            if (cartData.data.totalCheck > 0) {
               cartArray.data= []
               arrayFlag.value=false
               let flag = false;
               let name = '';
               let num = '';
               cartData.data.storeCartGroupList.map(item => {
                  item.storeGoodsList.map(items => {
                     items.goodsCartList.forEach(item2 => {
                        if(item2.isChecked == 1){
                           cartArray.data.push(item2)
                        }
                        if (item2.isChecked == 1 && item2.buyNum < item2.minBuyNum && !name) {
                           flag = true;
                           name = items.goodsName;
                           num = items.minBuyNum;
                        }
                     })
                  })
               })
               cartArray.data.forEach((item)=>{
                  if(cartArray.data[0].goodsProperty.presencePlace=='鞍山'){
                     if(item.goodsProperty.presencePlace=='沈阳'){
                        arrayFlag.value = true 
                     }
                  }else if(cartArray.data[0].goodsProperty.presencePlace=='沈阳'){
                     if(item.goodsProperty.presencePlace=='鞍山'){
                        arrayFlag.value = true 
                     }
                  }
               })
               if(arrayFlag.value){
                  ElMessage('货物所在地不同，不可一起下单');
                  return
               }
               if (flag) {
                  ElMessage.warning('产品：' + name + '起订量为' + num);
                  return;
               }
               let query = {
                  ifcart: true,
                  sets: buySets.value
               }
               router.push({ path: "/buy/confirm", query });
            } else {
               ElMessage(L["结算产品不能为空！"]);
            }
         } else {
            //未登录提示登录
            loginModalVisibleFlag.value = true;
         }
      };
      //关闭登录弹框
      const closeLoingModal = () => {
         loginModalVisibleFlag.value = false;
      };

      const exportCarts = () => {
         proxy.$get('v3/business/front/cart/export', {}, 'blob').then(res => {
            let tmp = new Blob([res], { type: 'application/vnd.ms-excel' })
            const aLink = document.createElement('a');
            document.body.appendChild(aLink);
            aLink.style.display = 'none';
            aLink.href = window.URL.createObjectURL(tmp);
            aLink.setAttribute('download', '导出清单');
            aLink.click();
            document.body.removeChild(aLink);
         })
      }


      //去首页
      const goIndex = () => {
         router.push({ path: "/index" });
      };


      //打开优惠券弹框
      const openCouponModel = (storeId, index, type) => {
         switch (type) {
            case 'open': {
               curStoreId.value = storeId ? storeId : curStoreId.value;

               if (cartData.data.storeCartGroupList[index].showCoupon) {
                  cartData.data.storeCartGroupList[index].showCoupon = false;
                  curStoreId.value = -1
               } else {
                  //获取优惠券
                  let params = {
                     storeId: curStoreId.value,
                     current: curCouponPage.value,
                     pageSize: couponPageSize.value
                  };
                  proxy.$get("v3/promotion/front/coupon/storeCouponList", params).then((res) => {
                     if (res.state == 200) {
                        couponList.data = res.data;
                     }
                  });
                  cartData.data.storeCartGroupList[index].showCoupon = true;
               }
               break
            }

            case 'get': {
               //获取优惠券
               let params = {
                  storeId: curStoreId.value,
                  current: curCouponPage.value,
                  pageSize: couponPageSize.value
               };
               proxy.$get("v3/promotion/front/coupon/storeCouponList", params).then((res) => {
                  if (res.state == 200) {
                     couponList.data = res.data;
                  }
               });
            }
         }




      };
      //关闭优惠券弹窗
      const closeCouponModel = (index) => {
         cartData.data.storeCartGroupList[index].showCoupon = false;
      };



      //当前页数优惠券
      const handleCurrentChangeCoupon = (e) => {
         curCouponPage.value = e
         openCouponModel(null, null, 'get');
      };



      const delBatchGoods = () => {
         let antiNum = 0
         let { totalCheck } = cartData.data
         if (totalCheck == 0) {
            ElMessage.warning('请选择要删除的产品')
         } else {
            chosenGoodsModel.value = true
         }
      }


      const service = (id) => { //客服
         let newWin = router.resolve({
            path: '/service',
            query: {
               vid: id
            }
         })
         window.open(newWin.href, '_blank');
      }

      const hanleKey = (e) => {
         if (e.keyCode == 100) {
            return false
         }
      }

      // 销售单位切换
      const goJiaType = (index1,index2,index3,type)=>{
         let cartcon = cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3]
         if(cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3].types!=type){
            let param = {
               cartId:cartcon.cartId,
               type:type
            }
            if(type!=5){
               proxy.$post("v3/business/front/cart/updateCartcheck", param).then((res) => {
                  if (res.state == 200) {
                     if(res.data){
                        cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3].types = type
                     }else{
                        ElMessage.warning('该商品销售单位已存在，请重新选择')
                     }
                  }else{
                     ElMessage.error(res.msg)
                  }
               });
            }else if(type==5&&cartcon.type!=5){
               goJiaTypejuan(index1,index2,index3,type,-1)
            }else if(type==5&&cartcon.type==5){
               cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3].types=type
            }
            
         }
      }

      // 销售单位切换选卷
      const goJiaTypejuan = (index1,index2,index3,type,ind)=>{
         let cartcon = cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3]
         let param = {
            cartId:cartcon.cartId,
            type:type,
            rollWight:ind==-1?cartcon.goodsProperty.roll[0].rollWight:cartcon.goodsProperty.roll[ind].rollWight
         }
         proxy.$post("v3/business/front/cart/updateCartcheck", param).then((res) => {
            if (res.state == 200) {
               if(res.data){
                  cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3].types = type
                  editNum(index1,index2,index3,'juan',5,1,ind)
               }else{
                  ElMessage.warning('该商品销售单位已存在，请重新选择')
               }
            }else{
               ElMessage.error(res.msg)
            }
         });
      }

      // 输入框
    const numFour =(index1,index2,index3,type)=>{
      let items = cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3]
      // 米
      if(type=='chang'){
        if(items.chang<0){
         items.chang=0
        }
        let num = String(items.chang).split('.')
        let nums = 0
        if(num[1]&&num[1].length>3){
          num[1] = num[1].substring(0,3)
          nums = num.join('.')
           cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3].chang = Number(nums)
         }else{
            nums = items.chang
            if(nums){
               if(items.buyNumType!=nums||items.type==5){
                  editNum(index1,index2,index3,type,1,nums,-1)
               }
            }
         }
      }else if(type=='dun'){//吨
        if(items.dun<0){
          items.dun=0
        }
        let num = String(items.dun).split('.')
        let nums = 0
        if(num[1]&&num[1].length>3){
          num[1] = num[1].substring(0,3)
          nums = num.join('.')
          cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3].dun = Number(nums)
        }else{
            nums = items.dun
            if(nums){
               if(items.buyNumType!=nums||items.type==5){
                  editNum(index1,index2,index3,type,4,nums,-1)
               }
            }
         }
      }else if(type=='pian'){//片
        if(items.pian<1){
          items.pian=''
        }
        let num = String(items.pian).split('.')
        let nums = 0
        if(num[0].length>0){
          cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3].pian = Number(num[0])
          nums = items.pian
          if((items.buyNumType!=nums||items.type==5)&&nums){
             editNum(index1,index2,index3,type,2,nums,-1)
          }
        }
      }else if(type=='gen'){//根
        if(items.gen<1){
          items.gen=''
        }
        let num = String(items.gen).split('.')
        let nums = 0
        if(num[0].length>0){
          cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3].gen = Number(num[0])
          nums = items.gen
          if((items.buyNumType!=nums||items.type==5)&&nums){
             editNum(index1,index2,index3,type,3,nums,-1)
          }
        }
      }
    }

   //  修改数量   types:1按米，2按片，3按根，4按吨，5按卷
    const editNum = (index1,index2,index3,type,types,nums,ind)=>{
      let cartcon = cartData.data.storeCartGroupList[index1].storeGoodsList[index2].goodsCartList[index3]
      let param = {
         cartId:cartcon.cartId,
         type:types,
         deliverType:cartcon.goodsProperty.deliverType,
         number:types!=5?nums:1, 
      }
      if(types==5){
         let roll = cartcon.goodsProperty.roll
         param.rollWight = ind==-1?roll[0].rollWight:ind==-2?cartcon.rollWight:roll[ind].rollWight
         if(ind==-2){
            if(cartcon.deliverType==1){
               param.deliverType = 2
            }else{
               param.deliverType = 1
            }
         }
      }else if(ind==-2){
         param.number = cartcon.buyNumType
         if(cartcon.deliverType==1){
            param.deliverType = 2
         }else{
            param.deliverType = 1
         }
      }
      proxy.$post("v3/business/front/cart/changeNum", param).then((res) => {
      if (res.state == 200) {
            // ElMessage.success(res.msg);
            getCartData()
         // cartData.data = proxy.$calculateSubtotal(res.data);
         // curCartItem.promotionModel = false
         // updateLocCartData(cartData.data);
      } else {
         ElMessage.error(res.msg);
         getCartData()
      }
   });

    }

    const changeSelectStates = ()=>{
      ElMessage.warning('所需吨数请输入小于等于可供重量的数值')
    }

      //暴露的变量及方法
      return {
         L,
         token,
         cartData,
         editNum,
         clearInvalidGoods,
         clearInvalidGoodsModel,
         confirmClearInvalidModel,
         cancelClearInvalidModel,
         changeSelectState,
         confirmDelGoodsModel,
         moveCartGoods,
         delCartGoods,
         delGoodsCartIds,
         goGoodsDetail,
         goSubmit,
         goBuy,
         goIndex,
         couponList,
         cartListData,
         loginModalVisibleFlag,
         closeLoingModal,
         optionsBtn,
         optionsBtnTop,
         windowHeight,
         optionsBtnShow,
         curStoreId,
         elementScrollTop,
         delBatchGoods,
         chosenGoodsModel,
         editBuySets,
         buySets,
         storeImg,
         service,
         firstLoading,
         skelelonData,
         memberInfo,
         isSubmit,
         changeEdit,
         exportCarts,
         closeCouponModel,
         handleCurrentChangeCoupon,
         openCouponModel,
         hanleKey,
         goJiaType,
         inpshu,
         numFour,
         goJiaTypejuan,
         editNums,
         cartArray,
         arrayFlag,
         changeSelectStates
      };
   },
};
</script>

<style lang="scss" scoped>
@import "../../style/base.scss";
@import "../../style/cartIndex.scss";
input::-webkit-inner-spin-button{ 
    -webkit-appearance: none !important;    
    margin: 0;    
    -moz-appearance: textfield; 
}
</style>
<style lang="scss">
.cart_content .el-radio {
   margin-bottom: 15px;
   white-space: unset;
   vertical-align: top;
   margin-right: 20px;
}

.cart_content .el-radio__label {
   width: 240px;
   display: inline-block;
   word-break: break-all;
   vertical-align: top;
}

.cart_content .el-radio__input.is-checked .el-radio__inner {
   border-color: $colorMain2;
   background: $colorMain2;
}

.cart_content .el-radio__input.is-checked+.el-radio__label {
   color: $colorMain2;
}

.promotion_btn {
   span {
      width: 40px;
      height: 20px;
      background: $colorMain;
      border-radius: 3px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: inline-block;
      text-align: center;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;

      &:nth-child(2) {
         margin-left: 40px;
         background: #ffffff;
         color: $colorMain;
      }
   }
}

.confirm_order_main {
   .store_item_bottom_item {
      .el-input__prefix {
         .el-icon-date {
            height: 34px;
            line-height: 34px;
         }
      }

      .el-input__icon,
      .el-input__suffix,
      .el-icon-circle-close {
         height: 36px;
         line-height: 36px;
      }
   }
}

// .el-overlay {
//    background-color: rgba(0, 0, 0, 0.3);
//    margin-bottom: 15px;
//    white-space: unset;
//    vertical-align: top;
//    margin-right: 20px;
// }

.cart_content .el-radio__label {
   width: 240px;
   display: inline-block;
   word-break: break-all;
   vertical-align: top;
}

.cart_content .el-radio__input.is-checked .el-radio__inner {
   border-color: $colorMain2;
   background: $colorMain2;
}

.cart_content .el-radio__input.is-checked+.el-radio__label {
   color: $colorMain2;
}

.promotion_btn {
   span {
      width: 40px;
      height: 20px;
      background: $colorMain;
      border-radius: 3px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: inline-block;
      text-align: center;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;

      &:nth-child(2) {
         margin-left: 40px;
         background: #ffffff;
         color: $colorMain;
      }
   }
}

.confirm_order_main {
   .store_item_bottom_item {
      .el-input__prefix {
         .el-icon-date {
            height: 34px;
            line-height: 34px;
         }
      }

      .el-input__icon,
      .el-input__suffix,
      .el-icon-circle-close {
         height: 36px;
         line-height: 36px;
      }
   }
}

.el-overlay {
   background-color: rgba(0, 0, 0, 0.3);
}
</style>