<template>
  <div class="store_introduce">
    <div class="mod5 flex-col">
      <div class="layer2 flex-row">
        <div class="main2 flex-col"></div>
        <span class="txt6">企业介绍</span>
      </div>
    </div>
    <div class="mod6 flex-col" v-if="detail.content || detail.imgs.length > 0">
      <div class="box12 flex-col">
        <div v-if="detail.content" class="rich_content ql-container" :style="detail.contentBg">
          <div class="ql-editor" v-html="detail.content"></div>
        </div>
        <div class="word9" v-if="detail.imgs.length > 0">
          <div class="enterprise_cer">企业资质</div>
          <div class="flex_row_start_center flex_wrap">
            <div v-for="item in detail.imgs" :key="item">
              <div class="word8">
                <img class="pic1" referrerpolicy="no-referrer" :src="item.certificateImageUrl" />
                <div class="section1">{{ item.certificateName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SldCommonEmpty v-else tip="暂无企业介绍" totalWidth="1200px" />
  </div>
</template>

<script>
  import { reactive, getCurrentInstance, ref, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import CartModel from "@/components/CartModel";
  import SldCommonEmpty from "@/components/SldCommonEmpty";

  export default {
    name: "storeIntroduce",
    components: {
      CartModel,
      SldCommonEmpty
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const route = useRoute();
      const detail = reactive({
        content: "",
        contentBg: '',
        imgs: []
      });

      const getIntroduce = () => {
        //获取企业介绍、企业资质
        let param = {
          storeId: route.query.vid
        };
        proxy.$get("v3/cms/front/introduction/detail", param).then(res => {
          detail.content = proxy.$quillEscapeToHtml(res.data.content);
          if (detail.content.indexOf('<p style="display:none;" data-background="') != -1) {
            let bg = detail.content.split('<p style="display:none;" data-background="')[1].split('">')[0]
            detail.contentBg = bg;
          }
        });
        proxy
          .$get("v3/seller/front/store/certificate/list", param)
          .then(res => {
            detail.imgs = res.data.list;
          })
          .then(() => {
            proxy.$get("v3/seller/front/store/detail", param).then(res => {
              if (res.state == 200) {
                if (res.data.businessLicenseImage) {
                  detail.imgs.push({
                    certificateImageUrl: res.data.businessLicenseImage,
                    certificateName: "营业执照"
                  });
                }
              }
            });
          });
      };

      onMounted(() => {
        getIntroduce();
      });

      return {
        L,
        detail,
        getIntroduce
      };
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/style/store/introduce.scss";
  @import "../../style/vendors.css";
</style>
<style lang="scss">
  .flex_wrap {
    flex-wrap: wrap;
  }

  .store_introduce {
    .rich_content {
      padding: 0 20px;
      word-break: break-all;



      .ql-editor {
        padding: 0;
      }

      img {
        max-width: 900px;
      }

      a {
        display: inline-block;
        margin: 5px auto;
        color: #0000FF;
        text-decoration: underline;
      }

      table {
        border-collapse: collapse;
        padding: 0;
      }

      td,
      th {
        border: 1px solid #DDD;
        padding: 5px 10px;
      }

      ol li,
      ul li {
        list-style: unset;
      }
    }
  }
</style>