<template>
   <div class="goods_list_container">
      <!-- 分类路径 -->
      <categoryHeader ref="cateHeader" :total="goodsData.page.total"></categoryHeader>
      <div class="goods_list_banner">
         <!-- 二级分类或者三级分类的组件 -->
         <GoodsListCate2 :cateItem="cate1.child" :cate2Name="catePathName.path[0]" v-if="showIndex == 1"
            @character="character" ref="goodsCate2">
         </GoodsListCate2>
         <GoodsListCate2 :cateItem="cate2.child" :cate2Name="catePathName.path[1]" v-if="showIndex == 2">
         </GoodsListCate2>
         <GoodsListCate3 :series="true" @chosenAttr="chosenAttr" :categoryid="categoryId3" @contrast="contrast"
            ref="goodsCate3">
         </GoodsListCate3>
      </div>
      <div class="new_tit">
          <div class="tit_name">产品名称</div>
          <div class="new_name">
              <div style="padding: 10px;" v-for="(item,index) in goodsNameList.data"
                  :key="index" class="item_name" @click="search_S(item)">{{item}}</div>
          </div>
      </div>
      <div class="goods_list_search flex_row_start_center">
         <!-- <div class="product_1 flex_row_start_center">
            <p>产品名称</p>
            <el-select v-model="productName" clearable filterable remote @clear="getGoodsName" :remote-method="getGoodsName" class="product_sel product_sel1" placeholder="请选择产品" size="small">
               <el-option
                  v-for="(item,index) in goodsNameList.data"
                  :key="index"
                  :label="item"
                  :value="item"
               />
            </el-select>
         </div> -->
         <div class="product_1 flex_row_start_center">
            <p>材质</p>
            <el-select v-model="material" filterable clearable remote  @clear="remoteMethod('','material')" :remote-method="(query)=>{remoteMethod(query,'material')}" class="product_sel product_sel1" placeholder="请选择材质" size="small">
               <el-option
                  v-for="(item,index) in searchList.material"
                  :key="index"
                  :label="item.material"
                  :value="item.material"
               />
            </el-select>
         </div>
         <div class="product_1 product_2 flex_row_start_center">
            <p>产地</p>
            <el-select v-model="originPlace" filterable clearable remote @clear="remoteMethod('','origin_place')" :remote-method="(query)=>{remoteMethod(query,'origin_place')}" class="product_sel product_sel1" placeholder="请选择产地" size="small">
               <el-option
                  v-for="(item,index) in searchList.origin_place"
                  :key="index"
                  :label="item.originPlace"
                  :value="item.originPlace"
               />
            </el-select>
         </div>
         <div class="product_1 product_2 flex_row_start_center">
            <p>货物存在地</p>
            <el-select v-model="presencePlace" clearable class="product_sel" placeholder="请选择货物存在地" size="small">
               <el-option
                  v-for="item in options.data"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
               />
            </el-select>
         </div>
      </div>
      <div class="goods_list_search goods_list_search_o flex_row_between_center">
         <div class="flex_row_start_center">
            <div class="product_3 flex_row_start_center">
               <p>规格</p>
               <el-input placeholder="请输入规格" clearable v-model="specModel" size="small" class="product_3_1"/>
            </div>
            <div class="product_4 flex_row_start_center">
               <p style="width:34px;">长度</p>
               <!-- <el-input placeholder="下限" size="small" clearable v-model="lengthMin" type="number" class="product_4_1"/>
               <div class="xian"></div>
               <el-input placeholder="上限" size="small" clearable v-model="lengthMax" type="number" class="product_4_1"/> -->
               <el-input placeholder="请输入长度" style="margin-left: 10px;" clearable v-model="lengthMin" size="small" class="product_3_1"/>
            </div>
         </div>
         <div class="search_o_right flex_row_start_center">
            <div class="search_o_btn flex_row_center_center" @click="goodsearch">搜索</div>
            <div class="reset_o_btn flex_row_center_center" @click="goodclear">重置</div>
         </div>
      </div>
      <div class="goods_list">
         <!-- 筛选区域 -->
         <div class="sld_screen flex_row_between_center">
            <div class="flex_row_start_center">
               <div @click="filterSort(0)"
                  :class="{ screen_item: true, btn_sort_default: indexNum == 0, flex_row_center_center: true }">
                  {{ L["默认"] }}</div>
               <div
                  :class="{ screen_item: true, flex_row_center_center: true, sld_price_jt_down: indexNum == 4, sld_price_jt_up: indexNum == 3, btn_sort_default: indexNum == 4 || indexNum == 3 }"
                  @click="filterSort(indexNum == 4 ? 3 : 4)">
                  <div class="sld_price">{{ L["价格"] }}</div>
               </div>
               <!-- <div @click="filterSort(2)"
                  :class="{ screen_item: true, flex_row_center_center: true, btn_sort: indexNum == 2, btn_sort_default: indexNum == 2 }">
                  {{ "发货日" }}
                  <i class="iconfont iconxiangshangjiantou" style="font-size: 13px"></i>
               </div> -->
               <!-- <div class="screen_item_price ">
                  <span class="price_git">{{ L["价格"] }}</span>
                  <div class="price_section">
                     <div class="input_box">
                        <input type="number" class="sld_rmb" v-model="lowprice" id="lowprice"
                           @input="priceInput('lowprice', $event)" @keydown="keyPress" />
                        <span class="line"></span>
                        <input type="number" class="sld_rmb" v-model="highprice" id="highprice"
                           @input="priceInput('highprice', $event)" @keydown="keyPress" />
                        <span class="line1"></span>
                     </div>

                     <div class="ctrl flex_row_between_center">
                        <a class="clearVal" @click="resetPrice">{{ L["清空"] }}</a>
                        <a class="confirm" @click="confirmPrice">{{ L["确定"] }}</a>
                     </div>
                  </div>
               </div> -->
               <div class="screen_item_sel flex_row_start_center"
                  v-if="memberInfo.memberType == 2 || memberInfo.memberType == 3">
                  <!-- <input type="checkbox" v-model="labelCode" id="label" @change="changeBox"> -->
                  <el-checkbox v-model="labelCode" id="label" @change="changeBox">有物料编码</el-checkbox>
                  <!-- <label for="label">
                     <span>有物料编码</span>
                  </label> -->
               </div>
            </div>
            <div class="flex-row">
               <div class="sld_goods_num flex_row_center_center">
                  <font color="#121212">共</font><span style="margin: 0 4px;">{{ goodsData.page.total || 0 }}</span>
                  <font color="#121212">件</font>
               </div>
               <div class="bar flex_row_center_center">
                  <span class="el-icon-arrow-left" style="cursor: pointer;" @click="recomChange('prev')"></span>
                  <span class="txt21" style="font-size: 13px;">
                     <font color="#285FDE">{{ goodsData.page.total ? goodsData.page.current : 0 }}</font> / <font>
                        {{ Math.ceil(goodsData.page.total / 10) || 0 }}
                     </font>
                  </span>
                  <span class="el-icon-arrow-right" style="cursor: pointer;" @click="recomChange('next')"></span>
               </div>
            </div>
         </div>

         <div id="scrollView"></div>
         <!-- 商品列表 -->
         <div class="sld_goods_list" v-if="goodsData.data.length" id="goods">
            <div class="block13 flex_row_start_center">
               <span class="word89">图片</span>
               <span class="word90">产品名称</span>
               <span class="word_91">规格</span>
               <span class="word_92">材质</span>
               <span class="word_93">产地</span>
               <span class="word_94">单价（元/吨）</span>
               <span class="word_95">货物存在地</span>
               <span class="word94">操作</span>
            </div>
            <div>
               <proListItem v-for="(item, index) in goodsData.data" @contrast="contrast" @addProJ="addProJ" :key="index"
                  :item="item" :index="index" @addDialog="addDialog" @openLogin="openLogin"></proListItem>
            </div>
         </div>
      </div>

      <!-- 空数据 -->
      <template v-if="!goodsData.data.length">
         <proEmpty ref="proEmpty"></proEmpty>
      </template>

      <!-- 分页 -->
      <div class="flex_row_center_center sld_pagination">
         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="goodsData.page.current"
            :page-size="goodsData.page.pageSize" layout="prev, pager, next, jumper" :total="goodsData.page.total"
            :hide-on-single-page="false" v-if="goodsData.page.total > 0">
         </el-pagination>
      </div>

      <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />
      <AtoProject ref="AtoProject"></AtoProject>

      <loadAnimation1 v-show="showLoading"></loadAnimation1>
      <LogisticsFreight></LogisticsFreight>
      <Insufficient v-if="ficientVisible" @Close="ficientguan" :goodsId="cartInfos"></Insufficient>
      <shoppingCart v-if="dialogVisible" @ficientClose="ficientClose"  @close='shoppclose' :deliverType="deliverType" :DialogType="DialogType" :roll="cartInfo.data.goodsRolls" :cartInfo="cartInfo.data" :goodsPropertys="cartInfo.data.goodsProperty"></shoppingCart>
   </div>
</template>
<script>
import proEmpty from '@/components/proEmpty'
import loadAnimation1 from '@/components/loading_animation1'
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, provide, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import GoodsListCate2 from "@/components/GoodsListCate2";
import LogisticsFreight from "@/components/LogisticsFreight";
import GoodsListCate3 from "@/components/GoodsListCate3";
import SldLoginModal from "@/components/loginModal";
import SldCommonEmpty from "@/components/SldCommonEmpty";
import shoppingCart from '@/components/shoppingCart'
import proListItem from '@/components/ProListItem'
import AtoProject from '@/components/AtoProject'
import Insufficient from '@/components/Insufficient'
import categoryHeader from '@/components/categoryHeader'
import { priceSlct, getCategory } from '@/utils/common.js'
import addCompare from '@/components/comparePanel/compare.js'
export default {
   name: "goodsList",
   components: {
      GoodsListCate2,
      GoodsListCate3,
      SldLoginModal,
      SldCommonEmpty,
      proListItem,
      AtoProject,
      categoryHeader,
      loadAnimation1,
      proEmpty,
      LogisticsFreight,
      shoppingCart,
      Insufficient
   },
   setup() {
      //变量------------------------------
      const firstLoading = ref(true); //是否第一次加载
      const skeletonData = reactive({ data: [] });
      const store = useStore();
      const memberInfo = store.state.memberInfo;
      const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
      const checkList = ref('')
      const showLoading = ref(false)
      const dialogVisible = ref(false)//加入购物车弹框
      const ficientVisible = ref(false)//库存不足弹框
      const deliverType = ref('')//是否净板交货
      const cartInfo = reactive({
			data:{}
		})//加入购物车弹框里的数据
      const cartInfos = ref('')//加入购物车弹框里的数据
      const route = useRoute();
      const router = useRouter();
      // 搜索的产品名称
      const productName = ref('');
      // 搜索的材质
      const material = ref('');
      // 搜索的产地
      const originPlace = ref('');
      // 搜索的货物存在地
      const presencePlace = ref('');
      // 搜索的规格
      const specModel = ref('');
      // 搜索的长度上限
      const lengthMax = ref('');
      // 搜索的长度下限
      const lengthMin = ref('');
      //初始化的请求参数
      const params = reactive({
         current: 1,
         pageSize: 10
      });
      const options = reactive({
         data:[
         {
            value: '沈阳',
            label: '沈阳',
         },
         {
            value: '鞍山',
            label: '鞍山',
         },
         ]
      })
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const goodsData = reactive({ data: [], page: {} });
      const indexNum = ref(0);
      const isEmpty = ref(false);
      const keyword = ref(
         route.query.keyword != undefined && route.query.keyword ? route.query.keyword : ""
      ); //搜索关键词

      const categoryId3 = ref(route.query.categoryId);
      const scrollTop = ref(null);
      const labelCode = ref(false)//物料编码
      const { cate1, cate2, catePathName, cateForm } = getCategory(route)
      const showIndex = ref(0)
      const characterList = ref([])
      const scrollViewTop = ref(0)
      //方法------------------------------
      const initSkeletonData = () => {
         for (let i = 0; i < 10; i++) {
            skeletonData.data.push({
               goodsName: "",
               goodsImage: "",
               goodsPrice: "",
               saleNum: "",
               activityList: "",
               isFollowGoods: "",
               defaultProductId: "",
            });
         }
      };
      initSkeletonData();

      //初始化数据，进行请求-start
      const getInitData = (params) => {
         let searchParams = { ...params };
         if (route.query.categoryId) {
            searchParams.categoryIds = route.query.categoryId
         }
         proxy.$get("v3/goods/front/goods/goodsList", searchParams).then((res) => {
            goodsData.data = []
            if (res.state == 200) {
               res.data.list.forEach(item=>{
                  if(!item.goodsProperty.goodsStockWeight){
                     item.goodsProperty.goodsStockNum = 0
                  }
                  item.checkopen = false
                  let arr = []
                  item.goodsRolls.forEach(it=>{
                     it.check = false
                     if(it.rollNum != 0) {
                         arr.push(it)
                     }
                  })
                  item.goodsRolls = arr
               })
               goodsData.data = res.data.list;
               isEmpty.value = res.data.list.length ? false : true;
               goodsData.page = res.data.pagination;
               if (firstLoading.value) {
                  // proxy.$refs.cateHeader.init()
               }
               firstLoading.value = false
            } else {
               goodsData.data = []
            }

            if (!goodsData.data.length) {
               nextTick(() => {
                  proxy.$refs.proEmpty.getStoreList()

               })
            }

            nextTick(() => {
               showLoading.value = false
               if (proxy.$refs.goodsCate3) proxy.$refs.goodsCate3.showLoadingMain = false;
            })
         });
      };

      const { highprice, lowprice, confirm, reset } = priceSlct(getInitData, params)

      const categorySearch = (data, val) => {
         //遍历分类列表，找到该categoryId所在的id路径
         for (var i = 0; i < data.length; i++) {
            if (data[i] && data[i].categoryId == val) {
               return [
                  {
                     categoryId: val,
                     categoryName: data[i].categoryName,
                     children: data[i].children,
                  },
               ];
            }
            if (data[i] && data[i].children) {
               var d = categorySearch(data[i].children, val);
               if (d)
                  return d.concat({
                     categoryId: data[i].categoryId,
                     categoryName: data[i].categoryName,
                     children: data[i].children,
                  });
            }
         }
      };
      //获取分类Id数组对应的分类名--end

      const handleCurrentChange = (e) => {
         params.current = Math.floor(e);
         window.scrollTo({
            top: scrollViewTop.value - 200
         })
         getInitData(params);
      };

      const search_S = (value) => {
          params.keyword = value;
          getInitData(params);
      }



      const filterSort = (index) => {
         if (indexNum.value == index) {
            return;
         }
         indexNum.value = index;
         params.sort = index
         showLoading.value = true
         // proxy.$refs.goodsCate3.showLoadingMain = true;
         // refreshSeriesList()
         // refreshAttrList()
         getInitData(params)
      };

      const changeBox = (e) => {
         labelCode.value = e
         params.current = 1;
         showLoading.value = true
         proxy.$refs.goodsCate3.selectorParam.isHasCode = labelCode.value
         proxy.$refs.goodsCate3.getInitData()
         if (!goodsData.data.length) {
            proxy.$refs.goodsCate3.clearAttr()
         }
         proxy.$refs.goodsCate3.showLoadingMain = true;
         params.isHasCode = labelCode.value
         proxy.$refs.goodsCate3.getSeries({ categoryId: route.query.categoryId })
         getInitData(params)
      }
      //页数改变的方法-end

      //产品加入购物车未登录
      const openLogin = () => {
         loginModalVisibleFlag.value = true;
      };

      //关闭登录弹框
      const closeLoingModal = () => {
         loginModalVisibleFlag.value = false;
      };

      //加入对比
      const contrast = (item) => {
         addCompare(item)
      }

      //计入我的项目
      const addProJ = (item) => {
         proxy.$refs.AtoProject.proInfo.productIds = `${item.items.goodsId}-${item.num}`
         proxy.$refs.AtoProject.visibleFlag = true
      }

      //筛选属性
      const chosenAttr = (commonList, resistorList, accurayList) => {
         if (commonList.length) {
            const sorted = groupBy(commonList, (item) => [item.attrId]);
            let attrList = sorted.map(item => {
               let list = item.map(i => i.attrValId).join('-')
               return list
            })
            params.attributeInfo = attrList.join(',')
         } else {
            delete params.attributeInfo
         }

         if (accurayList.length) {
            let list = accurayList.map(i => i.attrValId).join(',')
            params.accuracyIds = list
         } else {
            delete params.accuracyIds
         }

         if (resistorList.length) {
            let list = resistorList.map(i => i.attrValId).join(',')
            params.resistanceIds = list
         } else {
            delete params.resistanceIds
         }
         showLoading.value = true
         proxy.$refs.goodsCate3.showLoadingMain = true;
         params.current = 1
         setTimeout(() => {
            getInitData(params);
            nextTick(() => {
               proxy.$refs.goodsCate2.getCharacter(params)
            })
         }, 150);
      }

      //筛选分组
      const groupBy = (arr, func) => {
         const groups = {};
         arr.forEach((o) => { //注意这里必须是forEach 大写
            const group = JSON.stringify(func(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
         });
         return Object.keys(groups).map(function (group) {
            return groups[group];
         });
      }

      //获取特性
      const character = (list) => {
         characterList.value = list
         showLoading.value = true
         proxy.$refs.goodsCate3.showLoadingMain = true;
         let refObj = proxy.$refs.goodsCate3
         if (list.length) {
            params.characterInfo = list;
            refObj.selectorParam.characterInfo = list;
         } else {
            delete params.characterInfo;
            refObj.selectorParam.characterInfo = ''
         }

         setTimeout(() => {
            refObj.getInitData()
            refreshSeriesList()
            getInitData(params);
         }, 150);
      }


      const confirmPrice = () => {
         confirm(() => {
            refreshAttrList()
            refreshSeriesList()
         })
      }


      const resetPrice = () => {
         reset(() => {
            //这是 当 例如 3- 这种情况出现 无法清空，执行
            document.getElementById('lowprice').value = ''
            document.getElementById('highprice').value = ''
            refreshAttrList()
            refreshSeriesList()
         })
      }

      window.onscroll = () => {
         if (document.getElementById('goods')) {
            let dom = document.getElementById('goods')
            scrollViewTop.value = dom.offsetTop
         }

         // console.log(scrollViewTop.value, 'scrollViewTop.value ')
      }


      const refreshSeriesList = () => {
         let refObj = proxy.$refs.goodsCate3
         refObj.selectorParam.isHasCode = labelCode.value
         refObj.selectorParam.highPrice = highprice.value
         refObj.selectorParam.lowPrice = lowprice.value
         refObj.selectorParam.sort = indexNum.value

         if (characterList.value.length) {
            refObj.selectorParam.characterInfo = characterList.value;
         } else {
            refObj.selectorParam.characterInfo = ''
         }
         refObj.getSeries({ categoryId: route.query.categoryId })

      }

      const refreshAttrList = () => {
         let refObj = proxy.$refs.goodsCate3
         refObj.selectorParam.isHasCode = labelCode.value
         refObj.selectorParam.highPrice = highprice.value
         refObj.selectorParam.lowPrice = lowprice.value
         refObj.selectorParam.sort = indexNum.value
         if (!goodsData.data.length) {
            refObj.clearAttr()
         } else {
            refObj.getInitData()
         }
      }





      //监听事件--start
      watchEffect(() => {
         if (route.query.keyword != undefined) {
            keyword.value = route.query.keyword;
            params.keyword = keyword.value;
            categoryId3.value = "";
         } else {
            delete params.keyword
         }
         if (route.query.categoryId != undefined) {
            showIndex.value = route.query.g
            categoryId3.value = route.query.categoryId
            keyword.value = "";
            params.categoryIds = route.query.categoryId;
            if (route.query.g == 1 || route.query.g == 2) {
               cateForm(route.query.categoryId, route.query.g)
            }
         } else {
            delete params.categoryIds
         }
      });

      watch(
         () => route.query.categoryId,
         (nv, ov) => {
            if (nv != ov) {
               if (route.query.categoryId && !route.query.keyword) {
                  categoryId3.value = nv;
                  delete params.keyword;
                  deleteObjWhileChange()
                  proxy.$refs.goodsCate3.clearPartSelector()
                  params.categoryIds = route.query.categoryId;
                  showIndex.value = route.query.g
                  if (route.query.g == 1) {
                     cateForm(route.query.categoryId, route.query.g)
                  }
                  showLoading.value = true
                  if (proxy.$refs.goodsCate3) proxy.$refs.goodsCate3.showLoadingMain = true;
               }
               params.current = 1
               getInitData(params);
            }

         }
      );

      watch(() => route.query.keyword, (nv, ov) => {
         if (nv != ov) {
            if (route.query.keyword && !route.query.categoryId) {
               deleteObjWhileChange()
               delete params.categoryIds
               keyword.value = route.query.keyword;
               params.keyword = keyword.value;
               showLoading.value = true
               if (proxy.$refs.goodsCate3) {
                  proxy.$refs.goodsCate3.clearPartSelector()
                  proxy.$refs.goodsCate3.showLoadingMain = true;

               }


            }
            params.current = 1
            getInitData(params);
         }

      }
      );


      const deleteObjWhileChange = () => {
         delete params.highPrice;
         delete params.lowPrice;
         delete params.sort
         delete params.attributeInfo
         delete params.characterInfo
         delete params.resistanceIds
         delete params.accuracyIds
         labelCode.value = false;
         lowprice.value = highprice.value = null;
         params.current = 1
      }

      const recomChange = (type) => {
         if (type == 'next') {
            if (params.current == Math.ceil(goodsData.page.total / 10)) {
               return
            }
            params.current++
            getInitData(params)
         } else {
            if (params.current == 1) {
               return
            }
            params.current--
            getInitData(params)
         }
      }

      //监听事件--end

      const priceInput = (type, e) => {
         if (type == 'lowprice') {
            lowprice.value = String(e.target.value).slice(0, 8)
            let low = String(e.target.value).split('.')
            if (low[1] && low[1].length >= 2) lowprice.value = Number(e.target.value).toFixed(2)
         }

         if (type == 'highprice') {
            highprice.value = String(e.target.value).slice(0, 8)
            let high = String(e.target.value).split('.')
            if (high[1] && high[1].length >= 2) highprice.value = Number(e.target.value).toFixed(2)
         }
      }

      const keyPress = (e) => {
         let prohibitInput = ["-", "e", "+", "E"];
         if (prohibitInput.includes(e.key)) {
            e.preventDefault();
         }
      }
      const DialogType = ref(1);
      // 加入购物车弹框
      const addDialog = (item)=>{
         if(item.goodsRolls.length>0){
            item.goodsRolls.forEach(item=>{
               item.checks = false
            })
         }
        // DialogType 1米 4吨 5卷 2片 3根 
         if(item.goodsProperty.meterWeight){
            DialogType.value = 1
         }else if(item.goodsProperty.isSupportKg==1){
            DialogType.value = 4
         }else if(item.goodsRolls.length>0){
            DialogType.value = 5
         }else if(item.goodsProperty.sliceWeight){
            DialogType.value = 2
         }else if(item.goodsProperty.rootWeight){
            DialogType.value = 3
         }
         cartInfo.data = JSON.parse(JSON.stringify(item))
         deliverType.value = JSON.parse(JSON.stringify(item)).goodsProperty.deliverType
         dialogVisible.value = true
      }

      // 关闭购物车弹框
      const shoppclose = ()=>{
         
			dialogVisible.value = false
         cartInfo.data = {}
		}

      // 关闭购物车并打开库存不足框
      const ficientClose = (infos)=>{
         dialogVisible.value = false
         cartInfos.value = infos
         ficientVisible.value = true
      }

      // 关闭库存不足框
      const ficientguan = ()=>{
         cartInfos.value = ''
         dialogVisible.value = false
         ficientVisible.value = false
      }


      router.afterEach(() => {
         showLoading.value = false;
         nextTick(() => {
            if (proxy.$refs.goodsCate3) proxy.$refs.goodsCate3.showLoadingMain = false;
         })
      });


      // 选择输入框检索
      const searchList = reactive({
         material:[],
         origin_place:[],
      })
      const remoteMethod = (value,type)=>{
         // 材质
         let param_o = {}
         if(value){
            param_o.searchValue = value
         }
         if(type=='material'){
            param_o.searchType = 'material'
               material.value = value
         }else if(type=='origin_place'){//产地
            param_o.searchType = 'origin_place'
               material.value = value
         }
         if(categoryId3.value){
            param_o.categoryId = categoryId3.value
         }
         proxy.$get("v3/goods/front/goods/getGoodsSpecModel", param_o).then((res) => {
            if (res.state == 200) {
               if(type=='origin_place'){
                  searchList.origin_place = res.data
               }else if(type=='material'){
                  searchList.material = res.data
               }
            } else {
                ElMessage.error(res.msg)
            }
        }).then(() => {
           
        })
      }

      // 选择输入框搜索
      const goodsearch = ()=>{
         params.current = 1;
         params.keyword = productName.value
         params.material = material.value
         params.originPlace = originPlace.value
         params.presencePlace = presencePlace.value
         params.specModel = specModel.value
         if(lengthMin.value){
            if(lengthMin.value<0){
               ElMessage('长度下限不能小于0')
               return
            }
         }
         params.lengthMin = lengthMin.value
         if(lengthMax.value){
            if(lengthMax.value<0){
               ElMessage('长度上限不能小于0')
               return
            }
            // if(lengthMin.value&&Number(lengthMin.value)>Number(lengthMax.value)){
            //    ElMessage('长度下限不能大于上限')
            //    return
            // }
         }
        //  params.lengthMax = lengthMax.value
         getInitData(params)
         // console.log(params);
      }

      // 选择框产品名称列表
      const goodsNameList = reactive({
         data:[]
      })
      const getGoodsName = (value)=>{
         let param_o = {}
         if(value){
            param_o.goodsName = value
         }
        if(categoryId3.value){
            param_o.categoryId = categoryId3.value
         }
         productName.value = value
         proxy.$get("v3/goods/front/goods/getGoodsName", param_o).then((res) => {
            if (res.state == 200) {
               goodsNameList.data = res.data.list
            } else {
                ElMessage.error(res.msg)
            }
        }).then(() => {
           
        })
      }

      // 选择框获取焦点
      const focuval = (e)=>{
         if(e=='productName'){
            if(!productName.value){
               getGoodsName()
            }
         }
      }

      // 重置
      const goodclear = ()=>{
         params.current = 1;
         productName.value = ''
         material.value = ''
         originPlace.value = ''
         presencePlace.value = ''
         specModel.value = ''
        //  lengthMax.value = ''
         lengthMin.value = ''
         goodsearch()
      }

      onMounted(() => {

         if (route.query.categoryId) {
            params.categoryIds = route.query.categoryId;
            categoryId3.value = route.query.categoryId
         }
         if (route.query.keyword) {
            params.keyword = route.query.keyword;
         }
         if (route.query.storeId) {
            params.storeId = route.query.storeId;
         }
         if (route.query.goodsIds) {
            params.goodsIds = route.query.goodsIds;
         }

         if (route.query.sort) {
            params.sort = route.query.sort;
            indexNum.value = route.query.sort;
         }
         showLoading.value = true
         if (proxy.$refs.goodsCate3) proxy.$refs.goodsCate3.showLoadingMain = true;

         getInitData(params);
         getGoodsName();
         remoteMethod('','material')
         remoteMethod('','origin_place')

      })
      //返回值
      return {
         goodsData,
         handleCurrentChange,
         L,
         indexNum,
         filterSort,
         isEmpty,
         keyword,
         firstLoading,
         skeletonData,
         loginModalVisibleFlag,
         openLogin,
         closeLoingModal,
         categoryId3,
         checkList,
         contrast,
         addProJ,
         chosenAttr,
         changeBox,
         labelCode,
         character,
         highprice,
         lowprice,
         confirm,
         reset,
         cate1, cate2, catePathName, cateForm,
         showIndex,
         memberInfo,
         priceInput,
         showLoading,
         params,
         recomChange,
         confirmPrice,
         resetPrice,
         keyPress,
         productName,
         options,
         dialogVisible,
         cartInfo,
         addDialog,
         shoppclose,
         DialogType,
         ficientVisible,
         ficientClose,
         cartInfos,
         ficientguan,
         deliverType,
         material,
         originPlace,
         presencePlace,
         lengthMax,
         lengthMin,
         specModel,
         remoteMethod,
         goodsearch,
         getGoodsName,
         goodsNameList,
         searchList,
         focuval,
         goodclear,
         search_S,
      };
   },
   //
};
</script>

<style lang="scss" scoped>
@import "../../style/goodsList.scss";
@import "../../style/base.scss";

.bar {
   margin-left: 10px;
}

.empty_page {
   flex-direction: column;
   padding: 100px;
}

.flex-col {
   display: flex;
   flex-direction: column;
}

.flex-row {
   display: flex;
   flex-direction: row;
}

.sld_pagination {
   margin-top: 30px;
   padding-bottom: 30px;
}

/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}

input[type="number"] {
   -moz-appearance: textfield;
}

.goods_list {
   border: 1px solid #DBDBDB;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner {
   background-color: #285FDE;
   border-color: #285FDE;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
   color: #285FDE;
}
.el-select-dropdown__item{
   padding: 0 32px 0 20px;
}
.product_sel1 .el-input__inner{
   cursor: revert;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.el-select__caret::before{
   content: '';
}
</style>