<template>
     <div class="main_con flex-row">
          <div class="main_con_low flex_row_center_center">
               <div class="fixedTab flex_column_center_center" :style="{top:fixedTop+'px',left:fixedLeft+'px'}">
                    <div class="f1 flex_column_center_center">
                         <div class="flex_column_center_center" @click="showShare">
                              <span class="el-icon-share"></span>
                              <span class="f1_txt">分享</span>
                         </div>

                         <div :class="{share_list:true,showShareFlag:showShareFlag}">
                              <div class="share_btn_pre flex_row_start_center cursor_pointer" @click="share('qzone')">
                                   <img src="@/assets/goods/qq_share.png" alt="" />
                                   <span>{{ L["QQ"] }}</span>
                              </div>
                              <div class="share_btn_pre flex_row_start_center cursor_pointer" @click="share('sina')">
                                   <img src="@/assets/goods/sina_share.png" alt="" />
                                   <span>{{ L["新浪"] }}</span>
                              </div>
                              <div class="share_btn_pre flex_row_start_center cursor_pointer" @click="share('weixin')"
                                   @mouseover="share('weixin')" @mouseleave="wxShareCode=false">
                                   <img src="@/assets/goods/wx_share.png" alt="" />
                                   <span>{{ L["微信"] }}</span>
                              </div>
                              <div class="wx_share_code flex_column_center_center" v-if="wxShareCode">
                                   <div id="share_wx_qrcode" class="wx_share_code_img"></div>
                                   <p class="wx_share_code_title">{{L['分享到微信']}}</p>
                              </div>
                         </div>
                    </div>
                    <div class="f1 flex_column_center_center" @click="operate('collect')">
                         <span
                              :class="{'el-icon-star-off':!artDetail.isMemberCollectInformation,'el-icon-star-on':artDetail.isMemberCollectInformation}"></span>
                         <span class="f1_txt">收藏</span>
                    </div>
                    <div class="f1 flex_column_center_center" @click="scrollToGoods" v-if="goodsList.length">
                         <!-- <span class="el-icon-s-goods"></span> -->
                         <img src="@/assets/information/goods.png" alt="">
                         <span class="f1_txt">产品</span>
                    </div>
                    <div class="f1 flex_column_center_center">
                         <div id="qrcode"></div>
                         <div class="mobile_order_model">
                              <div class="bigCode" id="qrcodeBig1">
                              </div>
                         </div>
                    </div>
               </div>

               <div class="information_detail">
                    <div class="nav flex_row_start_center" v-if="categoryPath.length">
                         <div class="navI flex_row_start_center">
                              <div class="box1">
                                   <a v-if="storeId" :href="`/store/article/list?storeId=${storeId}`">首页</a>
                                   <a v-else href="/information?from=main">首页</a>
                              </div>
                              <span class="el-icon-arrow-right"></span>
                         </div>
                         <div class="navI flex_row_start_center" v-for="(item,index) in categoryPath" :key="index">
                              <div class="box1">
                                   <a v-if="storeId"
                                        :href="`/store/article/list?storeId=${storeId}&categoryId=${item.id}&g=${index+1}`">
                                        {{item.name}}
                                   </a>
                                   <a v-else :href="`/information/list?categoryId=${item.id}&g=${index+1}`">
                                        {{item.name}}
                                   </a>
                              </div>
                              <span class="el-icon-arrow-right" v-if="index<categoryPath.length-1"></span>
                         </div>
                    </div>
                    <div class="content flex_row_start_start">
                         <div class="m_left">
                              <div class="mode1">
                                   <div class="txt_content">
                                        <div class="title">{{artDetail.title}}</div>
                                        <div class="title2 flex_row_between_center">
                                             <section class="flex_row_start_center">
                                                  <span class="txt11" @click="toStore">{{artDetail.storeName}}</span>
                                                  <span class="txt11" @click="followStore"
                                                       v-if="artDetail.storeId!=0">{{artDetail.isFollowStore?'已关注':'关注'}}</span>
                                                  <span class="el-icon-time"></span>
                                                  <span class="txt13">{{artDetail.createTime}}</span>
                                             </section>
                                             <section class="flex_row_start_center">
                                                  <span class="el-icon-star-off"></span>
                                                  <span
                                                       class="txt2">{{artDetail.memberCollectInformationNum?artDetail.memberCollectInformationNum:0}}&nbsp;收藏</span>
                                                  <span class="el-icon-view"></span>
                                                  <span class="txt2">{{artDetail.pageView}}&nbsp;阅读</span>
                                             </section>
                                        </div>
                                        <!-- <div class="title3">
                                             {{artDetail.introduction}}
                                        </div> -->
                                   </div>
                                   <!-- <div class="mod4 flex-col">
                                        <div class="box1 flex-col">
                                             <span class="info3">主要性能指标</span>
                                        </div>
                                   </div>

                                   <div class="table">
                                        <div class="t1 flex_row_center_start">
                                             <div class="t1_1" v-for="itm in 2">
                                                  <div class="header">额定功耗W</div>
                                                  <div class="cell1" v-for="item in 5">
                                                  </div>
                                             </div>
                                             <div class="t1_1">
                                                  <div class="header">额定功耗W</div>
                                                  <div class="cell2">
                                                  </div>
                                             </div>
                                             <div class="t1_2">
                                                  <div class="header">额定功耗W</div>
                                                  <div class="cell2">
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="t2">
                                             注：当电阻器的阻值10Ω时，仅能生产阻
                                        </div>
                                        <div class="t3">
                                             采购指南
                                        </div>
                                   </div>

                                   <div class="txt_tip flex-col">
                                        <span class="txt4" v-for="item in 6">1、该产品可按照CAST、LMS、GJB</span>
                                   </div> -->

                                   <div class="rich_content">
                                        <div v-html="artDetail.content"></div>
                                   </div>

                                   <div class="txt_tip2 flex-col">
                                        <div v-if="upDownInfoList[0]">
                                             <span>上一条：</span>
                                             <span class="txt13">
                                                  <a
                                                       :href="`/information/detail?infoId=${upDownInfoList[0].informationId}`">{{upDownInfoList[0].title}}</a>
                                             </span>
                                        </div>
                                        <div v-if="upDownInfoList[1]">
                                             <span>下一条：</span>
                                             <span class="txt13">
                                                  <a
                                                       :href="`/information/detail?infoId=${upDownInfoList[1].informationId}`">{{upDownInfoList[1].title}}</a>
                                             </span>
                                        </div>
                                   </div>
                              </div>
                              <div class="mode2" id="mode2" v-if="goodsList.length">
                                   <el-carousel :arrow="goodsList.length>1?'always':'never'" v-if="goodsList.length">
                                        <el-carousel-item v-for="(item,index) in goodsList" :key="index">
                                             <div class="carousel_con flex_row_start_start">
                                                  <div class="item_set flex-col" v-for="(item1,index1) in item"
                                                       :key="index1">
                                                       <a :href="`/goods/detail?productId=${item1.defaultProductId}`"
                                                            class="link flex-col">
                                                            <img :src="item1.goodsImage" alt="">
                                                            <div class="flex-col">
                                                                 <span class="txt5">{{item1.goodsName}}</span>
                                                                 <span class="txt6">{{item1.specValues}}</span>
                                                                 <span class="txt7">¥{{item1.goodsPrice||'--'}}</span>

                                                            </div>
                                                       </a>

                                                  </div>
                                             </div>
                                        </el-carousel-item>
                                   </el-carousel>
                              </div>
                         </div>
                         <!-- <div class="m_right">
                              <div class="h_name">热门推荐</div>
                              <div class="h_list">
                                   <div class="h1 flex_row_start_center" v-for="(item,index) in artList"
                                        :key="item.informationId">
                                        <a :href="`/information/detail?infoId=${item.informationId}`" target="_blank"
                                             class="flex_row_start_center">
                                             <span :class="`hBox box${index+1}`">{{index+1}}</span>
                                             <span class="hT">{{item.title}}</span>
                                        </a>
                                   </div>
                              </div>
                         </div> -->
                    </div>
               </div>
          </div>
          <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal"
               @refreshInfo="refreshInfo" />
     </div>
</template>


<script>
     import SldLoginModal from "@/components/loginModal";
     import { reactive, getCurrentInstance, ref, watchEffect, onMounted, toRefs, computed } from "vue";
     import { useRoute, useRouter } from 'vue-router'
     import { qrcanvas } from "qrcanvas";
     import { useStore } from 'vuex'
     import { apiUrl, mUrl } from '@/utils/config.js'
     import { ElMessage } from 'element-plus'
     export default {
          components: {
               SldLoginModal
          },
          setup() {
               const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
               const route = useRoute()
               const router = useRouter()
               const { proxy } = getCurrentInstance();
               const L = proxy.$getCurLanguage()
               const artList = ref([])
               const state = reactive({
                    artDetail: {},
                    goodsList: [],
                    categoryPath: [],

               })
               const store = useStore()
               const upDownInfoList = ref([])
               const showShareFlag = ref(false)
               const storeId = ref(route.query.storeId);
               const getArtDetail = () => {
                    let param = {
                         informationId: route.query.infoId
                    }
                    proxy.$get('v3/cms/front/information/detail', param).then(res => {
                         if (res.state == 200) {
                              if (res.data.content) {
                                   res.data.content = proxy.$quillEscapeToHtml(res.data.content);
                              }
                              state.artDetail = res.data
                              // state.artDetail.content = proxy.$quillEscapeToHtml(state.artDetail.content)
                              let arrList = res.data.goodsList
                              let len = 4
                              state.goodsList = (function () {
                                   var a_len = arrList && arrList.length;
                                   var result = [];
                                   for (var i = 0; i < a_len; i += len) {
                                        result.push(arrList.slice(i, i + len));
                                   }
                                   return result;
                              })()
                              getCode()
                              addView()
                              state.categoryPath = res.data.categoryPath.split('>').map((i, index) => {
                                   let obj = {
                                        name: i,
                                        id: res.data[`categoryId${index + 1}`]
                                   }
                                   return obj
                              })
                              upDownInfoList.value = res.data.informationList
                              recomList()
                         }
                    })
               };
               const wxShareCode = ref(false)
               //获取二维码
               const getCode = () => {
                    proxy.$nextTick(() => {
                         let canvas = qrcanvas({
                              data: "下单", //二维码内容
                              size: 20,
                              colorDark: "red",
                         });
                         if (document.getElementById("qrcode")) {
                              document.getElementById("qrcode").innerHTML = "";
                              document.getElementById("qrcode").appendChild(canvas);
                         }
                         if (document.getElementById("qrcode1")) {
                              document.getElementById("qrcode1").innerHTML = "";
                              document.getElementById("qrcode1").appendChild(canvas);
                         }

                    });

                    proxy.$nextTick(() => {
                         let canvas = qrcanvas({
                              data: `${mUrl}pages/information/detail?infoId=${state.artDetail.informationId}`, //二维码内容
                              size: 105,
                              colorDark: "red",
                         });

                         if (document.getElementById("qrcodeBig1")) {
                              document.getElementById("qrcodeBig1").innerHTML = "";
                              document.getElementById("qrcodeBig1").appendChild(canvas);
                         }
                    });
               };


               const recomList = () => {
                    let param = {
                         isRecommend: 1,
                         categoryId: state.artDetail.categoryId1
                    }
                    proxy.$get('v3/cms/front/information/informationList', param).then(res => {
                         if (res.state == 200) {
                              artList.value = res.data.list.slice(0, 10)
                         }
                    })
               }

               const toDetail = (item) => {
                    router.push({
                         path: '/information/detail',
                         query: {
                              infoId: item.informationId
                         }
                    })
               }

               const operate = (type) => {
                    switch (type) {
                         case 'collect': {

                              if (!store.state.loginFlag) {
                                   loginModalVisibleFlag.value = true
                                   return
                              }


                              let { isMemberCollectInformation, informationId } = state.artDetail
                              proxy.$post('v3/cms/front/information/memberCollectInformation', {
                                   informationId,
                                   state: !isMemberCollectInformation
                              }).then(res => {
                                   if (res.state == 200) {
                                        state.artDetail.isMemberCollectInformation = !state.artDetail.isMemberCollectInformation
                                        if (state.artDetail.isMemberCollectInformation) {
                                             ElMessage.success(res.msg);
                                             state.artDetail.memberCollectInformationNum++
                                        } else {
                                             state.artDetail.memberCollectInformationNum = state.artDetail.memberCollectInformationNum > 0 ? state.artDetail.memberCollectInformationNum - 1 : 0
                                        }
                                   }
                              })
                         }
                    }
               }

               const scrollToGoods = () => {
                    let mode2 = document.getElementById('mode2')
                    window.scrollTo({
                         top: mode2.offsetTop - 300,
                         behavior: 'smooth'
                    })
               }

               const addView = () => {
                    let { informationId } = state.artDetail
                    proxy.$post('v3/cms/front/information/updateClickNum', { informationId }).then(res => {
                    })
               }

               const followStore = () => {
                    if (store.state.loginFlag) {
                         //已登录
                         let params = {
                              isCollect: !state.artDetail.isFollowStore, // true 收藏  false 取消收藏
                              storeIds: state.artDetail.storeId,
                         };
                         proxy.$post("v3/member/front/followStore/update", params).then((res) => {
                              if (res.state == 200) {
                                   state.artDetail.isFollowStore = !state.artDetail.isFollowStore;
                              } else {
                                   ElMessage.error(res.msg);
                              }
                         });
                    } else {
                         //未登录提示登录
                         loginModalVisibleFlag.value = true;
                    }
               }

               const toStore = () => {
                    if (state.artDetail.storeId == 0) {
                         return
                    }

                    let newWin = router.resolve({
                         path: '/store/index',
                         query: {
                              vid: state.artDetail.storeId
                         }
                    })
                    window.open(newWin.href, '_blank')
               }

               const showShare = () => {
                    showShareFlag.value = !showShareFlag.value
               }

               const share = (type) => {
                    let title = state.artDetail.title; //需要分享的标题,这里取商品名字
                    let url = window.location.href; //分享的地址，用户点击可以进入到该商品
                    let content = state.artDetail.introduction; //自定义内容,这里取商品广告词
                    let targetUrl = ""; //跳转的url地址
                    if (type == "weixin") {
                         wxShareCode.value = true;
                         //微信
                         let canvas = qrcanvas({
                              data: url, //二维码内容
                              size: 100,
                              colorDark: "red",
                         });
                         let share_wx_qrcode = document.getElementById("share_wx_qrcode");
                         if (wxShareCode.value && share_wx_qrcode != null && share_wx_qrcode != undefined) {
                              document.getElementById("share_wx_qrcode").innerHTML = "";
                              document.getElementById("share_wx_qrcode").appendChild(canvas);
                         }
                    } else if (type == "qzone") {
                         wxShareCode.value = false;
                         //QQ空间
                         targetUrl =
                              "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?title=" +
                              encodeURIComponent(title) +
                              "&desc=" +
                              encodeURIComponent(content) +
                              "&summary=" +
                              encodeURIComponent(content) +
                              "&url=" +
                              encodeURIComponent(url);
                         window.open(targetUrl, "_blank");
                    } else if (type == "sina") {
                         wxShareCode.value = false;
                         //新浪微博
                         targetUrl =
                              "https://service.weibo.com/share/share.php?title=" +
                              encodeURIComponent(content + "「" + title + "」" + " 点这里" + url);
                         window.open(targetUrl, "_blank");
                    }
               };

               const refreshInfo = () => {
                    history.go(0)
               }

               //关闭登录弹框
               const closeLoingModal = () => {
                    loginModalVisibleFlag.value = false;
               };


               onMounted(() => {
                    // console.log((document.documentElement.clientWidth - 1200) / 2, 'llllll')
                    getArtDetail()

               })

               return {
                    ...toRefs(state),
                    toDetail,
                    artList,
                    operate,
                    scrollToGoods,
                    followStore,
                    toStore,
                    upDownInfoList,
                    L,
                    showShareFlag,
                    showShare,
                    share,
                    wxShareCode,
                    storeId,
                    fixedTop: parseInt(document.documentElement.clientHeight / 2),
                    fixedLeft: parseInt((document.documentElement.clientWidth - 1200) / 2 - 88),
                    loginModalVisibleFlag,
                    refreshInfo,
                    closeLoingModal
               }
          }
     }
</script>


<style lang="scss">
     a,
     a:link {
          color: unset;

          &.link {
               width: 100%;
               height: 100%;
          }
     }

     .main_con {
          background: #F9F9F9;

          .main_con_low {
               width: 1200px;
               margin: 0 auto;
          }

          .information_detail {
               width: 1200px;
               padding-top: 1px;
               padding-bottom: 40px;


               .nav {
                    margin: 15px 0;

                    .navI {
                         margin-right: 8px;

                         .box1 {
                              padding: 0 15px;
                              height: 24px;
                              background: #FFFFFF;
                              border: 1px solid #CCCCCC;
                              line-height: 24px;
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                              font-weight: 400;
                              color: #333333;
                              margin-right: 8px;
                         }
                    }
               }

               .content {
                    .m_left {
                         width: 870px;

                         .mode1 {
                              background: #fff;
                              padding: 20px;

                              .txt_content {
                                   .title {
                                        font-size: 20px;
                                        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                                        font-weight: bold;
                                        color: #333333;
                                   }

                                   .title2 {
                                        margin-top: 20px;

                                        .txt11,
                                        .txt12 {
                                             font-size: 14px;
                                             font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                             font-weight: 400;
                                             color: #285FDE;
                                             margin-right: 20px;
                                        }

                                        .txt11:hover {
                                             text-decoration: underline;
                                             cursor: pointer;
                                        }

                                        .el-icon-time,
                                        .el-icon-star-off,
                                        .el-icon-view {
                                             font-size: 16px;
                                             color: #AEAEAE;
                                        }

                                        .el-icon-time {
                                             margin-right: 10px;
                                        }

                                        .txt13 {
                                             font-size: 14px;
                                             color: #AEAEAE;
                                        }

                                        .el-icon-star-off,
                                        .el-icon-view {
                                             margin-left: 20px;
                                        }

                                        .el-icon-star-off {
                                             margin-bottom: 2px;
                                        }

                                        .txt2 {
                                             margin-left: 5px;
                                             font-size: 14px;
                                             color: #AEAEAE;
                                        }
                                   }

                                   .title3 {
                                        margin-top: 21px;
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        font-weight: 400;
                                        color: #333333;
                                        line-height: 25px;
                                   }
                              }

                              .mod4 {
                                   position: relative;
                                   width: 723px;
                                   height: 203px;
                                   overflow: hidden;
                                   border: 1px solid #ccc;
                                   align-self: flex-start;
                                   margin: 17px 0 0 24px;

                                   .box1 {

                                        height: 201px;
                                        background: #ccc;
                                        width: 338px;
                                        justify-content: flex-end;
                                        padding-bottom: 8px;
                                        align-items: center;
                                        position: absolute;
                                        left: 193px;
                                        top: 1px;
                                   }

                                   .info3 {

                                        color: rgba(171, 171, 171, 1);
                                        font-size: 14px;
                                        font-family: PingFangSC-Regular;
                                        white-space: nowrap;
                                        line-height: 20px;
                                        text-align: left;
                                   }
                              }

                              .table {
                                   margin-top: 20px;
                                   width: 100%;

                                   .t1 {
                                        width: 100%;

                                        .header {
                                             background: #F3F7FF;
                                             font-size: 14px;
                                             font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                             font-weight: 400;
                                             color: #333333;
                                             height: 50px;
                                             line-height: 50px;
                                             text-align: center;
                                        }

                                        .t1_1 {
                                             width: 159px;



                                             .cell1 {
                                                  height: 50px;
                                                  line-height: 50px;
                                                  text-align: center;
                                                  border: 1px solid #C5D6FF;
                                             }


                                        }

                                        .cell2 {
                                             border: 1px solid #C5D6FF;
                                             height: 250px;
                                        }

                                        .t1_2 {
                                             flex: 1;
                                        }
                                   }

                                   .t2 {
                                        height: 50px;
                                        background: #C5D6FF;
                                        padding-left: 50px;
                                        line-height: 50px;
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        font-weight: 400;
                                        color: #333333;
                                   }

                                   .t3 {
                                        height: 50px;
                                        background: #fff;
                                        line-height: 50px;
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        font-weight: 400;
                                        color: #ABABAB;
                                        text-align: center;
                                   }

                              }

                              .rich_content {
                                   margin-top: 20px;
                                   width: 100%;
                                   word-break: break-all;
                                   line-height: 24px;
                                   padding: 1px;

                                   p {
                                        padding: 1px;

                                        img {
                                             object-fit: contain;
                                             max-width: 830px;
                                        }
                                   }

                                   a {
                                        display: inline-block;
                                        margin: 5px auto;
                                        color: #0000FF;
                                        text-decoration: underline;
                                   }

                                   table {
                                        border-collapse: collapse;
                                        padding: 0;
                                   }

                                   td,
                                   th {
                                        border: 1px solid #DDD;
                                        padding: 5px 10px;
                                   }

                                   ol li,
                                   ul li {
                                        list-style: unset;
                                   }
                              }

                              .txt_tip {

                                   .txt4 {
                                        line-height: 30px;
                                        background: #fff;
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        color: #333333;
                                        word-break: break-all;
                                   }
                              }

                              .txt_tip2 {
                                   margin-top: 20px;

                                   span {
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        font-weight: 400;
                                        color: #666666;
                                        line-height: 30px;

                                   }

                                   .txt13 {
                                        cursor: pointer;

                                        &:hover {
                                             color: $colorMain;
                                        }
                                   }
                              }
                         }

                         .mode2 {
                              margin-top: 20px;
                              padding: 20px 0;
                              background: #fff;

                              .el-carousel__container {
                                   position: relative;
                                   height: 230px;
                              }

                              .carousel_con {
                                   width: 100%;
                                   margin-left: 45px;
                              }


                              .item_set {
                                   margin: 0 20px;

                                   img {
                                        width: 152px;
                                        height: 152px;
                                   }

                                   .txt5 {
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        font-weight: 400;
                                        color: #333333;
                                        margin-top: 10px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: 152px;
                                   }

                                   .txt6 {
                                        font-size: 13px;
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        font-weight: 400;
                                        color: #666666;
                                        margin-top: 10px;
                                   }

                                   .txt7 {
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        font-weight: 400;
                                        color: #FC2222;
                                        margin-top: 10px;
                                   }
                              }
                         }


                    }

                    .m_right {
                         padding: 20px;
                         width: 320px;
                         background: #FFF;
                         margin-left: 15px;
                         position: relative;

                         .h_name {
                              font-size: 22px;
                              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                              font-weight: bold;
                              color: #333333;
                              margin-bottom: 10px;
                         }

                         .h_list {
                              .h1 {
                                   margin-bottom: 10px;

                                   .hBox {
                                        width: 24px;
                                        height: 24px;
                                        border-radius: 6px;
                                        font-size: 16px;
                                        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                                        font-weight: bold;
                                        color: #F9F9F9;
                                        text-align: center;
                                        line-height: 24px;

                                        background: #9D9D9D;

                                        &.box1 {
                                             background: #FF6660 !important;
                                        }

                                        &.box2 {

                                             background: #FFA560 !important;
                                        }

                                        &.box3 {

                                             background: #FFB99E !important;
                                        }
                                   }

                                   .hT {
                                        margin-left: 8px;
                                        width: 252px;
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                        font-weight: 400;
                                        color: #333333;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                   }
                              }
                         }
                    }
               }
          }
     }

     .fixedTab {
          margin-right: 20px;
          width: 58px;
          background: #fff;
          height: 235px;
          position: fixed;
          left: 280px;
          z-index: 999;

          .f1 {
               margin-bottom: 15px;
               position: relative;
               cursor: pointer;

               .el-icon-star-on,
               .el-icon-star-off,
               .el-icon-s-goods,
               .el-icon-share {
                    font-size: 24px;
                    cursor: pointer;
               }

               img {
                    width: 24px;
                    height: 24px;
               }

               .el-icon-star-on {
                    color: $colorMain;
               }

               .f1_txt {
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #4D4D4D;
                    margin-top: 8px;
               }

               #qrcode:hover+.mobile_order_model {
                    display: block;
               }

               .mobile_order_model {
                    display: none;
                    position: absolute;
                    left: 44px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 6px 0px rgba(171, 181, 205, 0.22);
                    padding: 10px;
                    z-index: 100;
               }
          }
     }

     .share_list {
          margin-left: -1px;
          width: 80px;
          height: 115px;
          border: 1px solid #dfdfdf;
          border-radius: 2px;
          padding: 10px 0 10px 10px;
          position: absolute;
          background: #fff;
          top: 0px;
          left: 48px;
          display: none;

          &.showShareFlag {
               display: block !important;
          }

          .share_btn_pre {
               margin-top: 1px;
               margin-bottom: 8px;

               &:nth-last-of-type(1) {
                    margin-bottom: 0;
               }

               img {
                    width: 24px;
                    height: 24px;
               }

               span {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-left: 8px;
               }

               &:hover span {
                    color: #333333;
               }
          }

          .wx_share_code {
               position: absolute;
               left: 85px;
               bottom: 0px;
               width: 130px;
               height: 135px;
               background: #ffffff;
               border: 1px solid #d9d9d9;

               .wx_share_code_img {
                    canvas {
                         width: 90px !important;
                         height: 90px !important;
                    }
               }

               .wx_share_code_title {
                    font-size: 10px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 6px;
               }
          }
     }
</style>