<template>
   <div :class="{ goods_list_cate2: true }">
      <div class="sld_brand_cat">
         <div class="sld_cat_item clearfix">
            <p class="sld_gleft flex_row_start_center">
               <span>{{ L["包含分类"] }}：</span>
            </p>
            <ul class="sld_gright flex_row_start_center" v-if="cateItem.length">
               <li v-for="(item, index) in cateItem" :key="index">
                  <a @click="toCate3(item)">{{ item.categoryName }}</a>
               </li>
            </ul>
            <ul class="sld_gright no_category" v-else>
               <div>暂无分类</div>
            </ul>
         </div>
         <div :class="{ sld_cat_item: true, skelelon_loading: firstLoading }"
            v-for="(item, index) in (firstLoading ? skelelonData : charaList)" :key="index">
            <p class="sld_gleft flex_row_start_center"><a>{{ item.characterName }}：</a></p>
            <ul class="sld_gright flex_row_start_center" v-if="item.characterValueList.length">
               <li v-for="(item1, index1) in item.characterValueList" :key="index1"
                  :class="{ sel: checkSel(item, item1) }">
                  <a @click="selCate(item, item1, index)">{{ item1.characterValue }}</a>
               </li>
            </ul>
            <ul class="sld_gright no_category" v-else>
               <div>暂无特性</div>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
   import { getCurrentInstance, onMounted, watchEffect, watch, ref, reactive } from "vue";
   import { useRouter, useRoute } from "vue-router";
   export default {
      name: "GoodsListCate2",
      props: ["cateItem", "firstLoading", 'storeId'],
      setup(props, { emit }) {
         const { proxy } = getCurrentInstance();
         const router = useRouter();
         const route = useRoute()
         const L = proxy.$getCurLanguage();
         const charaList = ref([])
         const chosenList = ref([])
         const firstLoading = ref(true)
         const grade = ref(route.query.g)
         const cha_first = ref(true)
         const injParam = props.injParam
         const selCate = (item, item1, index) => {

            let list = item.sel.split(',') || []
            if (list.includes(item1.valueId.toString())) {
               list = list.filter(i => i != item1.valueId)
               item.sel = list.join(',')
               chosenList.value = chosenList.value.filter(i => i.valueId != item1.valueId)
            } else {
               list.push(item1.valueId)
               item.sel = list.join(',')
               chosenList.value.push({ characterId: item.characterId, valueId: item1.valueId })
            }

            const sorted = groupBy(chosenList.value, (item2) => [item2.characterId])
            let attrList = sorted.map(item => {
               let list = item.slice(1).map(i => i.valueId).join('-')
               return `${item[0]}${list}`
            })

            getCharacter({ characterInfo: attrList.join(',') })

            emit('character', attrList.join(','))
         }

         const checkSel = (item, item1) => {
            if (!firstLoading.value) {
               let list = item.sel.split(',')
               return list.findIndex(i => i == item1.valueId) > -1
            }
         }

         //筛选分组
         const groupBy = (arr, func) => {
            const groups = {};
            arr.forEach((o) => { //注意这里必须是forEach 大写
               const group = JSON.stringify(func(o));
               groups[group] = groups[group] || [''];
               groups[group].push(o);
            });
            return Object.keys(groups).map(function (group) {
               return groups[group];
            });
         }

         const skelelonData = ref([])

         const toCate3 = (item) => {
            let { categoryId, grade, pid } = item;
            if (props.storeId) {
               router.push({
                  path: "/store/product",
                  query: {
                     ...route.query,
                     categoryId,
                     g: grade,
                     pid,
                  },
               });

            } else {
               router.push({
                  path: '/goods/list',
                  query: {
                     ...route.query,
                     categoryId,
                     g: grade,
                     pid,
                  },
               });
            }
         };




         const getCharacter = (injParam) => {
            let param = {
               categoryId: route.query.categoryId
            }

            if (props.storeId) {
               param.storeId = props.storeId
            }

            if (route.query.keyword) {
               param.keyword = route.query.keyword
            }

            if (injParam) {
               Object.keys(injParam).forEach(item => {
                  if (!['current', 'pageSize'].includes(item)) {
                     if (injParam[item]) {
                        if (item == 'categoryIds') {
                           param.categoryId = injParam.categoryIds
                        } else {
                           param[item] = injParam[item]
                        }
                     }
                  } else {
                     delete param[item]
                  }
               })
            }

            proxy.$get('v3/goods/front/goods/characterList', param).then(res => {
               if (res.state == 200) {
                  firstLoading.value = false

                  if (cha_first.value) {
                     charaList.value = res.data.map(i => {
                        return {
                           sel: '',
                           ...i
                        }
                     })


                     cha_first.value = false
                  } else {
                     let result = res.data.map(item => {
                        let obj_index = charaList.value.findIndex(i => i.characterId == item.characterId)
                        let res
                        if (obj_index > -1) {
                           res = {
                              sel: charaList.value[obj_index].sel,
                           }
                        } else {
                           res = {
                              sel: '',
                           }
                        }

                        return {
                           ...res,
                           ...item
                        }
                     })
                     charaList.value = result
                  }

               }
            })
         }

         watchEffect(() => {
            // if (route.query.g == 1) {
            //    getCharacter()
            // }

            if (charaList.value.length) {
               firstLoading.value = false
            }

         })

         watch(() => route.query.categoryId, (nv, ov) => {
            if (nv != ov) {
               chosenList.value = []
               if (nv) {
                  getCharacter()
               } else {
                  charaList.value = []
               }
            }
         })

         onMounted(() => {
            if (route.query.g == 1) {
               for (let i = 0; i <= 2; i++) {
                  skelelonData.value.push({
                     characterName: '',
                     characterValueList: ['',]
                  })
               }
               getCharacter()
            }
         });

         return { L, toCate3, charaList, selCate, checkSel, firstLoading, skelelonData, grade, getCharacter };
      },
   };
</script>
<style lang="scss">
   .clearfix {
      display: block;
      zoom: 1;
   }

   ul,
   ol,
   li {
      list-style: none;
   }

   a:visited {
      text-decoration: none;
   }

   .goods_list_cate2 {
      width: 1200px;
      margin: 0 auto;
      margin-top: 20px;
      padding-left: 0px;
      padding-right: 0px;
      font: 12px/150% Arial, Verdana, "\5b8b\4f53";
      font-family: "microsoft yahei";
      background: #fff !important;

      .sld_brand_cat {
         /* margin-bottom: 17px; */

         .sld_cat_item {
            position: relative;
            display: flex;
            border-bottom: 1px dashed #DBDBDB;
            min-height: 38px;

            &:last-child {
               border-bottom: 1px solid #DBDBDB;
            }

            li {
               margin-right: 20px;
               line-height: 23px;
            }

            .sld_gleft {
               width: 90px;
               font-size: #121212;
               color: $colorTitle1;
               font-weight: 600;
            }

            .sld_gright {
               background-color: #fff;
               flex-wrap: wrap;
               flex: 1;

               &.no_category {
                  display: flex;
                  justify-content: center;
                  width: 100%;

                  div {
                     color: #999;
                     line-height: 41px;
                  }
               }

               a {
                  color: #151A2D;
                  text-decoration: none;
                  cursor: pointer;

                  &:hover {
                     color: $colorMain;
                  }
               }
            }
         }
      }

      .skelelon_loading {
         .sld_gright li {
            width: 300px;
            height: 17px;
            background: $colorSkeleton;
            color: $colorSkeleton;
         }

         .sld_gleft a {
            width: 72px;
            height: 17px;
            color: $colorSkeleton;
            background: $colorSkeleton;
         }
      }
   }

   .sel {
      a {
         color: $colorMain  !important;
         font-weight: bold;
      }
   }
</style>