<template>
     <div class="pro_list_item flex_column_start_center">
          <div class="block144 flex_row_start_center">
               <div class="bd12 flex_row_center_center" :style="{ backgroundImage: `url(${item.goodsImage})` }"
                    @click="toDetail(0)">
                    <div class="bd122">
                         <img :src="item.goodsImage" />
                    </div>
               </div>
               <div class="bd13 flex-col">
                    <span class="word94" @click="toDetail(0)" :title="item.goodsName">{{ item.goodsName }}</span>
                    <span class="txt48" @click="emitContrast">加入对比</span>
               </div>
               <div class="bd_word_91">
                    {{ item.goodsProperty.specModel }}
               </div>
               <div class="bd_word_92">
                    {{ item.goodsProperty.material }}
               </div>
               <div class="bd_word_93">
                    {{ item.goodsProperty.originPlace }}
               </div>
               <div class="bd_word_94">
                    <div class="bd_word_94_1">￥{{ item.goodsProperty.goodsPrice ? item.goodsProperty.goodsPrice.toFixed(2) : 0 }}/吨</div>
                    <div class="bd_word_94_2">
                         <div>可供重量：{{item.goodsProperty.goodsStockWeight}}吨</div>
                         <div>可供数量：{{item.goodsProperty.goodsStockNum}}</div>
                    </div>
               </div>
               <div class="bd_word_95">
                    {{item.goodsProperty.presencePlace}}
               </div>
               <div class="bd17 flex-col" v-if="item.isSell">
                    <button class="section30 flex_row_center_center" @click="addCart"><span
                              class="word103">加入购物车</span></button>
                  
                    <button v-if="memberInfo.memberType" class="section32 flex-col" @click="emitAddPro"><span
                              class="info57">加入项目</span></button>
                    <button class="section32 flex-col" v-if="item.goodsRolls.length>0" @click="openList"><span
                    class="info57">{{ item.checkopen ? '收起' : '展开' }}</span></button>
               </div>
          </div>
          <!-- 展开 start -->
          <div class="proListzhan" v-if="item.checkopen">
               <div class="proList_th flex_row_start_center">
                    <span class="List_th1">序号</span>
                    <span class="List_th2">规格</span>
                    <span class="List_th3">重量（吨）</span>
                    <span class="List_th4">单价（元/吨）</span>
                    <span class="List_th5">操作</span>
               </div>
               <div class="proList_td flex_row_start_center" v-for="(it,ind) in item.goodsRolls" :key="ind">
                    <!-- <input type="checkbox" class="check_td" :checked="it.check" v-model="it.check" @click="checkjuan(ind,it.check)"> -->
                    <template v-if="it.rollNum">
                        <img src="@/assets/order/selected.png" alt="" v-if="it.check" @click="checkjuan(ind,false)">
                    <img src="@/assets/order/not_select.png" alt="" v-else @click="checkjuan(ind,true)">
                    <div class="td_id">{{ ind+1 }}</div>
                    <div class="td_specs">{{ item.goodsProperty.specModel }}</div>
                    <div class="td_weight">{{ it.rollWight }}</div>
                    <div class="td_univalence">￥{{ item.goodsProperty.goodsPrice ? item.goodsProperty.goodsPrice.toFixed(2) : 0 }}/吨</div>
                    <div class="td_operation flex_row_between_center">
                        <div class="td_operation_1" v-if="it.rollNum==1" @click="gojiaCart(it,ind,item)">加购</div> 
                        <div class="td_operation_3" v-else>加购</div> 
                        <div class="td_operation_2"  @click="toDetail(it.id)">查看详情</div> 
                    </div>
                    </template>
               </div>
               <div class="proList_foot flex_row_between_center">
                    <div class="proList_foot_left flex_row_start_center">
                         <!-- <input type="checkbox" class="check_foot"> -->
                         <img src="@/assets/order/selected.png" alt="" class="check_foot" v-if="checkboss" @click="checkjuans(false)">
                         <img src="@/assets/order/not_select.png" alt="" class="check_foot" v-else @click="checkjuans(true)">
                         <div>全选</div>
                    </div>
                    <!-- <div class="proList_foot_right flex_row_center_center" @click="addCarts">
                         加入购物车
                    </div> -->
               </div>
          </div>
           <!-- 点击加购弹窗时弹窗 start -->
         <div class="mask_personal" v-if="tips_flags" @click="checked_opt(3)">
         </div>
         <div class="personal_box" v-if="tips_flags">
            <div class="personal_img">
               <img src="@/assets/ze-cros.png" alt="" @click="checked_opt(3)">
            </div>
            <div class="personal_cont">
               <p>请先完成企业认证</p>
               <!-- <p>请注意，加入收藏并不代表您已选购该产品</p> -->
            </div>
            <div class="personal_btn">
               <span @click="goCollects_s">前往企业认证>></span>
            </div>
         </div>
      <!-- 点击收藏时弹窗 end -->
          <!-- 展开 end -->
          <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal"
               @refreshInfo="refreshInfo" />
               <Insufficient v-if="ficientVisible" @Close="ficientguan" :goodsId="goodsId"></Insufficient>

     </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import Insufficient from '@/components/Insufficient'
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, computed } from "vue";
import { utimes } from 'fs';
export default {
     props: ['item','index'],
      components: {
      Insufficient
   },
     setup(props, { emit }) {
          const { proxy } = getCurrentInstance();
          const router = useRouter()
          const ficientVisible = ref(false)//库存不足弹框
          const toDetail = (id) => {
               if(id){
                    let newWin = router.resolve({
                         path: '/goods/detail',
                         query: {
                              productId: props.item.defaultProductId,
                              id:id
                         }
                    })
                    window.open(newWin.href, '_blank')
               }else{
                    let newWin = router.resolve({
                         path: '/goods/detail',
                         query: {
                              productId: props.item.defaultProductId
                         }
                    })
                    window.open(newWin.href, '_blank')
               }
          }
          const store = useStore();
          const tips_flags = ref(false)//加入购物车弹框
          const checkboss = ref(false);
          const goodsId = ref('');
          const memberInfo = ref(store.state.memberInfo);
          const num = ref(props.item.minBuyNum ? props.item.minBuyNum : 1)
          const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
          const loginModalVisibleFlag = ref(false)
          const goodsSpec = computed(() => {
               if (props.item.goodsSpec.length > 39) {
                    return props.item.goodsSpec.slice(0, 39) + '...'
               } else {
                    return props.item.goodsSpec
               }
          })
                  // 用户类型点击
      const checked_opt = (flag)=>{
        if(flag==3){
          tips_flags.value = false
        }
      }
          const emitContrast = () => {
               let paramsData = {
                    productImage: props.item.goodsImage,
                    productId: props.item.goodsId,
                    productName: props.item.goodsName,
                    specValues: props.item.specValues,
                    type: 'product'
               };
               emit('contrast', paramsData)
          }

          const emitAddPro = () => {
              if(memberInfo.value.memberType == 1) {
                tips_flags.value = true;
                    return;
                }
               if (num.value < props.item.minBuyNum) {
                    ElMessage('数量小于最小起订量')
                    return
               }
               let param = {
                    productId: props.item.defaultProductId,
                    num: num.value,
                    items:props.item
               }
               emit('addProJ', param)
          }

          //加入购物车
          const addCart = () => {
              if(memberInfo.value.memberType == 1) {
                tips_flags.value = true;
                return;
                }
               if (store.state.loginFlag) {
                    emit('addDialog', props.item)
                    // if (num.value < props.item.minBuyNum) {
                    //      ElMessage('数量小于最小起订量')
                    //      return
                    // }
                    // let paramsData = {
                    //      productId: props.item.defaultProductId,
                    //      number: num.value,
                    // };
                    // proxy.$addCart(paramsData);
                    
               } else {
                    loginModalVisibleFlag.value = true
                    // ElMessage('请登录')
               }
          };

          watch(() => props.item, (nv, ov) => {
               num.value = nv.minBuyNum
          })

          const editNum = (type) => {
               switch (type) {
                    case 'minus': {
                         if (num.value <= props.item.minBuyNum) {
                              ElMessage(`最低限购${props.item.minBuyNum}`)
                              return
                         } else {
                              num.value--
                         }
                         break
                    }
                    case 'edit': {
                         if ((!/^[1-9]\d*$/.test(num.value) && num.value)) {
                              num.value = props.item.minBuyNum
                         }

                         if (num.value > 99999) {
                              num.value = 99999
                              ElMessage('超过购买限制')
                         }

                         break
                    }
                    case 'plus': {
                         num.value++

                         if (num.value > 99999) {
                              num.value = 99999
                              ElMessage('超过购买限制')
                         }

                         break;
                    }

                    case 'blur': {
                         if (num.value <= props.item.minBuyNum) {
                              num.value = props.item.minBuyNum
                         }
                    }
               }
          }


          //加入样品申请单
          const addSampleCart = () => {
               if (store.state.loginFlag) {
                    if (num.value < props.item.minBuyNum) {
                         ElMessage('数量小于最小起订量')
                         return
                    }
                    let paramsData = {
                         productId: props.item.defaultProductId,
                         number: num.value,
                    };
                    proxy.$addSampleCart(paramsData);
               } else {
                    loginModalVisibleFlag.value = true

               }


          };

          //关闭登录弹框
          const closeLoingModal = () => {
               loginModalVisibleFlag.value = false;
          };

          const refreshInfo = () => {
               history.go(0)
          }

          // 展开产品
          const openList = ()=>{
               // eslint-disable-next-line vue/no-mutating-props
               props.item.checkopen = !props.item.checkopen
          }
          // 选择
          const checkjuan = (index,check)=>{
               props.item.goodsRolls[index].check = check
               let findIndex = props.item.goodsRolls.filter(it=>{
                    return it.check==true
               })
               if(props.item.goodsRolls.length==findIndex.length&&findIndex.length!=0){
                    checkboss.value = true
               }else{
                    checkboss.value = false
               }
          }
          // 全选选择
          const checkjuans = (check)=>{
               props.item.goodsRolls.forEach(it=>{
                    it.check = check
               }) 
               checkboss.value = check
          }

          // 卷加购
          const gojiaCart = (it,ind,val)=>{
              goodsId.value = val.goodsId
              if(memberInfo.value.memberType == 1) {
                tips_flags.value = true;
                    return;
                }
               if(store.state.loginFlag){
                    if(!it.rollNum){
                         ElMessage.error('库存不足')
                         return
                    }
                    let params = {
                    goodsId: props.item.goodsId,
                    number: 1,
                    type:5,
                    deliverType:props.item.goodsProperty.deliverType,
                    rollWight:it.rollWight
               }
               proxy.$post("v3/business/front/cart/add", params).then((res) => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg);
                    } else {
                        if (res.msg === '可供数量不足！'||res.msg === '可供重量不足！') {
                            ficientVisible.value = true
                        } else {
                            ElMessage.error(res.msg)
                        }

                    }
               }).then(() => {
                    proxy.$getLoginCartListData();
               })
               }else{    
                    //未登录提示登录
                    loginModalVisibleFlag.value = true;
               }
          }
              //去认证页
       const goCollects_s = ()=>{
         let newWin = router.resolve({
            path: '/member/authen',
            query: {}
         })

         window.open(newWin.href, '_self')
      }

          // 全选加购

          const addCarts = ()=>{
              if(memberInfo.value.memberType == 1) {
                tips_flags.value = true;
                    return;
                }
               if(store.state.loginFlag){
                    let cartAddList = []
                    props.item.goodsRolls.forEach(it=>{
                         if(it.check){
                              let obj = {}
                              obj.deliverType = props.item.goodsProperty.deliverType
                              obj.goodsId=props.item.goodsId
                              obj.number=1
                              obj.productId=props.item.defaultProductId
                              obj.rollWight = it.rollWight
                              obj.type = 5
                              cartAddList.push(obj)
                         }
                    })
                    if(cartAddList.length==0){
                         ElMessage('请选择产品')
                    }
                    proxy.$post("v3/business/front/cart/batchAdd", cartAddList,"application/json").then((res) => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg);
                         } else {
                              ElMessage.error(res.msg)
                         }
                    }).then(() => {
                    
                    })
               }else{
                   //未登录提示登录
                   loginModalVisibleFlag.value = true; 
               }
               
          }
           // 关闭库存不足框
    const ficientguan = ()=>{
      ficientVisible.value = false
   }
          return {
              ficientguan,
               cartListData,
               toDetail,
               emitContrast,
               emitAddPro,
               addSampleCart,
               addCart,
               num,
               editNum,
               memberInfo,
               goodsSpec,
               loginModalVisibleFlag,
               closeLoingModal,
               refreshInfo,
               openList,
               checkjuan,
               checkboss,
               checkjuans,
               gojiaCart,
               addCarts,
               tips_flags,
            checked_opt,
            goCollects_s,
            ficientVisible,
            goodsId,
          }
     }
}
</script>

<style lang="scss">
.pro_list_item {
     border-top: 1px solid #F4F4F4;
}

.alert {
     color: #e2231a !important;
}

.goods_edit_nem1 {

     background: #ffffff;
     border: 1px solid #dddddd;

     span {
          width: 26px;
          height: 30px;
          background: #fff;
          text-align: center;
          display: block;
          line-height: 30px;
          cursor: pointer;
     }

     input {
          width: 44px;
          height: 30px;
          border: none;
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;
          text-align: center;
          line-height: 30px;
     }

     /*** 消除input元素 type="number" 时默认的 加减按钮*/
     input[type="number"]::-webkit-inner-spin-button,
     input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }

     /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
     input[type="number"] {
          -moz-appearance: textfield;
     }
}


.block144 {
     width: 100%;
     padding: 20px 0;

     &:hover {
          background: #F6F9FF;
     }

     .bd12 {
          position: relative;
          width: 106px;
          height: 106px;
          background-color: #f5f5f5;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin: 3px 20px 0;
          overflow: hidden;
          cursor: pointer;

          &:hover {
               overflow: unset;

               .bd122 {
                    opacity: 1;
               }
          }

          .bd122 {
               position: absolute;
               left: 136px;
               z-index: 999;
               opacity: 0;
               transition: all .5s ease;
               background: #fff;
               border: 1px solid #f0f0f0;
               box-shadow: rgb(0 0 0 / 15%) 3px 3px 10px;

               img {
                    max-width: 200px;
                    max-height: 200px;
                    border-radius: 8px;
               }
          }
     }

     .bd13 {
          width: 160px;
          height: 84px;
          margin: 14px 0 0 14px;

          .word94 {
               width: 160px; 
               height: 38px;
               display: block;
               overflow-wrap: break-word;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
               line-height: 19px;
               overflow: hidden;
               text-overflow: ellipsis;
               cursor: pointer;
               white-space: nowrap;

               &:hover {
                    color: $colorMain;
               }
          }

          .txt48 {
               width: 48px;
               height: 14px;
               cursor: pointer;
               display: block;
               overflow-wrap: break-word;
               color: $colorMain;
               font-size: 12px;
               border-bottom: 1px solid $colorMain;
               font-family: SourceHanSansCN-Regular;
               white-space: nowrap;
               text-align: left;
               align-self: flex-start;
               margin-top: 34px;
          }
     }
     .bd_word_91{
          width: 141px;
          text-align: center;
          padding: 0 10px;
          overflow: hidden;
    text-overflow: ellipsis;
     }
     .bd_word_92{
          width: 141px;
          text-align: center;
          padding: 0 10px;
     }
     .bd_word_93{
          width: 141px;
          text-align: center;
          padding: 0 10px;
     }
     .bd_word_94{
          width: 150px;
          text-align: center;
          padding: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-clamp: 1;
          .bd_word_94_1{
               color: rgba(234, 6, 6, 1);
               font-size: 14px;
          }
          .bd_word_94_2{
               color: rgba(136, 136, 136, 1);
               margin-top: 17px;
          }
     }
     .bd_word_95{
          width: 141px;
          text-align: center;
          padding: 0 10px;
     }

     .bd14 {
          width: 138px;
          height: 112px;
          margin-left: 25px;

          .bd14_box {
               width: 138px;
               height: 112px;
          }

          .word96 {
               color: #121212;
               font-size: 12px;
               margin-top: 6px;
               overflow: hidden;
               text-overflow: ellipsis;
               display: -webkit-box;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
               line-height: 16px;
          }
     }

     .bd15 {
          width: 75px;
          height: 100%;
          margin-left: 53px;

          .info54 {
               width: 26px;
               height: 12px;
               display: block;
               overflow-wrap: break-word;
               color: #121212;
               font-size: 12px;
               font-family: SourceHanSansCN-Regular;
               white-space: nowrap;
               line-height: 12px;
               text-align: left;
               align-self: flex-start;
          }

          .word100 {

               display: block;
               overflow-wrap: break-word;
               color: #121212;
               font-size: 12px;
               font-family: SourceHanSansCN-Regular;
               word-break: break-all;
               line-height: 16px;
               text-align: left;
               margin-top: 8px;
          }
     }

     .word101 {
          display: block;
          width: 50px;
          font-weight: bold;
          color: rgba(243, 2, 19, 1);
          font-size: 12px;
          text-align: center;
          margin-left: 58px;
     }

     .bd16 {
          width: 122px;
          height: 46px;
          margin-left: 50px;

          .section25 {
               width: 122px;
               height: 30px;
               justify-content: space-between;

               .section26 {
                    height: 30px;
                    border-radius: 2px;
                    border: 1px solid rgba(221, 221, 221, 1);
                    background-color: rgba(255, 255, 255, 1);
                    width: 98px;
                    align-items: flex-start;
                    padding-left: 8px;
                    position: relative;

                    .section27 {
                         width: 64px;
                         height: 30px;
                         justify-content: space-between;

                         .info55 {
                              width: 5px;
                              height: 12px;
                              display: block;
                              overflow-wrap: break-word;
                              color: rgba(204, 204, 204, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              white-space: nowrap;
                              line-height: 12px;
                              text-align: left;
                              margin-top: 8px;
                         }

                         .main21 {
                              height: 30px;
                              border: 1px solid rgba(221, 221, 221, 1);
                              width: 48px;
                              justify-content: center;
                              align-items: center;

                              .word102 {
                                   width: 14px;
                                   height: 12px;
                                   display: block;
                                   overflow-wrap: break-word;
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 12px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                                   line-height: 12px;
                                   text-align: left;
                              }
                         }
                    }

                    .section28 {
                         height: 30px;
                         border-radius: 0 2px 2px 0;
                         border: 1px solid rgba(221, 221, 221, 1);
                         width: 26px;
                         justify-content: center;
                         align-items: center;
                         position: absolute;
                         left: 72px;
                         top: 0;

                         .info56 {
                              width: 7px;
                              height: 12px;
                              display: block;
                              overflow-wrap: break-word;
                              color: rgba(34, 34, 34, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              white-space: nowrap;
                              line-height: 12px;
                              text-align: left;
                         }
                    }
               }

               .txt50 {
                    width: 12px;
                    height: 12px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(68, 68, 68, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 12px;
                    text-align: left;
                    margin-top: 7px;
               }
          }

          .section29 {

               margin-top: 4px;


               .txt51 {
                    word-break: break-all;
                    color: #90929E;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    line-height: 16px;
                    text-align: left;
               }

               .txt52 {
                    width: 60px;
                    height: 12px;
                    display: block;
                    overflow-wrap: break-word;
                    color: #90929E;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 12px;
                    text-align: left;
               }
          }
     }

     .bd17 {
          width: 95px;
          height: 90px;
          margin-left: 40px;
          align-items: center;
          justify-content: center;

          .section30 {
               height: 28px;
               background: $colorMain;
               width: 95px;
               cursor: pointer;
               border: none;
               outline: none;

               .word103 {
                    width: 60px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 18px;
                    text-align: left;
               }
          }

          .section31 {
               height: 28px;
               border: 1px solid rgba(226, 226, 226, 1);
               background: #fff;
               align-self: flex-start;
               margin-top: 6px;
               width: 97px;
               display: flex;
               justify-content: center;
               align-items: center;
               cursor: pointer;

               .txt53 {
                    width: 84px;
                    height: 12px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(51, 51, 51, 0.79);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 12px;
               }
          }

          .section32 {
               height: 28px;
               border: 1px solid rgba(226, 226, 226, 1);
               background: #fff;
               align-self: flex-start;
               margin-top: 3px;
               width: 95px;
               display: flex;
               justify-content: center;
               align-items: center;
               cursor: pointer;

               .info57 {
                    width: 48px;
                    height: 12px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(51, 51, 51, 0.79);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 12px;
               }
          }
     }
}

.pro_tag {
     display: inline-block;
     margin-top: 5px;
     padding: 2px 7px;
     border: 1px solid $colorMain2;
     border-radius: 6px;
}
.proListzhan{
     width: 100%;
     .proList_th{
          border-top: 1px solid #F4F4F4;
          width: 100%;
          height: 49px;
          background: #F5F5F5;
          padding-left: 57px;
          span{
               color: rgba(16, 16, 16, 1);
               font-size: 14px;
               font-family: SourceHanSansSC-regular;
          }
          .List_th2{
               margin:0 179px;
          }
          .List_th3{
               margin: 0 100px;
          }
          .List_th4{
               margin: 0 100px;
          }
          .List_th5{
               margin-left:57px;
          }
     }
     .proList_td{
          height: 50px;
          border-top: 1px solid #F4F4F4;
          font-size: 14px;  
          img{
               width: 20px;
               height: 20px;  
               margin-left: 16px;
          }
          .check_td{
             width: 15px;
             height: 15px;
             margin-left: 20px;
          }
          .td_id{
               color: rgba(16, 16, 16, 1);
               width: 69px;
               text-align: center;
          }
          .td_specs{
               width: 348px;
               padding: 0 15px;
               text-align: center;
          }
          .td_weight{
               width: 290px;
               padding: 0 15px;
               text-align: center;
          }
          .td_univalence{
               width: 275px;
               padding: 0 15px;
               text-align: center;
               color: #EA0606;
               font-family: SourceHanSansSC-medium;
          }
          .td_operation{
               flex: 1;
               padding: 0 37px;
               color: rgba(23, 123, 255, 1);
               .td_operation_1{
                    cursor: pointer;
               }
               .td_operation_2{
                    cursor: pointer;
               }
               .td_operation_3{
                    cursor: pointer;
                    color: #BBBBBB;
               }
          }
     }
     .proList_foot{
          padding-left: 20px;
          padding-left: 16px;
          height: 40px;
          border-top: 1px solid #F4F4F4;
          background: #F5F5F5;
          .proList_foot_left{
               .check_foot{
                    width: 20px;
                    height: 20px;   
               }
               div{
                    color: rgba(16, 16, 16, 1);
                    font-size: 14px; 
                    margin-left: 20px;
               }
          }
          .proList_foot_right{
               width: 94px;
               height: 25px;
               border-radius: 4px;
               background-color: rgba(255, 255, 255, 1);
               color: rgba(23, 123, 255, 1);
               font-size: 14px;
               text-align: center;
               border: 1px solid rgba(23, 123, 255, 1);
               margin-right: 40px;
               cursor: pointer;
          }
     }
}
.mask_personal{
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
}
.personal_box{
    padding-bottom: 20px;
    width: 367px;
    //   height: 310px;
    background:  rgba(16, 16, 16, 0.59);
    border-radius: 4px;
    position: fixed;
    left: 50%;
    top: 60%;
    z-index: 1000;
    transform:translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .personal_img{
        text-align: right;
        padding-top: 5px;
        padding-right: 5px;
        img{
            width: 20px;
            height: 20px;
        }
    }
    .personal_cont{
        margin-top: 30px;
        p{
            color: #fff;
            font-size: 14px;
            text-align: center;
            margin-bottom: 10px;
        }
    }
    .personal_btn{
        margin: 0 auto;
        margin-top: 20px;
        span{
            color: #fff;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>